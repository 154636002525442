import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ColumnComponent } from 'tabulator-tables';
import { SheetState } from '../../../store/page/page.store';
import { Select, Store } from '@ngxs/store';
import { Sheet } from '../../../store/page/page.actions';

@Injectable({
  providedIn: 'root'
})
export class SheetService {
  private addSheetSource = new Subject<void>();
  addSheet$ = this.addSheetSource.asObservable();
  @Select(SheetState.getSheetData) data$: Observable<any> | undefined;
  constructor(
    private store: Store,
  ) { }


  requestAddSheet(data:any) {
    this.addSheetSource.next(data);
  }

  orderColumns(pageId: number, columns: ColumnComponent[]) {
    // Get ordered columns that are not hidden
    const newOrderedColumns: number[] = columns
      .map((col:any) => col.getDefinition())
      .filter(col => !col.status.includes('Hidden'))
      .map(col => Number(col.col))

    // Prepare payload
    const payload = {"PgCols": newOrderedColumns};

    // API call through store dispatch
    this.store.dispatch(new Sheet.OrderColumns(pageId, payload));
  }

  deleteColumn(pageId: number, colId: number) {
    const payload = { pageId, colId }

    // API call through store dispatch
    this.store.dispatch(new Sheet.DeleteColumn(payload));
  }
}
