<div class="freeze-chip">
    <div class="tooltip-container">
        <span class="add-underline">{{ pageFreezeColumn }}</span>
    </div>
    <div class="expand-icon flex-row">
        <span
          class="counter-btn inc"
          (click)="toggleFreeze($event)">
        </span>
          {{ arrow_up_down }}
        <span
          class="counter-btn dec"
          (click)="toggleFreeze($event)">
        </span>
      </div>
</div>