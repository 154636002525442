import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ItemService } from '../../core/services/item-service/item.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-edit-json-dialog',
  templateUrl: './edit-json-dialog.component.html',
  styleUrls: ['./edit-json-dialog.component.scss']
})
export class EditJSONDialogComponent implements OnInit {
  @ViewChild('content', { static: true }) content: any;
  private modalRef!: NgbModalRef;
  closeResult: string = '';


  constructor(
    private modalService: NgbModal,
    private itemService: ItemService,
    public dialogRef: MatDialogRef<EditJSONDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.open(this.content);
  }
  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        this.onCancel();
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        this.onCancel();
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }
  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }
}
