import { Component, ViewChild, OnInit, Inject, ViewEncapsulation, ChangeDetectorRef, AfterViewInit, HostListener, ElementRef, inject } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular';

import {
	ClassicEditor,
	Autoformat,
	AutoImage,
	Autosave,
	BlockQuote,
	Bold,
	CKBoxImageEdit,
	CloudServices,
	Code,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	Heading,
	Highlight,
	HtmlComment,
	HtmlEmbed,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	MediaEmbed,
	PageBreak,
	Paragraph,
	PasteFromOffice,
	PictureEditing,
	RemoveFormat,
	SelectAll,
	ShowBlocks,
	SourceEditing,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextPartLanguage,
	TextTransformation,
	Title,
	TodoList,
	Underline,
	Undo,
  FindAndReplace,
	type EditorConfig,
  AutoLink,
  Base64UploadAdapter,
  CKBox,
  CKFinder,
  CKFinderUploadAdapter,
  CodeBlock,
  HorizontalLine,
  Style,
  Alignment,

} from 'ckeditor5';

import { ExportPdf, ExportWord, FormatPainter, ImportWord, SlashCommand, TableOfContents, Template } from 'ckeditor5-premium-features'; //TODO Unsed Things are awaiting liscence
import { UNICODE_MULTIPLY, ASCII_EXPAND_ICON } from '../../../core/constants/app.contants';
import { DragService } from '../../../core/services/drag-service/drag.service';
import { ResizeService } from '../../../core/services/resize-service/resize.service';
import { JSONPayload } from '../../models/item/item.model';
import { Store } from '@ngxs/store';
import { AddItemDataInRow, EditItemDataInRow } from '../../../store/item/item.action';
import { Observable } from 'rxjs';
import { ItemState } from '../../../store/item/item.store';
import { ContextMenu } from '../../../core/constants/menu-bar/page/page';
import { FormMode } from '../../../core/enums/forms/form';
@Component({
  selector: 'dialog-edit',
  templateUrl: './dialog-edit.component.html',
  styleUrls: ['./dialog-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogEditComponent implements OnInit,AfterViewInit {
  DEFAULT_LANG = '3000000100';
  form!: FormGroup;
  private modalRef!: NgbModalRef;
  expandIcon = ASCII_EXPAND_ICON;
  @ViewChild('editor',{ static: false }) editorComponent!: CKEditorComponent;
  heightClass={value:false,index:0};
  textEditor:any={index:0,textLength:0};
  editorConfig: any = {};
  closeIcon=UNICODE_MULTIPLY;
  selectedLanguages: {[key : string] : string} = {};
  languageOptions : any[] = [];
  isLoading$: Observable<boolean> = inject(Store).select(ItemState.isLoading);
  public isLayoutReady = false;
  public Editor = ClassicEditor;
  headerTextWidth: number = 0;
  public config: EditorConfig = {};
  public mode = FormMode.ADD;
  constructor(
    public dialogRef: MatDialogRef<DialogEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dragService: DragService,
    private resizeService: ResizeService,
    private changeDetector: ChangeDetectorRef,
    private store : Store,
  ){}

  ngOnInit() {
    this.form = this.fb.group({
      languageGroups: this.fb.array([this.createLanguageGroup(this.DEFAULT_LANG,this.data?.value)])
    });
    this.languageOptions = this.data.language.data.DDLContent ?? [];
    this.selectedLanguages[0] = this.DEFAULT_LANG;
    this.mode = this.data.mode;
  }

   @ViewChild('headerText') header!: ElementRef;

  get languageGroupsFields(): FormArray {
    return this.form.get('languageGroups') as FormArray;
  }

    @ViewChild('editdialogcontainer', { static: true })
    popupContainer!: ElementRef<HTMLDivElement>; // to get the reference of the popup container for further manipulaiton

    // Function to handle drag start
    onDragStart(event: MouseEvent) {
      this.dragService.onDragStart(event, this.popupContainer);
    }
    // Function to handle mouse movement
    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
      this.dragService.onMouseMove(event, this.popupContainer);
    }

    // Function to handle drag end
    @HostListener('document:mouseup')
    onMouseUp() {
      this.dragService.onMouseUp(); // Use the service method
    }
    // Resizing functionality
    onResizeStart(event: MouseEvent) {
      this.resizeService.onResizeStart(event, this.popupContainer);
    }

    public ngAfterViewInit(): void {
    this.headerTextWidth = this.header.nativeElement.offsetWidth;
      this.config = {
        plugins: [
          CloudServices,
          Undo,
          PageBreak,
          List,
          HorizontalLine,
          SelectAll,
          Style,
          ImportWord,
          CKBox,
          // Template,
          // WProofreader,
          CKFinder, CKFinderUploadAdapter,
          // TableOfContents,                               TODO: Liscence Required for commented Plugins.
          FindAndReplace,
          // CaseChange,
          CodeBlock,
          Autoformat,
          Base64UploadAdapter,
          // FormatPainter,
          AutoImage,
          Autosave,
          BlockQuote,
          Bold,
          CKBoxImageEdit,
          Code,
          Essentials,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          FullPage,
          GeneralHtmlSupport,
          Heading,
          Highlight,
          HtmlComment,
          HtmlEmbed,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsert,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          AutoLink,
          LinkImage,
          List,
          ListProperties,
          MediaEmbed,
          PageBreak,
          Paragraph,
          PasteFromOffice,
          PictureEditing,
          RemoveFormat,
          SelectAll,
          ShowBlocks,
          SourceEditing,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextPartLanguage,
          TextTransformation,
          Title,
          TodoList,
          Underline,
          Undo,
          ExportWord,
          ExportPdf
        ],
        toolbar: {
          items: [
            'undo',
            'redo',
            '|',
            'importWord',
            'exportWord',
            'exportPdf',
            '|',
            'formatPainter',
            'caseChange',
            'findAndReplace',
            'selectAll',
            'wproofreader',
            '|',
            'insertTemplate',
            'tableOfContents',
            '|',
            'link',
            'insertImage',
            'ckfinder',
            'insertTable',
            'blockQuote',
            'mediaEmbed',
            'codeBlock',
            'pageBreak',
            'horizontalLine',
            'specialCharacters',
            'heading',
              'style',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'fontFamily',
              'fontSize',
            'removeFormat',
            '|',
            'alignment',
            '|',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
          ],
          shouldNotGroupWhenFull: true,
        },
        caseChange: {
          titleCase: {
              excludeWords: [ 'a', 'an', 'and', 'as', 'at', 'but', 'by', 'en', 'for', 'if', 'in','nor', 'of', 'on', 'or', 'per', 'the', 'to', 'vs', 'vs.', 'via' ]
          }
        },
        style: {
          definitions: []
        },
        fontFamily: {
          supportAllValues: true
        },
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22],
          supportAllValues: true
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3'
            },
            {
              model: 'heading4',
              view: 'h4',
              title: 'Heading 4',
              class: 'ck-heading_heading4'
            },
            {
              model: 'heading5',
              view: 'h5',
              title: 'Heading 5',
              class: 'ck-heading_heading5'
            },
            {
              model: 'heading6',
              view: 'h6',
              title: 'Heading 6',
              class: 'ck-heading_heading6'
            }
          ]
        },
        htmlSupport: {
          allow: [
            {
              name: /^.*$/,
              styles: true,
              attributes: true,
              classes: true
            }
          ]
        },
        image: {
          toolbar: [
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
            '|',
            'resizeImage',
            '|',
            'ckboxImageEdit'
          ]
        },

        initialData:
          '',
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: 'https://',
          decorators: {
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            }
          }
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        placeholder: 'Enter Rich Text',
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
        }
      };
      this.isLayoutReady = true;
      this.changeDetector.detectChanges();
   }

  closeDialog(){
    this.heightClass={value:false,index:0};
  }

  onValueChange({ editor }: ChangeEvent, index: number): void {
    this.checkValues(index);
  }

  onSelect(value: string, index: number): void {
    this.languageOptions = [...this.data.language.data.DDLContent];
    this.selectedLanguages[index] = value;
    this.checkValues(index);
    this.updateRichTextField(index);
  }

  checkValues(index: number): void {
    if (this.isCurrentGroupValid()) {
      this.textEditor={value:true,index:0}
      this.addLanguageGroup();
    }
  }

  get languageGroups(): FormArray {
    return this.form.get('languageGroups') as FormArray;
  }

  createLanguageGroup(language:string,value:string): FormGroup {
    const group = this.fb.group({
      language: [language, Validators.required],
      richText: [{ value: value, disabled: false }, Validators.required] // Initialize as disabled
    });

    // Update richText field based on language value
    group.get('language')?.valueChanges.subscribe(value => {
      if (value) {
        group.get('richText')?.enable();
      } else {
        group.get('richText')?.enable();
      }
    });

    return group;
  }

  isCurrentGroupValid(): boolean {
    const currentGroup = this.languageGroups.at(this.languageGroups.length - 1) as FormGroup;
    return currentGroup.valid;
  }

  addLanguageGroup() {
    if (this.isCurrentGroupValid()) {
      this.languageGroups.push(this.createLanguageGroup('',''));
    }
  }

  removeLanguageGroup(index: number) {
    if (this.languageGroups.length > 1) {
      this.languageGroups.removeAt(index);
    }
  }

  updateRichTextField(index: number) {
    const group = this.languageGroups.at(index) as FormGroup;
    const languageValue = group.get('language')?.value;
    if (languageValue) {
      group.get('richText')?.enable();
    } else {
      group.get('richText')?.disable();
    }
  }

  onSave(): void {
    const context = this.data.context;
    const payload : JSONPayload = {
      Pg : Number(this.data.page_id),
      Col: Number(this.data.columnId),
      Row: Number(this.data.row_id),
      DataType: Number(this.data.dataTypeId),
      JSON: {},
    }

    if(this.languageGroups.value && this.languageGroups.length > 0){
      this.languageGroups.value.forEach((value : { language : string , richText : string}) => {
        payload.JSON[value.language] = this.transFormText(value.richText);
      });
    }

    if (context === ContextMenu.FormatLocalColumn) {
      this.dialogRef.close(payload.JSON);
    } else {
      const action = this.mode === FormMode.EDIT
       ? new EditItemDataInRow(payload) :
       new AddItemDataInRow(payload);
      this.store.dispatch(action)
      this.mode = FormMode.EDIT;
    }


    this.resetSelectedLanguages();

    this.form.markAsPristine();
  }

  onCancel(): void {
    this.dialogRef.close();
    this.resetSelectedLanguages();
  }

  resetSelectedLanguages(){
    this.selectedLanguages = {};
  }

  updateLanguageOptions(){
    this.languageOptions = [...this.data.language.data.DDLContent];
  }

  transFormText(text : string){
    const withoutTags = text.replace(/<\/?[^>]+(>|$)/g, '');

    const withoutEntities = withoutTags.replace(/&nbsp;/g, ' ').replace(/&[a-z]+;/g, '');

    return withoutEntities
      .trim()
      .split(' ')
      .map(word => word.charAt(0) + word.slice(1))
      .join(' ');
  }
}
