import { ADD_ROW_MODEL } from "../../shared/models/row/row.models";
export enum ActionTypes {
  ADD_NEXT_ROW = '[ROW] Add Next Row',
  ADD_NEXT_ROW_SUCCESS = '[ROW] Add Next Row Success',
  ADD_NEXT_ROW_FAILURE = '[ROW] Add Next Row Failure',

  ADD_PREV_ROW = '[ROW] Add Prev Row',
  ADD_PREV_ROW_SUCCESS = '[ROW] Add Prev Row Success',
  ADD_PREV_ROW_FAILURE = '[ROW] Add Prev Row Failure',

  ADD_CHILD_ROW = '[ROW] Add Child Row',
  ADD_CHILD_ROW_SUCCESS = '[ROW] Add Child Row Success',
  ADD_CHILD_ROW_FAILURE = '[ROW] Add Child Row Failure',

  ADD_NEXT_SYSTEM_ROW = '[ROW] Add Next System Row',
  ADD_NEXT_SYSTEM_ROW_SUCCESS = '[ROW] Add Next System Row Success',
  ADD_NEXT_SYSTEM_ROW_FAILURE = '[ROW] Add Next System Row Failure',

  ADD_PREV_SYSTEM_ROW = '[ROW] Add Prev System Row',
  ADD_PREV_SYSTEM_ROW_SUCCESS = '[ROW] Add Prev System Row Success',
  ADD_PREV_SYSTEM_ROW_FAILURE = '[ROW] Add Prev System Row Failure',

  ADD_CHILD_SYSTEM_ROW = '[ROW] Add Child System Row',
  ADD_CHILD_SYSTEM_ROW_SUCCESS = '[ROW] Add Child Row System Success',
  ADD_CHILD_SYSTEM_ROW_FAILURE = '[ROW] Add Child Row System Failure',
}

export class AddNextRow {
  static readonly type = ActionTypes.ADD_NEXT_ROW;
  constructor(
    public data : {
      row: ADD_ROW_MODEL,
      prevRowId : number | null,
      nextRowId : number | null,
      currentRowId : number | null,
    }
  ) {}
}

export class AddNextRowSuccess {
  static readonly type = ActionTypes.ADD_NEXT_ROW_SUCCESS;
  constructor(
    public data : {
      row: ADD_ROW_MODEL,
      prevRowId : number | null,
      nextRowId : number | null
      currentRowId : number | null,
    }
  ) {}
}


export class AddNextRowFailure {
  static readonly type = ActionTypes.ADD_NEXT_ROW_FAILURE;
  constructor(public error? : any) {}
}

export class AddPrevRow {
  static readonly type = ActionTypes.ADD_PREV_ROW;
  constructor(
    public data : {
      row: ADD_ROW_MODEL,
      prevRowId : number | null,
      nextRowId : number | null
      currentRowId : number | null,
    }) {}
}

export class AddPrevRowSuccess {
  static readonly type = ActionTypes.ADD_PREV_ROW_SUCCESS;
  constructor(
    public data : {
      row: ADD_ROW_MODEL,
      prevRowId : number | null,
      nextRowId : number | null,
      currentRowId : number | null,
    }) {}
}

export class AddPrevRowFailure{
  static readonly type = ActionTypes.ADD_PREV_ROW_FAILURE;
  constructor(public error : any) {}
}

export class AddChildRow {
  static readonly type = ActionTypes.ADD_CHILD_ROW;
  constructor(
    public data : {
    row: ADD_ROW_MODEL,
    prevRowId : number | null,
    nextRowId : number | null,
    currentRowId : number | null,
  }) {}
}

export class AddChildRowSuccess {
  static readonly type = ActionTypes.ADD_CHILD_ROW_SUCCESS;
  constructor(
    public data : {
    row: ADD_ROW_MODEL,
    prevRowId : number | null,
    nextRowId : number | null,
    currentRowId : number | null,
  }) {}
}

export class AddChildRowFailure {
  static readonly type = ActionTypes.ADD_CHILD_ROW_FAILURE
  constructor(public error : any) {}
}

export class AddNextSystemRow {
  static readonly type = ActionTypes.ADD_NEXT_SYSTEM_ROW;
  constructor(
    public data : {
      row: ADD_ROW_MODEL,
      prevRowId : number | null,
      nextRowId : number | null,
      currentRowId : number | null,
    }
  ) {}
}

export class AddNextSystemRowSuccess {
  static readonly type = ActionTypes.ADD_NEXT_SYSTEM_ROW_SUCCESS;
  constructor(
    public data : {
      row: ADD_ROW_MODEL,
      prevRowId : number | null,
      nextRowId : number | null
      currentRowId : number | null,
    }
  ) {}
}


export class AddNextSystemRowFailure {
  static readonly type = ActionTypes.ADD_NEXT_SYSTEM_ROW_FAILURE;
  constructor(public error? : any) {}
}

export class AddPrevSystemRow {
  static readonly type = ActionTypes.ADD_PREV_SYSTEM_ROW;
  constructor(
    public data : {
      row: ADD_ROW_MODEL,
      prevRowId : number | null,
      nextRowId : number | null
      currentRowId : number | null,
    }) {}
}

export class AddPrevSystemRowSuccess {
  static readonly type = ActionTypes.ADD_PREV_SYSTEM_ROW_SUCCESS;
  constructor(
    public data : {
      row: ADD_ROW_MODEL,
      prevRowId : number | null,
      nextRowId : number | null,
      currentRowId : number | null,
    }) {}
}

export class AddPrevSystemRowFailure{
  static readonly type = ActionTypes.ADD_PREV_SYSTEM_ROW_FAILURE;
  constructor(public error : any) {}
}

export class AddChildSystemRow {
  static readonly type = ActionTypes.ADD_CHILD_SYSTEM_ROW;
  constructor(
    public data : {
    row: ADD_ROW_MODEL,
    prevRowId : number | null,
    nextRowId : number | null,
    currentRowId : number | null,
  }) {}
}

export class AddChildSystemRowSuccess {
  static readonly type = ActionTypes.ADD_CHILD_SYSTEM_ROW_SUCCESS;
  constructor(
    public data : {
    row: ADD_ROW_MODEL,
    prevRowId : number | null,
    nextRowId : number | null,
    currentRowId : number | null,
  }) {}
}

export class AddChildSystemRowFailure {
  static readonly type = ActionTypes.ADD_CHILD_SYSTEM_ROW_FAILURE
  constructor(public error : any) {}
}
