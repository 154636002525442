import {
  Component,
  OnInit,
  Inject,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Sheet } from '../../store/page/page.actions';
import { SheetState } from '../../store/page/page.store';
import { Observable } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ASCII_EXPAND_ICON, UNICODE_CROSS_ICON } from '../../core/constants/app.contants';
import { ResizeService } from '../../core/services/resize-service/resize.service';
import { DragService } from '../../core/services/drag-service/drag.service';
import { PgTabStore } from '../../store/pg-tab/pg-tab.store';

@Component({
  selector: 'app-pick-col-dialog',
  templateUrl: './pickcol-dialog.component.html',
  styleUrls: ['./pickcol-dialog.component.scss'],
})
export class PickColDialogComponent implements OnInit {
  closeResult: string = '';
  columns: any[] = [];
  columnsDDI: any[] = [];
  height: any = 0;
  columnID:any="";
  width: any = 0;
  previousFontSize: number = 0;
  currentFontSize: number = 0;
  newFontSize: number = 0;
  closeTab = UNICODE_CROSS_ICON;
  @ViewChildren('checkboxElement') checkboxItems!: QueryList<ElementRef>;
  @ViewChild('popupContainer') popupContainer!: ElementRef;

  @Select(SheetState.getSheetColumnsById) columns$!: Observable<any>;
  @Select(SheetState.PickDdiColumns) columnsDDI$!: Observable<any>;
  expandIcon = ASCII_EXPAND_ICON;

  constructor(
    public dialogRef: MatDialogRef<PickColDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private dragService: DragService,
    private resizeService: ResizeService,
  ) {
    this.previousFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.currentFontSize = this.previousFontSize;
  }

  ngOnInit() {
    if (this.data?.dialog != true || this.data?.dialog == undefined) {
      this.columns$.subscribe((columns) => {
        if (columns == undefined) {
          this.store.dispatch(new Sheet.FetchSheetData(this.data?.selectedId));
          this.columns$.subscribe((columns) => {
            this.columns = columns.map((col: any) => ({ ...col }));
          });
        } else {
          this.columns = columns.map((col: any) => ({ ...col }));
        }
      });
    } else {
      this.columnsDDI$.subscribe((columns) => {
        if (columns == undefined) {
          this.store.dispatch(new Sheet.PickDdiData(this.data?.selectedId));
          this.columnsDDI$.subscribe((columns) => {
            this.columnsDDI = columns.map((col: any) => ({ ...col }));
          });
        } else {
          this.columnsDDI = columns.map((col: any) => ({ ...col }));
        }
      });
    }
  }
  idSelector(id:any){
    this.columnID=id;
    this.dialogRef.close(id);
  }
  // Dragging functionality
  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContainer);
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContainer);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }
  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContainer);
  }

  onClose(): void {
    this.dialogRef.close();
  }


  isVisible(column: any): boolean {
    return !column.status.includes('Hidden');
  }

  drop(event: CdkDragDrop<any[]>): void {
    if (this.data?.dialog == true) {
      moveItemInArray(this.columnsDDI, event.previousIndex, event.currentIndex);
      this.store.dispatch(new Sheet.UpdatePickDdiColumns(this.columnsDDI));
    } else {
      moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
      this.store.dispatch(new Sheet.updateColumns(this.columns));
    }
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.onKeydown);
  }

  onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.onClose();
    }
  };

  ngAfterViewInit() {
    document.addEventListener('keydown', this.onKeydown);
  }
}
