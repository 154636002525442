<div *ngIf="((isLoading$ | async) ||
  (isRowLoading$ | async) ||
  (isColLoading$ | async) ||
  (isItemLoading$ | async))"
  class="loader-overlay">
  <div class="loader"></div>
</div>
<div #tabulatorDiv></div>
<div *ngIf="errorMessage" id="errorMessage" style="color: red;">
</div>
<div id="dropdownMenu" class="dropdown-menu">
  <ul style="padding: 0px;">
    @if(showAddEdit.add){
      <li class="no-color" [ngClass]="{disabled: disabled}"  (click)="!disabled? modifyItem($event, formMode.ADD):''">Add Item</li>
    }
    @if(showAddEdit.edit){
      <li class="no-color" [ngClass]="{disabled: !disabled}" (click)="disabled? modifyItem($event, formMode.EDIT):''">Edit Item</li>
    }
    <li class="no-color" (click)="deleteItem()">Delete Item</li>
    <li class="no-color" (click)="formatLocalItem()">Format Local-Item</li>
    <li class="no-color" (click)="formatSharedItem()">Format Shared-Item</li>
    <li class="no-color" (click)="formatLocalCell()">Format Local-Cell</li>
    <li class="no-color" (click)="formatSharedCell()">Format Shared-Cell</li>
    <li class="no-color" (click)="viewCell()">View Cell</li>
    <li class="no-color" (click)="viewItem()">View Item</li>
  </ul>
</div>

<div class="dropdown-container" *ngIf="showDropdown">
  <span (click)="formatLocalCol()" class="dropdown-menu-item">Format Local-Column</span>
  <span (click)="formatSharedCol()" class="dropdown-menu-item">Format Shared-Column</span>
  <span (click)="deleteColumn()" class="dropdown-menu-item">Delete Column</span>
  <span (click)="viewColumn()" class="dropdown-menu-item">View Column</span>
</div>
