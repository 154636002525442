import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tPgService } from '../../core/services/tPg.service';
import { catchError, EMPTY, tap, } from 'rxjs';
import { Injectable } from '@angular/core';
import { Sheet } from './page.actions';
import { LoadPgTabs } from '../pg-tab/pg-tab.actions';
import { RowPageData } from '../../shared/models/edit-dd-dailogs/dd-dailog.models';
import { SetAppLoading, SetExpandLevel } from '../root/root.action';
import { findMaxRowLevel } from '../../utils/utils';
import { PageStateModel, PageTabState } from './page.statemodel';
import { MainService } from '../../core/services/main-service/main.service';


@State<PageStateModel>({
  name: 'sheet',
  defaults: PageTabState,
})
@Injectable()
export class SheetState {
  constructor(
    private _tPgService: tPgService,
    private store : Store,
    private mainService : MainService
  ) {}

  @Selector([SheetState])
  static getSheetData(state: PageStateModel) {
    return state.sheetData;
  }

  @Selector([SheetState])
  static getSheetFlatData(state: PageStateModel) {
    return state.allTabsFlatData;
  }

  @Selector([SheetState])
  static getAllTabsData(state: PageStateModel) {
    return state.allTabsData;
  }

  @Selector()
  static getSheetDataById(state: PageStateModel): RowPageData[] {
    if(state.allTabsData && state.allTabsData.length > 0){
      const key : number = Number(state.defaultPageId);
      const obj =  state.allTabsData.filter(obj => key && obj[key] !== undefined)[0];
      return obj ? [...obj[key].pageData] : [];
    }else{
      return [];
    }
  }

  @Selector()
  static getSheetFlatDataById(state: PageStateModel): any {
    if(state.allTabsFlatData && state.allTabsFlatData.length > 0){
      const key : number = Number(state.defaultPageId);
      const obj =  state.allTabsFlatData.filter(obj => key && obj[key] !== undefined)[0];
      return obj ? [...obj[key].pageData] : [];
    }else{
      return [];
    }
  }

  @Selector([SheetState])
  static getVisitedData(state: PageStateModel) {
    return state.visitedPages;
  }

  @Selector()
  static getSheetColumns(state: PageStateModel) {
    return state.sheetColumns;
  }

  @Selector()
  static getSheetColumnsById(state: PageStateModel): any {
    if(state.allTabsData && state.allTabsData.length > 0){
      const key : number = Number(state.defaultPageId);
      const obj =  state.allTabsData.filter(obj => key && obj[key] !== undefined)[0]
      return obj ? [...obj[key].pageColumns] : [];
    }else{
      return [];
    }
  }

  @Selector([SheetState])
  static PickDdiData(state: PageStateModel) {
    return state.PickDdiData;
  }

  @Selector([SheetState])
  static PickDdiColumns(state: PageStateModel) {
    return state.PickDdiColumns;
  }

  @Selector([SheetState])
  static regions(state: PageStateModel) {
    return state.regions;
  }

  @Selector([SheetState])
  static addRow(state: PageStateModel) {
    return state.addRow;
  }
  @Selector([SheetState])
  static filterDDSPageData(state: PageStateModel) {
    return state.filteredSheetData;
  }
  @Selector([SheetState])
  static formatLocalCol(state: PageStateModel) {
    return state.formatLocalCol;
  }
  @Selector([SheetState])
  static deleteItemData(state: PageStateModel) {
    return state.deleteItemData;
  }

  @Selector([SheetState])
  static getFrozen(state: PageStateModel) {
    return state.frozen;
  }
  @Selector([SheetState])
  static getExpandWidth(state: PageStateModel) {
    return state.expandWidth;
  }

  @Selector()
  static isLoading(state: PageStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static getPageId(state: PageStateModel) {
    return state.defaultPageId;
  }

  @Selector()
  static getRowLevel(state: PageStateModel){
    return state.rowLevel;
  }

  @Action(Sheet.UpdatePageData)
  UpdatePageData(ctx: StateContext<PageStateModel>, action: Sheet.UpdatePageData) {
      const pageId = ctx.getState().defaultPageId ?? action.payload.defaultPageId;
      const currentPageData = action.payload.allTabsData.filter((obj:any) => Object.keys(obj)[0] === pageId);
      let maxRowLevel = 0;
      if (currentPageData[0]){
        for (const obj of currentPageData[0][Number(pageId)].pageData) {
          maxRowLevel = Math.max(maxRowLevel, findMaxRowLevel(obj));
        }
      }
    if(action?.payload?.refresh){
      const pageId = ctx.getState().defaultPageId;
      this.store.dispatch(new LoadPgTabs(pageId, true));
    }else{
      ctx.patchState({
        allTabsData: action.payload.allTabsData,
        allTabsFlatData: action.payload.allTabsFlatData,
        defaultPageId: action.payload.defaultPageId,
        rowLevel : {
           page : maxRowLevel,
           ddl : 0
          },
      });
    }
  }

  @Action(Sheet.PickDdiData)
  PickDdiData(ctx: StateContext<PageStateModel>, action: Sheet.PickDdiData) {
    const { DDL, IsEdition } = action;
  
    ctx.dispatch(new SetAppLoading(true));

    ctx.patchState({
      PickDdiColumns: [],
      PickDdiData: [],
    });
    if (IsEdition) {
      const state = ctx.getState();
      const pageId = state.defaultPageId as number;
  
      const tabIndex = state.allTabsData.findIndex(tab => tab[pageId]);
      if (tabIndex !== -1) {
        const tabData = state.allTabsData[tabIndex][pageId];
        ctx.patchState({
          PickDdiData: tabData.pageData?.[0]?._children || [],
          PickDdiColumns: tabData.pageColumns || [],
          rowLevel: {
            ddl : state.rowLevel.page || 0,
            page : state.rowLevel.page || 0
          },
        });
      }
      ctx.dispatch(new SetAppLoading(false));
      return EMPTY;
    } else {
      // Fetch DDL data from the service
      return this._tPgService.getDDL(DDL).pipe(
        tap((response: any) => {
          const { success, data } = response;
  
          if (success && data) {
            const { DDLContent, DDLColumns } = data;
  
            const maxRowLevel = (DDLContent || []).reduce((max : any, item : any) => {
              return Math.max(max, findMaxRowLevel(item));
            }, 0);
  
            ctx.patchState({
              PickDdiData: DDLContent || [],
              PickDdiColumns: DDLColumns || [],
              rowLevel: {
                ddl : maxRowLevel,
                page : ctx.getState().rowLevel.page || 0
              },
            });
          }
  
          // Stop loading indicator
          ctx.dispatch(new SetAppLoading(false));
          return EMPTY;
        }),
        catchError(() => {
          // Handle errors (e.g., log or show a message)
          ctx.dispatch(new SetAppLoading(false));
          return EMPTY;
        })
      );
    }
  }
  

  @Action(Sheet.UpdatePickDdiColumns)
  UpdatePickDdiColumn(
    ctx: StateContext<PageStateModel>,
    action: Sheet.UpdatePickDdiColumns
  ) {
    ctx.patchState({
      PickDdiColumns: action.payload,
    });
  }

  @Action(Sheet.PageButtonPopupData)
  PageButtonPopupData(
    ctx: StateContext<PageStateModel>,
    action: Sheet.PageButtonPopupData
  ) {
    return this._tPgService.getPages(action.payload).pipe(
      tap((data: any) => {
        if (!data || !data.data) {
          console.error('Invalid data received from API:', data);
          return;
        }
        // Filter columns to include 'DDS-Col' or 'Share' but exclude 'Hidden'
        const ddsFields = data.data.pageColumns
          .filter((column: { status: string | string[] }) => {
            return (
              column.status &&
              column.status.includes('DDS-Col') &&
              !column.status.includes('Hidden')
            );
          })
          .map((column: { field: any }) => column.field);
        // Filter the page data based on the presence of required fields
        const filteredData = data.data.pageData
          .map((page: any) => {
            let filteredPage: any = {};

            ddsFields.forEach((field: string) => {
              if (page.hasOwnProperty(field)) {
                filteredPage[field] = page[field];
              }
            });

            return filteredPage;
          })
          .filter((page: any) => Object.keys(page).length > 0); // Remove empty pages here

        ctx.patchState({
          filteredSheetData: filteredData,
        });
      })
    );
  }

  @Action(Sheet.updateColumns)
  UpdateColumn(ctx: StateContext<PageStateModel>, action: Sheet.updateColumns) {
    const updatedData = this.mainService.updatePageColumns(ctx.getState().allTabsData,ctx.getState().defaultPageId as number, action.payload)
    ctx.patchState({
      allTabsData : updatedData,
    });
  }

  @Action(Sheet.OrderColumns)
  OrderColumns(ctx: StateContext<PageStateModel>, action: Sheet.OrderColumns) {
    return this._tPgService.orderColumns(action.pageId, action.payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
      })
    );
  }

  @Action(Sheet.AddRow)
  AddRow(ctx: StateContext<PageStateModel>, action: Sheet.AddRow) {
    ctx.patchState({
      addRow: [], // Set to an empty array to match the expected type
    });
    return this._tPgService.addRow(action.payload).pipe(
      tap((resp: any) => {
        ctx.patchState({
          addRow: resp,
        });
      })
    );
  }

  @Action(Sheet.addColumn)
  addColumn(ctx: StateContext<PageStateModel>, action: Sheet.addColumn) {
    ctx.patchState({
      addRow: [], // Set to an empty array to match the expected type
    });
    return this._tPgService.addColumn(action.payload).pipe(
      tap((resp: any) => {
        ctx.patchState({
          addRow: resp,
        });
      })
    );
  }

  @Action(Sheet.DeleteRow)
  DeleteRow(ctx: StateContext<PageStateModel>, action: Sheet.DeleteRow) {
    return this._tPgService.deleteRow(action.payload).pipe();
  }

  @Action(Sheet.addUrl)
  addUrl(ctx: StateContext<PageStateModel>, action: Sheet.addUrl) {}

  @Action(Sheet.DeleteColumn)
  DeleteColumn(ctx: StateContext<PageStateModel>, action: Sheet.DeleteColumn) {
    const state = ctx.getState();

    return this._tPgService.deleteColumn(action.payload).pipe();
  }

  @Action(Sheet.FormatLocalColData)
  formatLocalCol(
    ctx: StateContext<PageStateModel>,
    action: Sheet.FormatLocalColData
  ) {
    return this._tPgService.formatLocalCol(action.payload, action.page_id).pipe(
      tap((data: any) => {
        ctx.patchState({
          formatLocalCol: data,
        });
      })
    );
  }
  @Action(Sheet.DeleteItemData)
  deleteItemData(ctx: StateContext<PageStateModel>, action: Sheet.DeleteItemData) {
    return this._tPgService.deleteItemData(action.payload, action.page_id).pipe(
      tap((resp: any) => {
        ctx.patchState({
          deleteItemData: resp,
        });
      })
    );
  }
  @Action(Sheet.SetFrozen)
  SetFrozen(ctx: StateContext<PageStateModel>, action: Sheet.SetFrozen) {
    ctx.patchState({
      frozen: action.payload,
    });
  }
  @Action(Sheet.SetWidthExpand)
  SetWidthExpand(ctx: StateContext<PageStateModel>, action: Sheet.SetWidthExpand) {
    ctx.patchState({
      expandWidth: action.payload,
    });
  }

}
