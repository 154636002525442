<div class="menu-bar-container">
  <!-- Refresh Chip -->
  <div class="menu-item-container" *ngFor="let menu of menuBar; let i = index">
    <div [ngSwitch]="menu.item">
      <!-- Case: menu.item is '↻' -->
      <div class="Chip" *ngSwitchCase="'↻'">
        <div
          [appTooltip]="selectedMenuBar.session"
          [name]="menu.rowId"
          (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
          id="{{ menu.rowId }}"
          class="dropdown-button-container"
        >
          <div
            class="language-Icon"
            alt="reload-Icon"
            [class.blink]="false"
          >
            {{ menu.item }}
          </div>
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'🌎'">
        <div
          [appTooltip]="selectedMenuBar.currentRegion"
          [name]="menu.rowId"
          (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
          id="{{ menu.rowId }}"
          class="dropdown-button-container"
          (click)="showCountryRegion()"
        >
          {{ menu.item }}
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'文'">
        <div
          [appTooltip]="selectedMenuBar.currentLanguage"
          [name]="menu.rowId"
          (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
          id="{{ menu.rowId }}"
          class="dropdown-button-container"
        >
          <div class="tooltip-container" (click)="showLanguageDialog()">
            {{ menu.item }}
          </div>
          <input
            type="checkbox"
            id="languageCheckbox"
            name="languageCheckbox"
            class="checkbox"
            [(ngModel)]="isChecked"
            (change)="toggleAutoTranslate($event)"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'¤'" (click)="showCurrencyDialog()">
        <div
          [appTooltip]="selectedMenuBar.currentCurrency"
          [name]="menu.rowId"
          (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
          id="{{ menu.rowId }}"
        >
          {{ menu.item }}
        </div>
      </div>
      <div class="Chip" (click)="openUserDialog()" *ngSwitchCase="'User'">
        <div class="user-custom-label">{{ menu.item }}</div>
      </div>
      <div
        class="Chip"
        *ngSwitchCase="'Page'"
        (contextmenu)="onRightClick($event)"
        [matMenuTriggerFor]="pageList"
        (click)="showPageDialog()"
        #menuTrigger="matMenuTrigger"
      >
        <span
          [appTooltip]="selectedMenuBar.currentPage"
          [name]="menu.rowId"
          (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
          id="{{ menu.rowId }}"
        >
          {{ menu.item }}
        </span>
      </div>

      <mat-menu #pageList="matMenu" class="custom-menu-panel page-list">
        <div mat-menu-item (click)="formatPage()">Format Page</div>
        <div
          mat-menu-item
          [ngStyle]="isAllPages ? { color: 'LightGray' } : {}"
          (click)="deletePage()"
        >
          Delete Page
        </div>
        <div mat-menu-item (click)="viewPage()">View Page</div>
      </mat-menu>

      <div class="Chip" *ngSwitchCase="'Edition'">
        <div
          *ngIf="isEditionPg"
          class="dropdown-button-container"
        >
          <div
            [appTooltip]="selectedMenuBar.currentEdition"
            [name]="menu.rowId"
            (click)="showEditionDialog()"
            (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
            class="tooltip-container"
          >
            {{ menu.item }}
          </div>
          <!-- <input
            type="checkbox"
            id="editionCheckbox"
            name="editionCheckbox"
            class="checkbox"
            (change)="handleCheckboxChange($event)"
            [disabled]="false"
            [(ngModel)]="EditionCheckbox"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          /> -->
        </div>
        <div *ngIf="!isEditionPg" class="dropdown-button-container">
          <div
            [appTooltip]="selectedMenuBar.currentEdition"
            [name]="menu.rowId"
            (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
            class="tooltip-container"
          >
            Edition
          </div>
          <input
            type="checkbox"
            id="editionCheckbox"
            name="editionCheckbox"
            class="checkbox"
            (change)="handleCheckboxChange($event)"
            [disabled]="false"
            [(ngModel)]="EditionCheckbox"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div
        class="Chip"
        [appTooltip]="selectedMenuBar.currentMode"
        [name]="menu.rowId"
        (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
        (click)="showViewDialog()"
        *ngSwitchCase="'Mode'"
      >
        {{ menu.item }}
      </div>
      <div class="Chip" (click)="openColDialog()" *ngSwitchCase="'Cols'">
        {{ menu.item }}
      </div>
      <div class="Chip" *ngSwitchCase="'Freeze'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">Freeze:</div>
          <app-freeze-field
            [columns]="columns$ | async"
            context="menu-bar">
          </app-freeze-field>
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'Expand'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">
            Expand:
            <app-decimal-field 
              [minValue]="isSectionExist ? 0 : 1">
            </app-decimal-field>
          </div>
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'Sort'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">{{ menu.item }}</div>
          <input
            type="checkbox"
            id="sortCheckbox"
            name="sortCheckbox"
            class="checkbox"
            (change)="toggleSort()"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'Filter'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">{{ menu.item }}</div>
          <input
            type="checkbox"
            id="filterCheckbox"
            name="filterCheckbox"
            class="checkbox"
            (change)="toggleFilter()"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'Clear'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">Clear</div>
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'LevelSet'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">LevelSet</div>
          <input
            type="checkbox"
            id="showSetCheckbox"
            name="showSetCheckbox"
            class="checkbox"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'RowSet'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">RowSet</div>
          <input
            type="checkbox"
            id="showSetCheckbox"
            name="showSetCheckbox"
            class="checkbox"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'Cats'">
        <div class="dropdown-button-container">Cats</div>
      </div>
      <div class="Chip" *ngSwitchCase="'Compare'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">Compare</div>
          <input
            type="checkbox"
            id="compareCheckbox"
            name="compareCheckbox"
            class="checkbox"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'Timer'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">Timer</div>
          <input
            type="checkbox"
            id="timerCheckbox"
            name="timerCheckbox"
            class="checkbox"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- language -->
  <mat-menu class="dropdown" #languageMenu="matMenu">
    <div
      [ngStyle]="{ 'background-color': tooltip == lang ? '#eeeef2' : '#fff' }"
      class="button-language notranslate"
      mat-menu-item
      *ngFor="let lang of filteredLanguages"
      (click)="setLanguage(lang)"
    >
      {{ lang }}
    </div>
    <div
      class="button-language notranslate"
      mat-menu-item
      (click)="setLanguage(null)"
    >
      None
    </div>
  </mat-menu>
</div>
