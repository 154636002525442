export const MenuBar = {
  success: true,
  data: [
    {
      rowId: '3000000505',
      item: '↻',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
    {
      rowId: '3000000506',
      item: 'User',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
    {
      rowId: '3000000507',
      item: '🌎',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
    {
      rowId: '3000000508',
      item: '文',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
    {
      rowId: '3000000509',
      item: '¤',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
    {
      rowId: '3000000510',
      item: 'Page',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
    {
      rowId: '3000000511',
      item: 'Edition',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
    {
      rowId: '3000000512',
      item: 'Mode',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
    {
      rowId: '3000000513',
      item: 'Cols',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [
        {
          rowId: '3000000514',
          item: 'View',
          rowLevel: 4,
          colId: '2000000069',
          parentRow: '3000000513',
          childRows: [],
        },
        {
          rowId: '3000000515',
          item: 'Format',
          rowLevel: 4,
          colId: '2000000069',
          parentRow: '3000000513',
          childRows: [],
        },
        {
          rowId: '3000000516',
          item: 'Edit',
          rowLevel: 4,
          colId: '2000000069',
          parentRow: '3000000513',
          childRows: [],
        },
      ],
    },
    {
      rowId: '3000000517',
      item: 'Freeze',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
    {
      rowId: '3000000518',
      item: 'Expand',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
    {
      rowId: '3000000519',
      item: 'Sort',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
    {
      rowId: '3000000520',
      item: 'Filter',
      rowLevel: 3,
      colId: '2000000069',
      parentRow: '3000000504',
      childRows: [],
    },
  ],
  errorMsg: '',
  statusCode: 200,
};
export const SelectedMenuBar = {
  session: 'refresh',
  currentRegion: 'Current Region : United States',
  currentLanguage: 'Current Language : English',
  currentCurrency: 'Current Currency : US$',
  currentEdition:
    'Page Name: All Pages\nPage Edition: Default\nPage Comment: Page: Listing of all Pages in the system. Said Page is displayed in the AllPage-dialog inside the template',
  currentPage:
    'Page Name: All Pages\nPage Edition: Default\nPage Comment: Page: Listing of all Pages in the system. Said Page is displayed in the AllPage-dialog inside the template',
  defaultUser: 'Guest',
  currentMode: 'Current Mode : View',
};
