import { Component, Inject, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DragService } from '../../../../core/services/drag-service/drag.service';
import { ResizeService } from '../../../../core/services/resize-service/resize.service';
import { FontSelectServiceService } from '../../../../core/services/font-select-service/font-select-service.service';
import { UNICODE_MULTIPLY, UNICODE_CROSS_ICON, ASCII_EXPAND_ICON } from '../../../../core/constants/app.contants';
import { tPgService } from '../../../../core/services/tPg.service';
import { Store } from '@ngxs/store';
import { Format } from '../../../../store/format/format.actions';
import { ContextMenu } from '../../../../core/constants/menu-bar/page/page';
import { FormatDialogData } from '../../../../store/format/format.model';
import { MainService } from '../../../../core/services/main-service/main.service';
import { SheetState } from '../../../../store/page/page.store';
import { PgTabStore } from '../../../../store/pg-tab/pg-tab.store';
import { PgTab } from '../../../../store/pg-tab/pg-tab.model';
import { PAGE_IDS } from '../../../../constant';
import { Sheet } from '../../../../store/page/page.actions';
@Component({
  selector: 'app-format-page-main',
  templateUrl: './format-page-main.component.html',
  styleUrls: ['./format-page-main.component.scss']
})
export class FormatPageComponent implements AfterViewInit{
  headerText: string = 'Format Page';
  height: any = 0;
  width: any = 0;
  expandIcon = ASCII_EXPAND_ICON;
  newWidth: number = 0;
  newHeight: number = 0;
  formatId!: string; // Assuming this is populated
  pageId!: string; // Assuming this is populated
  formatPageForm!: FormGroup;
  closeResult: string = '';
  closeTab = UNICODE_CROSS_ICON ;
  isFormValid: boolean = true;
  failureMessage: string = '';
  successMessage: string = ''; 
  apiSuccessFlag: boolean = false;
  headerTextWidth: number = 0;
  onFormDataChange(updatedFormData: any): void {
    // Saved the updated form data in the `formatPageForm` variable
    this.formatPageForm = updatedFormData.form;
  }
  @ViewChild('headerText') header!: ElementRef;
  @ViewChild('scrollContainer',) scrollContainer!: ElementRef<HTMLDivElement>;

  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollContainer.nativeElement.scrollTop = 0; // Scroll to top
      this.scrollContainer.nativeElement.scrollLeft = 0; // Scroll to top
    }, 200); // 200ms delay
    this.headerTextWidth = this.header.nativeElement.offsetWidth;

  }

  private modalRef!: NgbModalRef;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FormatPageComponent>,
    public dialog: MatDialog,
    private dragService: DragService,
    private resizeService: ResizeService,
    public service: FontSelectServiceService,
    private store: Store,
    public pageService: tPgService,
    private modalService: NgbModal,
    private mainService: MainService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /**
   * Handles the save action when the user submits the form.
   * 1. Closes the dialog and passes the form data.
   * 2. Dispatches the updated form data to the global store.
   */
  onSave(): void {
    this.formatPageForm.markAllAsTouched();

    // Dispatch an action to update the data
    if (this.formatPageForm.valid) {
      switch (this.dialogData.context) {
        case ContextMenu.FormatPage:
          this.updatePageFormat();
          break;
        case ContextMenu.FormatLocalColumn: 
          this.updateLocalColumnFormat();
          break;
        case ContextMenu.FormatLocalRow: 
          this.updateLocalRowFormat();
          break;
        case ContextMenu.FormatLocalCell: 
          this.updateLocalCellFormat();
          break;
        case ContextMenu.FormatLocalItem: 
          this.updateLocalItemFormat();
          break;
        default:
          break;
      }
    }
  }

  updatePageFormat() {
    const pgId: number = this.dialogData.objectId;
    const formatForm = this.formatPageForm.value;
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);

    // Dispatch an action to update page
    this.store.dispatch(new Format.UpdatePage(formatForm)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          const updatedAllTabsData = this.mainService.updatePageFormat(formatForm, allTabsData, pgId);
          this.store.dispatch(new Sheet.UpdatePageData({
            allTabsData: updatedAllTabsData,
            defaultPageId: pgId,
          }));

          this.handleSuccess(`Success! Format Page (ID: ${pgId}) has been Formatted`);
        } else {
          this.handleFailure(`Error! Format Page (ID: ${pgId}) ${data.format.apiStatus.message}`);
        }
      },      
    });
  }

  updateLocalColumnFormat() {
    const colId: number = this.dialogData.objectId;
    const formatForm = this.formatPageForm.value;
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const pgId = PAGE_IDS.ALL_COLS;

    // Dispatch an action to update the column
    this.store.dispatch(new Format.UpdateColumn(colId, formatForm)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.mainService.updateLocalColumnFormat(formatForm, allTabsData, pgId);
          this.handleSuccess(`Success! Format Column (ID: ${colId}) has been Formatted`);
        } else {
          this.handleFailure(`Error! Format Column (ID: ${colId}) ${data.format.apiStatus.message}`);
        }
      },      
    });
  }

  updateLocalRowFormat() {
    const rowId: number = this.dialogData.objectId;
    const formatForm = this.formatPageForm.value;
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const pgTab = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as PgTab;
    const pgId = Number(pgTab.page_id);

    // Dispatch an action to update the Row
    this.store.dispatch(new Format.UpdateRow(rowId, formatForm)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          const updateTabData = this.mainService.updateLocalRowFormat(formatForm, allTabsData, pgId);
          this.store.dispatch(new Sheet.UpdatePageData({
            allTabsData: updateTabData,
            defaultPageId: pgId,
          }));

          this.handleSuccess(`Success! Format Row (ID: ${rowId}) has been Formatted`);
        } else {
          this.handleFailure(`Error! Format Row (ID: ${rowId}) ${data.format.apiStatus.message}`);
        }
      },      
    });
  }

  updateLocalCellFormat() {
    const CellId: number = this.dialogData.objectId;
    const colId: number = this.dialogData.colId;
    const rowId: number = this.dialogData.rowId;
    const formatForm = this.formatPageForm.value;

    // Dispatch an action to update the Cell
    this.store.dispatch(new Format.UpdateCell(colId, rowId, formatForm)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.handleSuccess(`Success! Format Cell (ID: ${CellId}) has been Formatted`);
        } else {
          this.handleFailure(`Error! Format Cell (ID: ${CellId}) ${data.format.apiStatus.message}`);
        }
      },
    });
  }

  updateLocalItemFormat() {
    const itemId: number = this.dialogData.objectId;
    const colId: number = this.dialogData.colId;
    const rowId: number = this.dialogData.rowId;
    const formatForm = this.formatPageForm.value;

    // Dispatch an action to update the Row
    this.store.dispatch(new Format.UpdateItem(colId, rowId, formatForm)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.handleSuccess(`Success! Format Item (ID: ${itemId}) has been Formatted`);
        } else {
          this.handleFailure(`Error! Format Item (ID: ${itemId}) ${data.format.apiStatus.message}`);
        }
      },
    });
  }

  onCancel(): void {
    this.dialogRef.close(this.dialogData);
    this.modalRef.dismiss('Cancel click');
  }
  @ViewChild('formatPageContainer', { static: true })
  popupContainer!: ElementRef<HTMLDivElement>; // to get the reference of the popup container for further manipulaiton

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContainer);
  }
  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContainer);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }
  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContainer);
  }

  handleSuccess(message: string) {
    this.isFormValid = true;
    this.apiSuccessFlag = true;
    this.successMessage = message;
  }

  handleFailure(message: string) {
    this.isFormValid = false;
    this.apiSuccessFlag = false;
    this.failureMessage = message;
  }
}
