import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-open-edition-dialog',
  templateUrl: './open-edition-dialog.component.html',
  styleUrls: ['./open-edition-dialog.component.scss']
})
export class OpenEditionDialogComponent {
  editions = ["Edition1", "Edition2"]; // Example edition list

  constructor(public dialogRef: MatDialogRef<OpenEditionDialogComponent>) {}

  onOpenEdition(edition: any): void {
    console.log('Opening edition:', edition);
  }

  onMoveEdition(): void {
    console.log('Move Edition clicked');
  }

  onClose(): void {
    this.dialogRef.close();
  }
}