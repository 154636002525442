<div class="column-dialog-container" #content>
  <div class="dialog-header" (mousedown)="onDragStart($event)">
    <span class="heading">{{ data.data.title }}</span>
  </div>
  <div *ngIf="data.data.cell != 'cell'">
    <div class="title-container"></div>
    <form [formGroup]="formatLocalColForm">
      <div class="form-group">
        <div class="label-container">
          <label>Format ID*</label>
        </div>
        <input class="custom-input" matInput formControlName="formatId" />
        <!-- disabled -->
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Format User</label>
        </div>
        <input class="custom-input" matInput formControlName="formatUser" />
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Column ID</label>
        </div>
        <input class="custom-input" matInput formControlName="columnId" />
        <!-- disabled -->
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Status</label>
        </div>
        <input class="custom-input" matInput formControlName="status" />
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Min Width</label>
        </div>
        <input class="custom-input" matInput formControlName="minWidth" />
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Font Style</label>
        </div>
        <input
          class="custom-input"
          matInput
          (click)="openFontDialog()"
          formControlName="fontStyle"
        />
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Comment</label>
        </div>
        <input class="custom-input" matInput formControlName="comment" />
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Transactions</label>
        </div>
        <input class="custom-input" matInput formControlName="transactions" />
        <!-- disabled -->
      </div>
    </form>
  </div>
  <div *ngIf="data.data.cell == 'cell'">
    <div class="title-container"></div>
    <form [formGroup]="formatLocalColForm">
      <div class="form-group">
        <div class="label-container">
          <label>Format ID*</label>
        </div>
        <input class="custom-input" matInput formControlName="formatId" />
        <!-- disabled -->
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Format User*</label>
        </div>
        <input class="custom-input" matInput formControlName="formatUser" />
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Local-Cell ID*</label>
        </div>
        <input class="custom-input" matInput formControlName="columnId" />
        <!-- disabled -->
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Cell Data-Type</label>
        </div>
        <input class="custom-input" matInput formControlName="columnId" />
        <!-- disabled -->
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Cell DropDown-Source</label>
        </div>
        <input class="custom-input" matInput formControlName="columnId" />
        <!-- disabled -->
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Cell Default-Data</label>
        </div>
        <input class="custom-input" matInput formControlName="columnId" />
        <!-- disabled -->
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Cell Status</label>
        </div>
        <input class="custom-input" matInput formControlName="status" />
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Cell Formula</label>
        </div>
        <input class="custom-input" matInput formControlName="minWidth" />
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Font Style</label>
        </div>
        <input
          class="custom-input"
          matInput
          (click)="openFontDialog()"
          formControlName="fontStyle"
        />
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Cell Comment</label>
        </div>
        <input class="custom-input" matInput formControlName="comment" />
      </div>
      <div class="form-group">
        <div class="label-container">
          <label>Cell Transactions</label>
        </div>
        <input class="custom-input" matInput formControlName="transactions" />
        <!-- disabled -->
      </div>
    </form>
  </div>
  <div class="button-group">
    <div class="button-container">
      <div
        class="Chip"
        [ngClass]="{ disabled: !formatLocalColForm.valid }"
        (click)="onSave()"
      >
        Save
      </div>
    </div>
    <div class="Chip" (click)="onCancel()">Cancel</div>
  </div>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <span [innerHTML]="expandIcon"></span>
  </div>
</div>
