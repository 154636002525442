import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import {  Observable, skip, Subject, withLatestFrom } from 'rxjs';
import { LoadPgTabs, SelectPgTab } from '../../../store/pg-tab/pg-tab.actions';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';
import { MainService } from '../../../core/services/main-service/main.service';
import {
  All_Pages_ID,
  UNICODE_ADD_ICON,
  UNICODE_CIRCLE,
  UNICODE_CROSS_ICON,
  UNICODE_MULTIPLY,
  UNICODE_PLUS,
} from '../../../core/constants/app.contants';
import { Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TooltipService } from '../../../core/services/tooltip.service';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent implements OnInit, OnDestroy {
  destroyRef = inject(DestroyRef);
  pgTabs$: Observable<PgTab[]> = inject(Store).select(PgTabStore.getPgTabs);
  defaultPgTab$: Observable<PgTab> = inject(Store).select(
    PgTabStore.getSelectedPgTab
  );
  isLoading$ : Observable<boolean> = inject(Store).select(PgTabStore.isLoading)
  addDefaultTab$ = new Subject<PgTab>();
  tabBars: PgTab[] = [];
  currentSheetIndex = 0;
  closeTab = UNICODE_MULTIPLY;
  plusIcon = UNICODE_PLUS;
  private openPageSubscription!: Subscription; // To manage subscription
  circle = UNICODE_CIRCLE;
  cross_icon = UNICODE_CROSS_ICON;
  pageName : string = '';
  add_icon = UNICODE_ADD_ICON;
  constructor(private store: Store, public tooltipService: TooltipService, private mainService: MainService) {}
  ngOnInit(): void {
    this.mainService.pageName$.next('All Pages');
    this.openPageSubscription = this.mainService.openPage$.subscribe((updatedOpenPage: any) => {
      this.addDefaultTab$.next(updatedOpenPage);
    });
    this.defaultPgTab$
      .pipe(
        skip(2),
        takeUntilDestroyed(this.destroyRef),
        withLatestFrom(this.pgTabs$)
      )
      .subscribe(([activeTab, allTabs]) => {
        this.onSelectPgTab(activeTab, allTabs.length - 1);
      });
    this.addDefaultTab$.pipe(
      takeUntilDestroyed(this.destroyRef),
      withLatestFrom(this.pgTabs$),
    ).subscribe(([tab, allTabs])=>{
        if (!tab) return;
        const newTab = { ...tab };
        this.tabBars.push(newTab);
        this.onSelectPgTab(newTab, allTabs.length - 1);
    });
    this.initializeTabs();
  }
  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    if (this.openPageSubscription) {
      this.openPageSubscription.unsubscribe();
    }
  }
  private initializeTabs(): void {
    this.store.dispatch(new LoadPgTabs(All_Pages_ID));
    this.pgTabs$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tabs) => (this.tabBars = tabs));
  }

  onSelectPgTab(pgTab: PgTab, index: number) {
    this.store.dispatch(new SelectPgTab(pgTab));
    this.currentSheetIndex = index;
    this.mainService.pageName$.next(pgTab.page_name);
  }

  deleteSheet(index: number): void {
    if (this.tabBars.length <= 1) {
      alert("Can't close tab");
      return;
    }
    this.tabBars.splice(index, 1);

    if (index >= this.tabBars.length) {
      const previousTab = this.tabBars[index - 1];
      this.onSelectPgTab(previousTab, index - 1);
    } else {
      const nextTab = this.tabBars[index];
      this.onSelectPgTab(nextTab, index);
    }
    this.tooltipService.destroyToolTip();
  }

  addSheet(tabs : PgTab[]): void {
    this.addDefaultTab$.next(tabs[0])
  }

  onKeyDown(event: any, pgTab: PgTab, index: number): void {
    if (!this.tabBars[index]) {
      event.preventDefault();
      return;
    }

    if (event.key === 'Tab') {
      this.onSelectPgTab(pgTab, index);
    }
  }

  onShiftTabKeyPress(event: any, pgTab: PgTab, index: number): void {
    if (!this.tabBars[index - 1]) {
      event.preventDefault();
      return;
    }

    this.onSelectPgTab(pgTab, index - 1);
  }
}
