import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ColumnComponent, RowComponent } from 'tabulator-tables';
import { Select, Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class TabUIService {
  private addPageSource = new Subject<void>();
  addPage$ = this.addPageSource.asObservable();
  constructor(
    private store: Store,
  ) { }

  deleteRow(row: RowComponent) {
    const parentRow = row.getTreeParent();
    if (parentRow) {
      const parentData = parentRow.getData();
      const siblings = parentData['_children'] || [];
      const rowIndex = siblings.findIndex(
        (child: { page_id: any }) =>
          child.page_id === row.getData()['page_id']
      );
      if (rowIndex > -1) {
        siblings.splice(rowIndex, 1);
        parentRow.update({ _children: siblings });
        row.getTable().updateData([{ ...parentData, _children: siblings }]);
        parentRow.reformat();
      }
    } else {
      row.getTable().deleteRow(row);
    }
  }
}
