export namespace Format {
  export class ViewPage {
    static readonly type = '[ViewPage] Load ViewPage';
    constructor() {}
  }
  export class GetPage {
    static readonly type = '[Format] GetPage';
    constructor() {}
  }
  export class UpdatePage {
    static readonly type = '[Format] FormatPage';
    constructor(public payload: any) {}
  }
  export class DeletePage {
    static readonly type = '[Format] DeletePage';
    constructor(public pgId: number, public payload: any) {}
  }
  export class GetLocalColumn {
    static readonly type = '[Format] GetLocalColumn';
    constructor(public colId: number) {}
  }
  export class GetLocalRow {
    static readonly type = '[Format] GetLocalRow';
    constructor(public rowId: number) {}
  }
  export class GetLocalCell {
    static readonly type = '[Format] GetLocalCell';
    constructor(public colId: number, public rowId: number) {}
  }
  export class GetLocalItem {
    static readonly type = '[Format] GetLocalItem';
    constructor(public colId: number, public rowId: number) {}
  }
  export class UpdateColumn {
    static readonly type = '[Format] UpdateColumn';
    constructor(public colId: number, public payload: any) {}
  }
  export class UpdateRow {
    static readonly type = '[Format] UpdateRow';
    constructor(public rowId: number, public payload: any) {}
  }
  export class UpdateCell {
    static readonly type = '[Format] UpdateCell';
    constructor(public colId: number, public rowId: number, public payload: any) {}
  }
  export class UpdateItem {
    static readonly type = '[Format] UpdateItem';
    constructor(public colId: number, public rowId: number, public payload: any) {}
  }
  export class DeleteColumn {
    static readonly type = '[Format] DeleteColumn';
    constructor(public colId: number, public payload: any) {}
  }
  export class ViewColumn {
    static readonly type = '[Format] ViewColumn';
    constructor(public col: string) {}
  }
  export class DeleteRow {
    static readonly type = '[Format] DeleteRow';
    constructor(public rowId: number, public payload: any) {}
  }
  export class DeleteItem {
    static readonly type = '[Format] DeleteItem';
    constructor(public colId: number, public rowId: number, public payload: any) {}
  }
  export class DeletePageSuccess {
    static readonly type = '[Format] DeletePageSuccess';
    constructor(public pgId: number) {}
  }
  export class DeleteItemSuccess {
    static readonly type = '[Format] DeleteItemSuccess';
    constructor(public colId: number, public rowId: number) {}
  }
  export class DeleteRowSuccess {
    static readonly type = '[Format] DeleteRowSuccess';
    constructor(public pgId: number, public rowId: number) {}
  }
  export class ViewRow {
    static readonly type = '[Format] ViewRow';
    constructor(public rowId: string) {}
  }

  export class ViewCell {
    static readonly type = '[Format] ViewCell';
    constructor(public rowId: string, public colId: string) {}
  }

  export class ViewItem {
    static readonly type = '[Format] ViewItem';
    constructor(public rowId: string, public colId: string) {}
  }
}
