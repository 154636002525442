import { Component, ViewChild, OnInit, Inject, ElementRef, HostListener } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Sheet } from '../../../store/page/page.actions';
import { DragService } from '../../../core/services/drag-service/drag.service';
import { ResizeService } from '../../../core/services/resize-service/resize.service';
import { ASCII_EXPAND_ICON, ObjectType } from '../../../core/constants/app.contants';
import { Format } from '../../../store/format/format.actions';
import { SystemInitials } from '../../../constant';
import { RowComponent, Tabulator } from 'tabulator-tables';
import { TabUIService } from '../page/services/tab-ui.service';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import { DeletePgCol } from '../../../store/pg-tab/pg-tab.actions';
import { MainService } from '../../../core/services/main-service/main.service';

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.scss'],
})
export class DialogDeleteComponent implements OnInit {
  private modalRef!: NgbModalRef;
  public isFormValid: boolean = true;
  public failureMessage: string = '';
  public successMessage: string = '';
  public apiSuccessFlag: boolean = false;
  public closeResult: string = '';
  public expandIcon = ASCII_EXPAND_ICON;
  @ViewChild('content') popupContent!: ElementRef;

  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<DialogDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private store: Store,
    private dragService: DragService,
    private resizeService: ResizeService,
    private tabUIService: TabUIService,
    private mainService: MainService,
  ) { }

  ngOnInit() {}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContent);
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContent);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }
  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContent);
  }

  onDelete(): void {
    if (this.dialogData.name == ObjectType.DataObject.Item) {
      this.deleteItem();
    } else if (this.dialogData.name == ObjectType.DataObject.Page) this.deletePage();
      else if (this.dialogData.name == ObjectType.DataObject.Row) this.deleteRow();
      else if (this.dialogData.name == ObjectType.DataObject.Column) this.deleteColumn();
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef?.dismiss('Cancel click');
  }

  deleteColumn() {
    const colId = this.dialogData.colId;
    const colField = this.dialogData.colField;
    const tabulator: Tabulator = this.dialogData.tabulatorTable;
    const payload = {
      RecycledBy: SystemInitials.RecycledUser
    };

    // Dispatch an action to delete the column
    this.store.dispatch(new Format.DeleteColumn(colId, payload)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.store.dispatch(new DeletePgCol(colId)).subscribe(res => {});
          this.handleSuccess(`Success! Delete Column (ID: ${colId}) has been Deleted`);
          tabulator.deleteColumn(colField);

        } else {
          this.handleFailure(`Error! Delete Column (ID: ${colId}) ${data.format.apiStatus.message}`);
        }
      },      
    });    
  }

  deleteRow() {
    const rowComponent: RowComponent = this.dialogData.row;
    const rowId: number = this.dialogData.rowId;
    const pgId = rowComponent.getData()['page_id'];
    const payload = {
      RecycledBy: SystemInitials.RecycledUser
    };

    // Dispatch an action to delete the row
    this.store.dispatch(new Format.DeleteRow(rowId, payload)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          // this.tabUIService.deleteRow(row);
          this.store.dispatch(new Format.DeleteRowSuccess(pgId, rowId)).subscribe(res => {});
          this.handleSuccess(`Success! Delete Row (ID: ${rowId}) has been Deleted`);

        } else {
          this.handleFailure(`Error! Delete Row (ID: ${rowId}) ${data.format.apiStatus.message}`);
        }
      },      
    });    
  }

  deleteItem() {
    const colId: number = this.dialogData.colId;
    const rowId: number = this.dialogData.rowId;
    const payload = {
      RecycledBy: SystemInitials.RecycledUser
    };

    // Dispatch an action to delete the row
    this.store.dispatch(new Format.DeleteItem(colId, rowId, payload)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.store.dispatch(new Format.DeleteItemSuccess(colId, rowId)).subscribe(res => {});
          this.handleSuccess(`Success! Delete Item (ID: ) has been Deleted`);

        } else {
          this.handleFailure(`Error! Delete Item (ID: ) ${data.format.apiStatus.message}`);
        }
      },      
    });    
  }

  deletePage() {
    const pgId = Number(this.dialogData.pgId);
    const payload = {
      RecycledBy: SystemInitials.RecycledUser
    };

    // Dispatch an action to delete the column
    this.store.dispatch(new Format.DeletePage(pgId, payload)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.store.dispatch(new Format.DeletePageSuccess(pgId)).subscribe(res => {});
          this.handleSuccess(`Success! Delete Page (ID: ${pgId}) has been Deleted`);

        } else {
          this.handleFailure(`Error! Delete Page (ID: ${pgId}) ${data.format.apiStatus.message}`);
        }
      },      
    });    
  }

  handleSuccess(message: string) {
    this.isFormValid = true;
    this.apiSuccessFlag = true;
    this.successMessage = message;
  }

  handleFailure(message: string) {
    this.isFormValid = false;
    this.apiSuccessFlag = false;
    this.failureMessage = message;
  }
}
