import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SheetState } from '../../store/page/page.store';
import { UNICODE_MULTIPLY } from '../../core/constants/app.contants';

@Component({
  selector: 'app-all-pages-dialog',
  templateUrl: './all-pages-dialog.component.html',
  styleUrls: ['./all-pages-dialog.component.scss'],
})
export class AllPagesDialogComponent implements OnInit {
  @Select(SheetState.filterDDSPageData) pages$!: Observable<any[]>; // Use Observable for async data binding
  selectedPage: any;
  pages: any;
  displayedColumns: string[] = []; // Dynamic columns
  showDropdown: boolean = true; // Show dropdown by default
  gridColumns: string = ''; // Grid template columns string
  closeTab=UNICODE_MULTIPLY;
  constructor(
    public dialogRef: MatDialogRef<AllPagesDialogComponent>,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.pages$.subscribe((res) => {
      this.pages = res;
      if (this.pages.length > 0) {
        this.displayedColumns = Object.keys(this.pages[0]);
        this.gridColumns = `repeat(${this.displayedColumns.length}, minmax(0, 1fr))`;
      }
    });
  }
  selectPage(page: any) {
    this.dialogRef.close({ page: page, selectedPage: 'parent' }); // Emit selected page
  }
  selectchildPage(page: any) {
    this.dialogRef.close({ page: page, selectedPage: 'child' });
  }
  close() {
    this.dialogRef.close(false)
  }
}
