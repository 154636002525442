<div class="format-page-container" #formatPageContainer [style.minWidth.px]="headerTextWidth">
  <div class="dialog-header" (mousedown)="onDragStart($event)">
    <div class="header-text" #headerText>{{ dialogData.title }}</div>
  </div>
  <div *ngIf="!isFormValid" class="custom-error-message">{{ failureMessage }}</div>
  <div *ngIf="isFormValid && successMessage" class="custom-success-message">{{ successMessage }}</div>
  <div class="form-card" style="overflow: auto;" #scrollContainer>
    <app-format-page-form [dialogData]="dialogData" (formDataChange)="onFormDataChange($event)"></app-format-page-form>
    <div class="button-group">
      <div class="button-group-container">
        <div type="button" [ngClass]="{'disabled': apiSuccessFlag}" class="Chip" (click)="onSave();">Save</div>
      </div>
      <div *ngIf="!apiSuccessFlag" type="button" class="Chip" (click)="onCancel()">Cancel</div>
      <div *ngIf="apiSuccessFlag" type="button" class="Chip" (click)="onCancel()">Close</div>
    </div>
  </div>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <span [innerHTML]="expandIcon"></span>
  </div>
</div>