
export const MenuItems : string[] = [
  'Add Prev Row',
  'Add Next Row',
  'Add Child Row',
  'Delete Row',
  'Format Local-Row',
  'Format Shared-Row',
  'Select Row(s)',
  'View Row',
  'Open Page'
];

export enum ContextActions {
  ADD_NEXT_ROW = 'Add Next Row',
  ADD_PREV_ROW = 'Add Prev Row',
  ADD_CHILD_ROW = 'Add Child Row',
  DELETE_ROW = 'Delete Row',
  FORMAT_LOCAL_ROW = 'Format Local-Row',
  FORMAT_SHARED_ROW = 'Format Shared-Row',
  SELECT_ROW = 'Select Row(s)',
  VIEW_ROW = 'View Row'
}

export enum Page {
  ALL_PAGES = 1000000001,
  ALL_COLS = 1000000006,
}

export enum ContextMenu {
  FormatPage = 'Format Page',
  DeletePage = 'Delete Page',
  FormatLocalColumn = 'Format Local-Column',
  FormatLocalRow = 'Format Local-Row',
  FormatLocalItem = 'Format Local-Item',
  FormatLocalCell = 'Format Local-Cell',
}

export enum DataTypes {
 ML_TEXT =  "MLText",
 PAGE_ID =  "PageID",
 DROPDOWN =  "DropDown"
}


export enum PageItem {
  PAGE_ID = 'page_id',
  PAGE_TYPE = 'page_type'
}

export enum RowTypes {
  COL_ROW = 'Col-Row',
  PG_ROW = 'Pg-Row',
}


export const PAGE_TOKEN = 'All Pages';
export const PAGE_ID_DATA_TYPE = '3000001316';
export const SECTION = 'Section'
