
<div class="editDialog-container" #editdialogcontainer [style.minWidth.px]="headerTextWidth">
    <div class="modal-header" (mousedown)="onDragStart($event)">
      <div class="heading" #headerText>Edit-{{data.dataType}}-dialog</div>
    </div>
    <div class="edit-item-container dialog-overflow-container">
      <form [formGroup]="form">
        <div class="fields-container" formArrayName="languageGroups">
        @for(group of languageGroups.controls; track $index; let i = $index){
          <div [formGroupName]="i" class="language-group"
            [class.selected-row]="i<languageGroups.controls.length-1">
            <div class="form-group select-language">
              <div class="language-field-container">
              <label for="language{{i}}" class="font-style">Language(s)</label>
              <select id="language{{i}}"
                (ngModelChange)="onSelect($event,i)"
                formControlName="language"
                 class="language-select">
                @for(item of this.languageOptions; track $index){
                  <option
                    [disabled]="item.row | isLanguageDisabled:selectedLanguages:i"
                    [value]="item.row">
                    {{ item.language }}
                  </option>
                }
              </select>
            </div>
            </div>
            <div class="form-group rich-text-editor">
              <div class="text-editor-container">
              <label for="richText{{i}}" class="font-style">Language-specific Rich-Text(s)</label>
              <div>
                <div class="main-container">
                  <div class="editor-container editor-container_classic-editor" #editorContainerElement>
                    <div class="editor-container__editor">
                      <div #editorElement>
                        <ckeditor
                          id="richText{{i}}"
                          (change)="onValueChange($event,i)"
                          formControlName="richText"
                          [editor]="Editor"
                          [config]="config"
                          *ngIf="isLayoutReady"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <button
              [ngStyle]="{display: languageGroups.controls.length==1?'none':'flex'}"
              type="button"
              class="close"
              aria-label="Close">
              <div class="cross-icon" (click)="removeLanguageGroup(i)">
                {{closeIcon}}
              </div>
            </button>
          </div>
        }
        </div>
          <div class="button-group">
            <div class="button-container">
              <button
                class="Chip"
                [disabled]="this.form.pristine
                  || this.form.invalid
                  || (isLoading$ | async)"
                (click)="onSave()">
                Save
              </button>
              </div>
            <div type="button" class="Chip" (click)="onCancel()" >Cancel</div>
          </div>
      </form>
    </div>
    <div class="resizer" (mousedown)="onResizeStart($event)">
      <span [innerHTML]="expandIcon"></span>
    </div>
</div>
@if(isLoading$ | async){
  <div class="loader-overlay">
    <div class="loader">
    </div>
  </div>
}
