import {
  Component,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  OnInit,
  AfterViewInit,
  inject,
  DestroyRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CellComponent,
  ColumnComponent,
  RowComponent,
  TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { DialogEditComponent } from '../dialog-edit/dialog-edit.component';
import { DialogDeleteComponent } from '../dialog-delete/dialog-delete.component';
import { DialogViewComponent } from '../dialog-view/dialog-view.component';
import { DialogFormatComponent } from '../../../modals/dialog-format/dialog-format.component';
import { FormatLocalSharedColComponent } from '../../../modals/format-local-shared-col/format-local-shared-col.component';
import { AddRowDialogComponent } from '../add-row-dialog/add-row-dialog.component';
import { MainService } from '../../../core/services/main-service/main.service';
import { Select, Store } from '@ngxs/store';
import {
  distinctUntilChanged,
  map,
  Observable,
  skip,
  Subject,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { SheetState } from '../../../store/page/page.store';
import { SheetService } from '../../../core/services/sheet-service/sheet.service';
import { ItemState } from '../../../store/item/item.store';
import { FormatUIService } from '../../../core/services/format-ui-service/format-ui.service';
import {
  COL_STATUSES,
  FILTER_TYPES,
  SystemInitials,
  pagesId,
} from '../../../constant';
import {
  All_Pages_ID,
  ASCII_DASH,
  ASCII_PLUS,
  ASCII_SPACE,
  ObjectType,
  UNICODE_DOWN_ARROW,
  UNICODE_UP_ARROW,
} from '../../../core/constants/app.contants';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import { TabulatorFormatters } from '../../../core/enums/tabulator-events/events';
import {
  ContextActions,
  ContextMenu,
  DataTypes,
  MenuItems,
  Page,
  PAGE_ID_DATA_TYPE,
  PAGE_TOKEN,
  PageItem,
} from '../../../core/constants/menu-bar/page/page';
import { PgRowStore } from '../../../store/row/row.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RowPageData } from '../../models/edit-dd-dailogs/dd-dailog.models';
import { ColRowStore } from '../../../store/cols/cols.store';
import { FormatPageComponent } from '../format-page-dialog/format-page/format-page-main.component';
import { FetchLanguages } from '../../../store/pg-tab/pg-tab.actions';
import {
  AddItemDataInRow,
  EditItemDataInRow,
} from '../../../store/item/item.action';
import { editDdDialogMainComponent } from '../edit-dd-dialog/edit-dd-dialog-main/edit-dd-dialog-main.component';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';
import {
  AddItemPayload,
} from '../../models/item/item.model';
import { FormMode } from '../../../core/enums/forms/form';
import { PageModelData } from '../../../core/interfaces/page.iterface';
import { AppStore } from '../../../store/root/root.store';
import { FilterService } from '../../../core/services/filter-service/filter.service';
declare module 'tabulator-tables' {
  interface ColumnDefinition {
    validationFlag?: boolean; // custom property
  }
}

@Component({
  selector: 'app-sheet',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnChanges, OnInit, AfterViewInit {
  destroyRef = inject(DestroyRef);
  sheet: any;
  private tabulatorTable!: Tabulator;
  public showDropdown: boolean = false;
  public deletedColumnDef: any;
  public deleteColumnName: any;
  private currentColumn: any = null;
  showRowHeader: boolean = false;
  load: any = false;
  @ViewChild('tabulatorDiv', { static: true }) tabulatorDiv!: ElementRef;
  private currentCell: any = null;
  freezLevel: number = 0;
  expandLevels: boolean[] = [];
  public sortStatus = false;
  private isFilter: boolean = false;
  private unsubscribe$ = new Subject<void>();
  disabled: boolean = false;
  private languageArray: [{}] = [{}];
  public columnData: any[] = [];
  height: any = 0;
  width: any = 0;
  previousFontSize: number;
  currentFontSize: number;
  lineDiv: any;
  hasChildrenInAnyRow = false;
  isLoading$: Observable<boolean> = inject(Store).select(PgTabStore.isLoading);
  expandLevel$: Observable<number> = inject(Store).select(AppStore.getExpandLevel);
  isRowLoading$: Observable<boolean> = inject(Store).select(
    PgRowStore.isRowLoading
  );
  isColLoading$: Observable<boolean> = inject(Store).select(
    ColRowStore.isRowLoading
  );
  isItemLoading$: Observable<boolean> = inject(Store).select(
    ItemState.isLoading
  );
  columnsWithHeaderContext: any = [];
  viewcolumnData = {};
  formMode = FormMode;
  showAddEdit = {
    add : true,
    edit : true
  }
  constructor(
    private mainService: MainService,
    public dialog: MatDialog,
    private store: Store,
    private sheetService: SheetService,
    private formatUIService: FormatUIService,
    private filterService: FilterService,
  ) {
    this.previousFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.currentFontSize = this.previousFontSize;
  }
  allData: any = ([] = []);
  activeSheet: any;
  tabFlatData: any = ([] = []);
  column: any;
  colMinWith: number = 0;
  levels: any;
  languagesList: any = {};
  currentPageId: string = '';
  @Select(SheetState.getSheetDataById) data$!: Observable<RowPageData[]>;
  @Select(SheetState.getSheetFlatDataById) tabFlatData$!: Observable<any>;
  @Select(SheetState.getSheetColumnsById) columns$!: Observable<any>;
  @Select(PgTabStore.getLanguages) languages$!: Observable<any>;
  @Select(PgTabStore.getFontStyle) fontStyle$!: Observable<any>;
  @Select(SheetState.formatLocalCol) res$!: Observable<any>;
  @Select(SheetState.getVisitedData) visited$!: Observable<any>;
  defaultPgTab$: Observable<PgTab> = inject(Store).select(
    PgTabStore.getSelectedPgTab
  );
  width60: any;
  selectedPage: string = '';
  newRowData = {
    row: 3000000017,
    page_id: 1000000017,
    page_name: '',
    page_type: '',
    page_edition: '',
    page_owner: '',
    page_url: 'URL to open this Page',
    page_seo: '',
    page_status: '',
    page_comment: '',
  };
  ngOnInit(): void {
    this.mainService.sortStatus.subscribe((value)=>{
      if(value==='Sort is On'){
        this.enableSort();
      }
    })
    this.defaultPgTab$
      .pipe(skip(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((activeTab) => {
        this.currentPageId = activeTab.page_id as string;
        this.showAddEdit = {
          add : true,
          edit : true
        }
    });
    this.getData();
    this.renderTabulator();
    this.freezeLevel();
    this.expandLevel();
    this.getLanguageList();
    this.languages$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.languagesList = data;
      data?.data['ALL Languages'].map((item: any, index: number) => {
        this.languageArray[index] = {
          language: Object.values(item),
          row: Object.keys(item),
        };
      });
    });
    this.mainService.sortStatus.subscribe((sortStatus) => {
      if (sortStatus == 'Sort is Off') {
        this.sortStatus = false;
        this.tabulatorTable?.clearSort();
      } else if (sortStatus == 'Sort is On') {
        this.sortStatus = true;
      }
    });
    this.width60 = window.innerWidth * 0.6;
    this.mainService.filterStatus.subscribe((filterStatus) => {
      if (filterStatus == 'Filter is Off') {
        this.updateTableWithFilters(false);
      } else if (filterStatus == 'Filter is On') {
        this.updateTableWithFilters(true);
      }
    });
    this.adjustCheckboxSize();
    window.addEventListener('resize', this.adjustCheckboxSize.bind(this));
  }
  enableSort():void {
    const sorterElements = this.tabulatorDiv.nativeElement.querySelectorAll('.sorter');
    if (sorterElements.length > 0) {
      sorterElements.forEach((element: HTMLElement) => {
        element.classList.add('hover-enabled');
      });
    }
  }

  private adjustCheckboxSize() {
    const bodyFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.height = bodyFontSize / this.previousFontSize - 0.2;
    this.width = bodyFontSize / this.previousFontSize - 0.2;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.clearErrorMessage();
    this.clearFilterCheckbox();
    this.activeSheet = changes['sheet']['currentValue']['name'];
    this.mainService.setPageEdition(this.sheet.page_edition);
  }

  clearFilterCheckbox() {
    const filterCheckBox = document.getElementById(
      'filterCheckbox'
    ) as HTMLInputElement;
    filterCheckBox.checked = false;

    this.mainService.refreshFilterCheckbox();
  }

  clearErrorMessage(): void {
    const errorMessageElement = document.getElementById('errorMessage');
    if (errorMessageElement) {
      errorMessageElement.style.display = 'none';
      this.currentCell.getElement().style.backgroundColor = 'none';
    }
  }

  async getData() {
    this.data$?.subscribe(async (data) => {

      if (data && data.length) {
        const hasChildrenInAnyRow = { value: false };
        this.allData = [...data];
        this.hasChildrenInAnyRow = hasChildrenInAnyRow.value;
      } else {
        this.tabulatorTable?.setData([]);
      }

      // Emit currect filter status to preserve filter status
      setTimeout(() => {
        this.mainService.emitCurrentFilter();
      }, 500);
    });

    this.tabFlatData$
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (flatData) => {
        if (flatData && flatData.length) {
          this.tabFlatData = [...flatData];
        }
      });
  }

  showColumnContextMenu(event: MouseEvent, column: ColumnComponent) {
    event.preventDefault();
    // Check if the event target has the 'column-header' class
    if (
      !(event.target as HTMLElement).classList.contains('tabulator-col-title')
    )
      return;

    this.viewcolumnData = column?.getDefinition();
    event.preventDefault();

    this.showDropdown = !this.showDropdown;
    this.deletedColumnDef = column?.getDefinition();
    this.deleteColumnName = column.getDefinition().title;

    // Ensure the dropdown menu is available after setting showDropdown to true
    setTimeout(() => {
      const dropdownMenu = document.querySelector(
        '.dropdown-container'
      ) as HTMLElement;
      if (!dropdownMenu) return;

      // Get delete column menu item
      const deleteColumnSpan = Array.from(
        dropdownMenu.querySelectorAll('.dropdown-menu-item')
      ).find((span) => span.textContent?.trim() === 'Delete Column');

      // Disable menu item
      this.deletedColumnDef.status.includes('Nested')
        ? deleteColumnSpan?.classList.add('no-color', 'disabled')
        : '';

      // Get the position of the header cell
      const headerCell = event.target as HTMLElement;
      const rect = headerCell.getBoundingClientRect();

      // Adjust x and y coordinates
      const newX = rect.left; // Start of the header column
      const newY = rect.bottom; // Below the bottom border of the header column box

      dropdownMenu.style.top = `${newY}px`;
      dropdownMenu.style.left = `${newX}px`;
    }, 0);

    // Remove dropdown menu on clicking anywhere outside it
    this.column = column.getField();
    document.body.addEventListener('click', this.hideDropdown);
  }

  hideDropdown = (event: MouseEvent) => {
    // Ensure the click event is outside the dropdown menu
    const dropdownMenu = document.querySelector(
      '.dropdown-container'
    ) as HTMLElement;
    if (dropdownMenu && !dropdownMenu.contains(event.target as Node)) {
      this.showDropdown = false;
      // Remove the event listener to avoid multiple bindings
      document.body.removeEventListener('click', this.hideDropdown);
    }
  };

  deleteColumn() {
    if (!this.deletedColumnDef.status.includes(COL_STATUSES.NESTED)) {
      const dialogRef = this.dialog.open(DialogDeleteComponent, {
        data: {
          title: `Delete: Column (ID: ${this.deletedColumnDef.col})`,
          colId: this.deletedColumnDef.col,
          colField: this.deletedColumnDef.field,
          data: "shall be Deleted? Click 'Delete' to confirm ",
          name: ObjectType.DataObject.Column,
          tabulatorTable: this.tabulatorTable,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {});

      this.showDropdown = false;
      document.body.removeEventListener('click', this.hideDropdown);
    }
  }

  ngAfterViewInit(): void {
    document.addEventListener('click', () => this.hideDropdownMenu());
    this.freezeLevel();
  }

  dataSelection(sheet: any) {
    let selectedColumns;
    selectedColumns = this.allData;
    return selectedColumns;
  }

  underlineFormatter = (cell: { getValue: () => any }) => {
    const value = cell.getValue();
    if (value) {
      return `<span style="text-decoration: underline;">${value}</span>`;
    } else {
      return value;
    }
  };

  boldSectionHead = (cell: { getValue: () => any; getData: () => any }) => {
    const value = cell.getValue();
    const data = cell.getData();
    if (data.RowLevel == 0) {
      return `<span style="font-weight:bold;">${value}</span>`;
    }
    return value;
  };

  checkColumnForSemicolons(column: any): boolean {
    return this.mainService.checkColStatus(column.status);
  }

  generateColumnCode(columnSelection: any = []) {
    if (columnSelection.length != 0) {
      // First, sort columns so that visible ones come at the start
      const sortedColumns = columnSelection.sort((a: any, b: any) => {
        // If the column is visible, it should come before the ones that are not visible
        if (a.visible && !b.visible) return -1; // a comes before b
        if (!a.visible && b.visible) return 1; // b comes before a
        return 0; // If both are either visible or not, keep their relative order
      });
      const columns = sortedColumns.map((column: any, index: number) => {
        const hasSemicolon = this.checkColumnForSemicolons(column); // Check if the column has semicolons
        const formatter = (
          cell: any,
          formatterParams: any,
          onRendered: any
        ) => {
          const value = cell.getValue();
          // If the column has a semicolon, format all items in that column with chips
          if (hasSemicolon) {
            return this.chipFormater()(cell, formatterParams, onRendered);
          } else {
            return value; // Return the original value if no semicolon
          }
        };
        // Apply freezing logic based on column index and visibility
        if (index < +this.freezLevel && this.freezLevel != 0) {
          if (column.visible === true) {
            column.frozen = true;
          }
        } else {
          column.frozen = false;
        }
        // Apply formatter logic for specific conditions
        if (column.status.includes('Nested')) {
          return {
            ...column,
            formatter: this.boldSectionHead,
            cssClass: 'wrap-text',
          };
        }
        // Use underline formatter for "Page URL" column
        if (column.field === 'page_url' && column.field !== 'undefined') {
          return { ...column, formatter: this.underlineFormatter };
        } else if (
          column.field === 'row_comment' ||
          column.field === 'col_comment'
        ) {
          return {
            ...column,
            formatter: TabulatorFormatters.TEXT_AREA,
          };
        } else {
          return { ...column, formatter }; // Apply the dynamic formatter to other columns
        }
      });
      return columns;
    }
  }

  chipFormater() {
    return (cell: any, formatterParams: any, onRendered: any) => {
      const value = cell.getValue();

      // Create a container for chips
      const chipContainer = document.createElement('div');
      chipContainer.classList.add('button-container');

      // Split by semicolon or treat as single item
      const items =
        value && typeof value === 'string'
          ? value.includes(';')
            ? value.split(';').map((item) => item.trim())
            : [value.trim()]
          : [];

      // Create chips for each item
      items.forEach((item) => {
        // Create the outer div for each item
        const outerDiv = document.createElement('div');
        outerDiv.className = 'menu-item-container'; // Set the class name

        // Create the Button div
        const chipDiv = document.createElement('div');
        chipDiv.className = 'Chip';
        chipDiv.title = item; // Set the chip tooltip
        chipDiv.textContent = item;

        // Append the Button div to the outer div
        outerDiv.appendChild(chipDiv);

        // Append the outer div to the chip container
        chipContainer.appendChild(outerDiv);
      });

      // Remove the cell tooltip by removing the title attribute
      cell.getElement().removeAttribute('title');

      return chipContainer.outerHTML;
    };
  }

  private renderTabulator(): void {
    let selectedColumns: any = [];
    if (this.tabulatorTable) {
      this.tabulatorTable.destroy();
    }
    this.load = true;
    this.columns$
      ?.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((columns) => {
        if (this.showRowHeader) {
          this.columnsWithHeaderContext.unshift({
            titleFormatter: 'rowSelection',
            titleFormatterParams: {
              rowHandle: true, // Ensure it is recognized as a row selection column
            },
            headerSort: false,
            resizable: false,
            hozAlign: 'center',
            cellClick: function (
              e: any,
              cell: { getRow: () => { toggleSelect: () => void } }
            ) {
              cell.getRow().toggleSelect();
            },
            headerHozAlign: 'center',
            formatter: 'rowSelection',
          });
        }
        selectedColumns = columns;
        this.load = false;
        this.columnsWithHeaderContext = this.generateColumnCode(
          this.columnsWithHeaderContext
        )?.map((column: any) => {
          return {
            ...column,
            headerClick: (e: any, column: any) => {
              this.headerClickFunc(e, column);
            },
          };
        });
        this.columnsWithHeaderContext = selectedColumns?.map((column: any) => {
          const isVisible = !column.status.includes('Hidden');
          return {
            ...column,
            validationFlag: true,
            headerContext: this.showColumnContextMenu.bind(this),
            headerFilterLiveFilter: false,
            visible: isVisible,
            headerFilter:
              this.isFilter == true ? this.customFilterEditor : false,
            headerFilterFunc: this.filterService.customFilterFunction,
            headerClick: (e: any, column: any) => {
              this.headerClickFunc(e, column);
            },
            contextMenu:
              ((this.currentPageId === Page.ALL_COLS.toString() &&
                column.field === 'page_id') &&
                column.field !== 'row'
                ) ||
              (column.field !== 'row' &&
                column.field !== 'page_id')
                ? (e: any, cell: any) => {
                    this.currentCell = cell;
                    this.showDropdownMenu(e, cell,column?.field);
                    if (
                        this.currentCell['_cell']['value'] &&
                        this.currentCell['_cell']['value']?.length > 0
                      ) {
                        this.disabled = true;
                      }else{
                        this.disabled = false;
                      }
                  } : ''
          };
        });
        const nestedColumn = this.columnsWithHeaderContext.find((column: any) =>
          column.status?.includes('Nested')
        )?.field;



        this.tabulatorTable = new Tabulator(this.tabulatorDiv?.nativeElement, {
          data: this.dataSelection(this.sheet),
          columns: this.generateColumnCode(this.columnsWithHeaderContext),
          frozenRows: 0,
          dataTree: true,
          dataTreeFilter: true,
          dataTreeStartExpanded: true,
          dataTreeElementColumn: nestedColumn,
          dataTreeCollapseElement: '<div></div>',
          dataTreeExpandElement: '<div></div>',
          addRowPos: 'bottom',
          validationMode: 'highlight',
          columnHeaderSortMulti: true,
          headerSortClickElement: 'icon',
          columnDefaults: {
            resizable: true,
            headerSortTristate: true,
            maxWidth: this.width60,
          },
          progressiveLoad: 'scroll',
          layout: 'fitDataFill',
          layoutColumnsOnNewData: true,
          movableColumns: true,
          spreadsheet: true,
          spreadsheetRows: 250,
          autoResize: true,
          resizableColumnFit: true,
          movableRows: false,
          headerSortElement: function (column, dir) {
            switch (dir) {
              case 'asc':
                return (
                  '<div class="sorter">' + `${UNICODE_UP_ARROW}` + '</div>'
                );
              case 'desc':
                return (
                  '<div class="sorter">' + `${UNICODE_DOWN_ARROW}` + '</div>'
                );
              default:
                return (
                  '<div class="sorter">' + `${UNICODE_UP_ARROW}` + '</div>'
                );
            }
          },
          rowFormatter: (row) => {
            this.formatRow(row, nestedColumn);
            row.getCells().forEach((cell) => {
              const value = cell.getValue();
              if (value && typeof value === 'string' && value.includes(';')) {
                cell.getElement().removeAttribute('title');
              } else {
                // Ensure tooltip is available for all other cells
                cell.getElement().setAttribute('title', value);
              }
            });

            const rowHandle = row
              .getElement()
              .querySelector(`[tabulator-field="row"]`) as HTMLElement;
            if (rowHandle) {
              const rowData = row.getData(); // Get data for the current row
              rowHandle.addEventListener('contextmenu', (e: MouseEvent) => {

                e.preventDefault(); // Prevent the default context menu

                // Calculate the position for the context menu
                const menuHeight = 200; // Approximate height of the context menu
                const menuWidth = 150; // Approximate width of the context menu
                const { clientX: mouseX, clientY: mouseY } = e;

                // Get the dimensions of the viewport
                const viewportHeight = window.innerHeight;
                const viewportWidth = window.innerWidth;

                // Determine the row's left position
                const rowLeft = rowHandle.getBoundingClientRect().left;

                // Calculate if there is enough space below and adjust the menu's position
                const isSpaceBelow = mouseY + menuHeight <= viewportHeight;
                const isSpaceRight = mouseX + menuWidth <= viewportWidth;

                const top = isSpaceBelow ? mouseY : mouseY - menuHeight; // Show upwards if no space below
                const left = isSpaceRight ? rowLeft : Math.max(0, rowLeft - menuWidth); // Align to row or adjust to viewport


                // Call the context menu creation method
                this.createContextMenu(e, row, rowData, top, left);
              });
            }
            this.fontStyle$?.subscribe((result: any) => {
              if (result != undefined) {
                let res = result['pageFontStyle']?.split(':')[0];
                row.getElement().style?.minWidth
                  ? (row.getElement().style.minWidth = this.colMinWith + 'px')
                  : '';
                row.getElement().style?.backgroundColor
                  ? (row.getElement().style.backgroundColor = res.split(';')[5])
                  : '';
                row.getElement().style?.color
                  ? (row.getElement().style.color = res.split(';')[0])
                  : '';
                row.getElement().style?.fontSize
                  ? (row.getElement().style.fontSize = res.split(';')[2])
                  : '';
                row.getElement().style?.textAlign
                  ? (row.getElement().style.textAlign = res.split(';')[4])
                  : '';
                if (
                  res?.split(';')[3] == '0' &&
                  row.getElement().style.fontStyle
                ) {
                  row.getElement().style.fontStyle =
                    res?.split(';')[3] == '0' ? 'normal' : '';
                } else if (
                  res?.split(';')[3] == '2' &&
                  row.getElement().style.fontWeight
                ) {
                  row.getElement().style.fontWeight =
                    res?.split(';')[3] == '2' ? 'bold' : '';
                } else if (
                  res?.split(';')[3] == '1' &&
                  row.getElement().style.fontStyle
                ) {
                  row.getElement().style.fontStyle =
                    res?.split(';')[3] == '1' ? 'italic' : '';
                }
                if (
                  res?.split(';')[6] == 'italicText' &&
                  row.getElement().style.fontStyle
                ) {
                  row.getElement().style.fontStyle = 'italic';
                } else if (
                  res?.split(';')[6] == 'boldText' &&
                  row.getElement().style.fontWeight
                ) {
                  row.getElement().style.fontWeight = 'bold';
                } else if (res?.split(';')[6] == 'strikeThrough') {
                  row.getElement().classList.add('text-linethrough-class');
                } else if (res?.split(';')[6] == 'underline') {
                  row.getElement().classList.add('text-underline-class');
                } else if (res?.split(';')[6] == 'textWrap') {
                  row.getElement().classList.add('text-wrap-class');
                } else if (res?.split(';')[6] == 'superScript') {
                  const element = row.getElement();
                  const originalText = element.innerHTML;
                  element.innerHTML = originalText.replace(
                    originalText,
                    '<sup>' + originalText + '</sup>'
                  );
                } else if (res?.split(';')[6] == 'subScript') {
                  const element = row.getElement();
                  const originalText = element.innerHTML;
                  element.innerHTML = originalText.replace(
                    originalText,
                    '<sub>' + originalText + '</sub>'
                  );
                } else if (
                  res?.split(';')[6] == 'textBorder' &&
                  row.getElement().style.border
                ) {
                  row.getElement().style.border = '1px solid';
                }
                row.getElement().style?.fontFamily
                  ? (row.getElement().style.fontFamily = res?.split(';')[1])
                  : '';
              }
            });
          },
        });

        // Attach the event for updating column order
        this.tabulatorTable.on('columnMoved', (column, columns) => {
          this.sheetService.orderColumns(this.sheet.page_id, columns);
        });

        // Attch the event for column resizing
        this.tabulatorTable.on('columnResized', (column: ColumnComponent) => {
          const columnDef = column.getDefinition(); // Get column definition
          const validationFlag = columnDef.validationFlag; // Access the custom flag

          // Check minWidth logic
          const currentWidth = column.getWidth();
          if (!validationFlag && currentWidth < SystemInitials.MinWidth) {
            column.setWidth(100);
          }
        });

        this.tabulatorTable.on('rowClick', (e, row) => {
          const element = e.target as HTMLElement;
          if (element.id === 'RowHeaderID') {
            // Toggle expansion state
            const isExpanded = row.isTreeExpanded();
            isExpanded ? row.treeCollapse() : row.treeExpand();
          }
        });

        setTimeout(() => {
          if (this.isFilter) {
            this.updateTableWithFilters(true);
          } else {
            this.updateTableWithFilters(false);
          }
        }, 300);
      });
  }

  formatRow(row: any, nestedColumn: string): void {
    const depth = row.getData().RowLevel; // Get the RowLevel
    const hasChildren = row.getTreeChildren().length > 0;
    if (!this.hasChildrenInAnyRow) {
      row
        .getCells()
        .forEach((cell: { getElement: () => any; getField: () => string }) => {
          const cellElement = cell.getElement();
        });
    }
    row
      .getCells()
      .forEach((cell: { getElement: () => any; getField: () => string }) => {
        const field = cell.getField();
        if (field === nestedColumn) {
          const cellElement = cell.getElement();
          Array.from(cellElement.querySelectorAll('.line')).forEach((line) => {
            (line as HTMLElement).remove();
          });
          const parentDiv = document.createElement('div');
          parentDiv.classList.add('RowHeaderDiv');

          if (depth > 0) {
            for (let i = 0; i < depth; i++) {
              const lineDiv = document.createElement('div');
              if (hasChildren && i == depth - 1) {
                lineDiv.classList.add('RowHeader');
                lineDiv.innerHTML = row.isTreeExpanded()
                  ? ASCII_DASH
                  : ASCII_PLUS;
                lineDiv.id = 'RowHeaderID';
              } else {
                lineDiv.classList.add('RowHeader');
                lineDiv.innerHTML = ASCII_SPACE;
              }
              parentDiv.append(lineDiv);
              cellElement.appendChild(parentDiv);
            }
          }
          if (depth == 0) {
            const lineDiv = document.createElement('div');
            lineDiv.classList.add('SectionRowHeader');
            lineDiv.innerHTML = row.isTreeExpanded() ? ASCII_DASH : ASCII_PLUS;
            lineDiv.id = 'RowHeaderID';
            parentDiv.append(lineDiv);
            cellElement.appendChild(parentDiv);
          }
        } else {
          const cellElement = cell.getElement();
        }
      });
  }

  freezeLevel() {
    this.mainService.pageFormate.subscribe((res) => {
      this.freezLevel = res.pageFreezeColumn;
      const columns = this.columnsWithHeaderContext.map(
        (column: any, index: number) => {
          const isVisible = !column.status.includes('Hidden');
          const isFrozen = index < res.pageFreezeColumn;
          return {
            ...column,
            frozen: isFrozen,
            visible: isVisible,
          };
        }
      );
      this.tabulatorTable?.setColumns(this.generateColumnCode(columns));
      // this.store.dispatch(new Sheet.updateColumns(columns));
    });
  }

  expandLevel(): boolean[] {
    this.expandLevel$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((level)=> this.currentPageId === All_Pages_ID ? level - 1  :  level),
      ).subscribe((res) => {
        if (res != null) {
          this.tabulatorTable.blockRedraw();
          this.expandLevels = Array(res).fill(true);
          const rows = this.tabulatorTable.getRows();
          if (rows) {
            if (res === 0) {
              rows.forEach((row) => row.treeCollapse());
            } else {
              rows.forEach((row) => this.expandToDepth(row, res));
            }
          }
        this.tabulatorTable.restoreRedraw();
      }
      });
    return this.expandLevels;
  }

  expandToDepth(row: any, depth: number) {
    if (depth > 0) {
      row.treeExpand();
      const children = row.getTreeChildren();
      children?.forEach((child: RowPageData) =>
        this.expandToDepth(child, depth - 1)
      );
    } else {
      row.treeCollapse();
    }
  }

  createContextMenu(e: MouseEvent, row: RowComponent, rowData: any, top: number, left: number): void {
    const currentRow = row;
    e.preventDefault();
    const menu = document.createElement('div');
    menu.style.position = 'absolute';
    menu.style.zIndex = '10000';
    menu.style.top = `${top}px`;
    menu.style.left = `${left}px`;
    menu.style.backgroundColor = '#fff';
    menu.style.border = '1px solid #ccc';
    menu.style.borderRadius = '5px';
    menu.classList.add('context-menu');
    menu.style.overflow = 'auto';
    // menu.style.height = '185px';
    menu.style.scrollbarWidth = 'none';

    const addRowOptions = [
      ContextActions.ADD_CHILD_ROW,
      ContextActions.ADD_PREV_ROW,
      ContextActions.ADD_NEXT_ROW,
    ];

    MenuItems.forEach((label: any) => {
      const menuItem = document.createElement('div');
      menuItem.innerText = label;
      menuItem.style.marginRight = '2px';
      menuItem.style.marginBottom = '2px';
      menuItem.style.cursor = 'pointer';
      menuItem.classList.add('item-menu');
      // Check the page name and set the color dynamically
      if (label === 'Open Page') {
        this.mainService.pageName$.subscribe((pageName) => {
          menuItem.style.color = pageName === 'All Pages' ? 'black' : 'gray';
         // Add click event listener
          menuItem.addEventListener('click', () => {
            pageName === 'All Pages' ? this.pageOpen(rowData) : null;
          });
          });
      }
      menuItem.addEventListener('click', () => {
        // Check if the label matches one of the titles to show a dialog
        if (addRowOptions.includes(label)) {
          this.dialog.open(AddRowDialogComponent, {
            data: {
              title: label,
              currentRow,
            },
          });
        }
      });
      menuItem.addEventListener('click', () => {
        this.handleMenuAction(label, row);
        if (menu.parentElement) {
          menu.parentElement.removeChild(menu);
        }
      });
      menu.appendChild(menuItem);
    });

    // Remove all previous context menus that was appended
    const contextMenus = Array.from(
      document.getElementsByClassName('context-menu')
    );
    if (contextMenus.length > 0) {
      contextMenus.forEach(function (menu) {
        if (menu != null && menu.parentElement != null) {
          menu.parentElement.removeChild(menu);
        }
      });
    }

    document.body.appendChild(menu);
    document.addEventListener(
      'click',
      () => {
        if (menu.parentElement) {
          menu.parentElement.removeChild(menu);
        }
      },
      { once: true }
    );
  }

  private toggleRowHeader(): void {
    this.showRowHeader = !this.showRowHeader;
    this.renderTabulator();
  }
  handleMenuAction(action: string, row: RowComponent): void {
    switch (action) {
      case 'Add Previous Row':
        this.addRow('Add Previous Row', row);
        break;
      case 'Add Next Row':
        this.addRow('Add Next Row', row);
        break;
      case 'Add Child Row':
        this.addRow('Add Child Row', row);
        break;
      case ContextActions.DELETE_ROW:
        this.deleteRow(row);
        break;
      case ContextMenu.FormatLocalRow:
        this.formatLocalRow(row);
        break;
      case 'Format Shared-Row':
        break;
      case 'Select Row(s)':
        this.toggleRowHeader();
        break;
      case 'View Row':
        this.viewRow(row);
        break;
    }
  }
  pageOpen(rowData:any){
    this.mainService.openPage$.next(rowData);
  }
  addRow(position: string, row: RowComponent): void {
    if (!row) {
      return; // Handle the absence of a row
    }
    const { sheetType, newRowData } = this.initializeNewRowData(row);
    if (!sheetType || !newRowData) {
      console.error('Unknown sheet type or initialization error');
      return;
    }
    const { currentRowData, currentRowIndex, parentRow } =
      this.getCurrentRowInfo(row);
    if (currentRowIndex === -1) {
      return;
    }
    const siblingInfo = this.getSiblingInfo(
      position,
      parentRow,
      row,
      currentRowIndex
    );
    const dialogRef = this.dialog.open(AddRowDialogComponent, {
      data: { rowDetails: position, rowData: row, siblingInfo: siblingInfo },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateNewRowData(newRowData, result, sheetType);
        console.log('New Row Data:', newRowData); // Log the new row data
        this.insertNewRow(
          position,
          row,
          parentRow,
          currentRowIndex,
          newRowData,
          sheetType
        );
      }
    });
  }

  private initializeNewRowData(row: RowComponent): {
    sheetType: string;
    newRowData: any;
  } {
    let sheetType: any = '';
    this.mainService.selectedId.subscribe((res) => {
      sheetType = res;
    });

    let newRowData: any = {};
    if (sheetType == pagesId.All_Pages) {
      newRowData = {
        row: '',
        page_id: '',
        page_name: '',
        page_type: '',
        page_edition: '',
        page_owner: '',
        page_url: '',
        page_seo: '',
        page_status: '',
        page_comment: '',
      };
    } else if (sheetType == pagesId.AllCols) {
      newRowData = {
        row: '',
        col_id: '',
      };
    }
    return { sheetType, newRowData };
  }

  private getCurrentRowInfo(row: RowComponent): any {
    const currentRowData = row.getData();
    const data = row.getTable().getData(); // Get all data from the table
    let currentRowIndex = -1;
    let parentRow: any = null;

    if (row.getTreeParent()) {
      parentRow = row.getTreeParent(); // Get the parent of the current row
      const siblings = parentRow.getData()['_children'] || [];
      currentRowIndex = siblings.findIndex(
        (item: { row: any }) => item.row === currentRowData['row']
      );
    } else {
      currentRowIndex = data.findIndex(
        (item) => item.row === currentRowData['row']
      );
    }

    return { currentRowData, currentRowIndex, parentRow };
  }

  private getSiblingInfo(
    position: string,
    parentRow: any,
    row: RowComponent,
    currentRowIndex: number
  ): any {
    const data = row.getTable().getData();
    let siblingInfo = null;

    switch (position) {
      case 'Add Previous Row':
        if (currentRowIndex > 0) {
          siblingInfo = parentRow
            ? parentRow.getData()['_children'][currentRowIndex - 1]
            : data[currentRowIndex - 1];
        }
        break;
      case 'Add Next Row':
        if (parentRow) {
          if (currentRowIndex + 1 < parentRow.getData()['_children'].length) {
            siblingInfo = parentRow.getData()['_children'][currentRowIndex + 1];
          }
        } else {
          if (currentRowIndex + 1 < data.length) {
            siblingInfo = data[currentRowIndex + 1];
          }
        }
        break;
      case 'Add Child Row':
        siblingInfo = row.getData(); // Current row is the parent for child
        break;
    }

    return siblingInfo;
  }

  private updateNewRowData(
    newRowData: any,
    result: any,
    sheetType: string
  ): void {
    if (+sheetType === pagesId.All_Pages) {
      newRowData.page_id = result?.createdPage?.Pg;
    } else if (+sheetType === pagesId.AllCols) {
      newRowData.col_id = result?.createdCol?.Col;
    }
    newRowData.row = result?.createdRow?.Row;
  }

  private insertNewRow(
    position: string,
    row: RowComponent,
    parentRow: any,
    currentRowIndex: number,
    newRowData: any,
    sheetType: string
  ): void {
    if (position === 'Add Previous Row') {
      this.addRowAtPosition(row, parentRow, currentRowIndex, newRowData, 0);
    } else if (position === 'Add Next Row') {
      this.addRowAtPosition(row, parentRow, currentRowIndex, newRowData, 1);
    } else if (position === 'Add Child Row') {
      let children = row.getData()['_children'] || [];
      children.push(newRowData);
      row.update({ _children: children });
      row.getTable().updateData([{ ...row.getData(), _children: children }]);
    }
  }

  private addRowAtPosition(
    row: RowComponent,
    parentRow: any,
    currentRowIndex: number,
    newRowData: any,
    offset: number
  ): void {
    if (parentRow) {
      const parentData = parentRow.getData();
      const siblings = parentData['_children'] || [];
      siblings.splice(currentRowIndex + offset, 0, newRowData);
      parentRow.update({ _children: siblings });
      row.getTable().updateData([{ ...parentData, _children: siblings }]);
      parentRow.reformat();
    } else {
      const data = row.getTable().getData();
      data.splice(currentRowIndex + offset, 0, newRowData);
      row.getTable()?.setData(data);
    }
  }

  deleteRow(row: RowComponent): void {
    let selectedPageId;
    let rowId = row.getData()?.['row'];
    this.mainService.selectedId.subscribe((res) => {
      selectedPageId = res;
    });
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        title: `Delete: Row (ID: ${rowId})`,
        data: "shall be Deleted? Click 'Delete' to confirm ",
        name: ObjectType.DataObject.Row,
        rowId: rowId,
        row: row,
        selectedPage: selectedPageId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  viewRow(row: RowComponent): void {
    const rowId = row.getData()?.['row'];
    const dialogRef = this.dialog.open(DialogViewComponent, {
      data: {
        clicked: 'Row',
        data: rowId,
      }, // Pass the current column data to the dialog
    });

    dialogRef.afterClosed().subscribe(() => {
      this.showDropdown = false;
      document.body.removeEventListener('click', this.hideDropdown);
    });
  }

  formatLocalRow(row: RowComponent) {
    const rowData = row.getData();
    this.dialog
      .open(FormatPageComponent, {
        data: {
          title: `Format: Local Row (ID: ${rowData['row']})`,
          objectId: rowData['row'],
          context: ContextMenu.FormatLocalRow,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        const currentRow: RowComponent = row;
        const inlineFontStyle: string = result.fontStyleParsed;

        this.formatUIService.foramtLocalRow(currentRow, inlineFontStyle);
      });
  }

  public showDropdownMenu(e: MouseEvent, cell: CellComponent, item : any): void {
    e.preventDefault();

    this.handelClick(e,cell,item);

    const dropdownMenu = document.getElementById('dropdownMenu')!;
    const cellElement = cell.getElement();
    const cellRect = cellElement.getBoundingClientRect();

    // Display the menu
    dropdownMenu.style.display = 'block';

    // Calculate initial position
    let menuX = cellRect.left;
    let menuY = cellRect.bottom + window.scrollY;

    // Get menu dimensions
    const menuWidth = dropdownMenu.offsetWidth;
    const menuHeight = dropdownMenu.offsetHeight;

    // Check right boundary
    if (menuX + menuWidth > window.innerWidth) {
      menuX = window.innerWidth - menuWidth;
    }

    // Check bottom boundary
    if (menuY + menuHeight > window.innerHeight + window.scrollY) {
      menuY = cellRect.top + window.scrollY - menuHeight; // Position above the cell
    }

    // Apply the calculated positions
    dropdownMenu.style.left = `${menuX}px`;
    dropdownMenu.style.top = `${menuY}px`;

    // Stop propagation to prevent document click event from hiding the menu immediately
    const items = dropdownMenu.querySelectorAll('li');
    items.forEach((item) => {
      if (item.id) {
        item.addEventListener('click', (event) =>
          this.handleDropdownItemClick(event, cell, e)
        );
      }
    });

    e.stopPropagation();
  }

  private handleDropdownItemClick = (
    e: Event,
    cell: CellComponent,
    event: MouseEvent
  ) => {
    if (this.dialog) {
      this.dialog.closeAll();
    }
    const target = e.target as HTMLElement;
    const cellValue = event.target as HTMLElement;
    let id: number = 0;
    cell
      .getColumn()
      .getCells()
      .map((cell, index) => {
        if (cell.getData()['page_name'] == cellValue.innerHTML) {
          id = index;
        }
      });
    if (target && target.id) {
      const clickedItemId = target.id;
      if (clickedItemId == 'format-local-cell') {
        const dialogRef = this.dialog.open(FormatLocalSharedColComponent, {
          data: {
            clicked: 'Local Cell',
            data: {
              title: 'Format: Local Cell (ID:' + id + ')',
              id: id,
              cell: 'cell',
            },
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          const columnId = this.currentCell.getColumn().getDefinition().col;
          const rowId = this.currentCell.getData().row;
          const pageId = this.sheet.page_id;
          let res = result?.fontStyleMinWidth.split(':')[0];
          const parsedStyle =
            result.formatLocalColForm.fontStyle != ''
              ? JSON.parse(result.formatLocalColForm.fontStyle)
              : null;

          // Call API to update cell format
          this.formatUIService.formatLocalCell(
            pageId,
            columnId,
            rowId,
            result.formatLocalColForm
          );

          // Apply formating on cell
          if (parsedStyle != null) {
            this.formatUIService.applyFormatLocaCell(cell, parsedStyle);
          }
        });
      } else if (clickedItemId == 'format-local-item') {
        const dialogRef = this.dialog.open(FormatLocalSharedColComponent, {
          data: {
            clicked: 'Local Cell',
            data: { title: 'Format: Local Item (' + 'ID:' + id + ')', id: id },
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          let res = result?.fontStyleMinWidth.split(':')[0];
          const changeElement = cell.getElement();
          const originalText = changeElement.innerHTML;
          if (!/<span\b[^>]*>/i.test(originalText)) {
            // If <span> tag is not present, wrap the text with <span>
            changeElement.innerHTML =
              '<span class="text-updated">' + originalText + '</span>';
          }
          const element = changeElement.querySelector('span');
          if (element) {
            element.style.backgroundColor = res.split(';')[5];
            element.style.color = res.split(';')[0];
          }
          cell.getElement().style.fontSize = res.split(';')[2];
          cell.getElement().style.textAlign = res.split(';')[4];
          if (res.split(';')[3] == '0') {
            cell.getElement().style.fontStyle =
              res.split(';')[3] == '0' ? 'normal' : '';
          } else if (res.split(';')[3] == '2') {
            cell.getElement().style.fontWeight =
              res.split(';')[3] == '2' ? 'bold' : '';
          } else if (res.split(';')[3] == '1') {
            cell.getElement().style.fontStyle =
              res.split(';')[3] == '1' ? 'italic' : '';
          }
          if (res.split(';')[6] == 'italicText') {
            cell.getElement().style.fontStyle = 'italic';
          } else if (res.split(';')[6] == 'boldText') {
            cell.getElement().style.fontWeight = 'bold';
          } else if (res.split(';')[6] == 'strikeThrough') {
            cell.getElement().classList.add('text-linethrough-class');
          } else if (res.split(';')[6] == 'underline') {
            cell.getElement().classList.add('text-underline-class');
          } else if (res.split(';')[6] == 'textWrap') {
            cell.getElement().classList.add('text-wrap-class');
          } else if (res.split(';')[6] == 'superScript') {
            const element = cell.getElement();
            const originalText = element.innerHTML;
            element.innerHTML = originalText.replace(
              originalText,
              '<sup>' + originalText + '</sup>'
            );
          } else if (res.split(';')[6] == 'subScript') {
            const element = cell.getElement();
            const originalText = element.innerHTML;
            element.innerHTML = originalText.replace(
              originalText,
              '<sub>' + originalText + '</sub>'
            );
          } else if (res.split(';')[6] == 'textBorder') {
            cell.getElement().style.border = '1px solid';
          }
          cell.getElement().style.fontFamily = res.split(';')[1];
        });
      }
    }
  };
  private hideDropdownMenu(): void {
    const dropdownMenu = document.getElementById('dropdownMenu')!;
    dropdownMenu.style.display = 'none';
  }

  getLanguageList() {
    this.store.dispatch(new FetchLanguages());
  }

  errorMessage = false;

  modifyItem(e: Event, formMode : FormMode) {
    if (!this.currentCell) return;
    const field = this.currentCell.getField();
    const column = this.tabulatorTable.getColumnLayout();
    let matchingColumn = column.find((col) => col.field === field);
    if (!matchingColumn) return;
    const datatype = (matchingColumn as any).datatype;
    let dialogRef = this.getDialogForModifyItem(datatype,formMode);
    if (!dialogRef) return;
    this.handleDialogClose(dialogRef, (result) => {
      if (result) {
        const mode = result.mode;
        const field = this.currentCell.getField();
        const column = this.tabulatorTable.getColumn(field);
        const colDataType = (column.getDefinition() as any).datatype;
        const payload: AddItemPayload = {
          Pg: Number(this.currentPageId),
          Col: Number(this.currentCell.getColumn().getDefinition().col),
          Row: Number(this.currentCell.getData().row),
          DataType: Number(Object.keys(colDataType)[0]),
          Object: Number(result?.modeRowSelected?.row ?? result?.pageRowSelected?.row ?? 0),
        };

        if(Object.keys(colDataType)[0] === PAGE_ID_DATA_TYPE){
          const row = Number(result?.modeRowSelected?.row ?? result?.pageRowSelected?.row ?? 0);
          payload.Object = Number(result?.modeRowSelected?.page_id ?? result?.pageRowSelected?.page_id ?? 0);
          payload.ColId = Number(this.currentCell.getData()?.col_id);
          payload.CRow = Number(row - 1);
        }

        const action = mode === FormMode.EDIT
          ? new EditItemDataInRow(payload,
              result?.modeRowSelected?.token ??
              result?.pageRowSelected?.page_id):
            new AddItemDataInRow(payload,
              result?.modeRowSelected?.token ??
              result?.pageRowSelected?.page_id);
        this.store.dispatch(action);
      }
    });
  }

  private getDialogForModifyItem(datatype: string, mode : FormMode) {
    let dialogRef;
    switch (Object.values(datatype)[0]) {
      case DataTypes.DROPDOWN:
        dialogRef = this.dialog.open(editDdDialogMainComponent, {
          width: 'auto',
          panelClass: 'pick-ddl',
          data: this.getDropdownDialogData(mode),
        });
        break;
      case DataTypes.ML_TEXT:
        dialogRef = this.dialog.open(DialogEditComponent, {
          data: this.getMlTextDialogData(datatype, mode),
        });
        break;

      case DataTypes.PAGE_ID:
        dialogRef = this.dialog.open(editDdDialogMainComponent, {
          width: 'auto',
          panelClass: 'pick-ddl',
          data: this.getDropdownDialogData(mode, PAGE_TOKEN),
        });
        break;
    };

    return dialogRef;
  }

  private getDropdownDialogData(mode: FormMode, dropdown? : string) : PageModelData {
    return {
      page_id: this.currentCell.getData().page_id,
      token: dropdown ?? this.currentCell.getColumn().getDefinition().dropdown[0],
      mode: mode,
    };
  }

  private getMlTextDialogData(colDataType: string, mode : FormMode) : PageModelData {
    return {
      value: this.currentCell.getValue(),
      language: this.languagesList,
      columnId: this.currentCell.getColumn().getDefinition().col,
      row_id: this.currentCell.getData().row,
      dataType: Object.values(colDataType)[0] ?? '',
      dataTypeId: Object.keys(colDataType)[0] ?? 0,
      page_id: this.currentPageId,
      mode : mode
    };
  }

  private handleDialogClose(dialogRef: any, callback: (result: any) => void) {
    dialogRef.afterClosed().subscribe(callback);
  }


  deleteItem() {
    const rowId = this.currentCell._cell['row']['data']['row'];
    const colId = this.currentCell._cell['column']['definition']['col'];

    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        title: `Delete: Item (ID: )`,
        data: "shall be Deleted? Click 'Delete' to confirm ",
        name: ObjectType.DataObject.Item,
        rowId,
        colId,
      },
    });

    this.showDropdown = false;
    document.body.removeEventListener('click', this.hideDropdown);
  }

  formatLocalItem() {
    const rowId = this.currentCell._cell['row']['data']['row'];
    const colId = this.currentCell._cell['column']['definition']['col'];

    // Handle format local item
    const dialogRef = this.dialog.open(FormatPageComponent, {
      data: {
        title: `Format: Local Item (ID: )`,
        objectId: '',
        colId: colId,
        rowId: rowId,
        context: ContextMenu.FormatLocalItem,
        clicked: 'Local Item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      const currentCell: CellComponent = this.currentCell;
      const inlineFontStyle: string = result.fontStyleParsed;

      this.formatUIService.foramtLocalItem(currentCell, inlineFontStyle);
    });
  }

  formatLocalCell() {
    const rowId = this.currentCell._cell['row']['data']['row'];
    const colId = this.currentCell._cell['column']['definition']['col'];

    // Handle format local cell
    const dialogRef = this.dialog.open(FormatPageComponent, {
      data: {
        title: `Format: Local Cell (ID: )`,
        objectId: '',
        colId: colId,
        rowId: rowId,
        context: ContextMenu.FormatLocalCell,
        clicked: 'Local Cell',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      const currentCell: CellComponent = this.currentCell;
      const inlineFontStyle: string = result.fontStyleParsed;

      this.formatUIService.foramtLocalCell(currentCell, inlineFontStyle);
    });
  }

  formatSharedItem() {
    // Handle format shared item logic here
    const dialogRef = this.dialog.open(DialogFormatComponent, {
      data: {
        clicked: 'Shared Item',
        data:
          'Format Shared Item Here ' + this.currentCell._cell['value'] + ' ?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  formatSharedCell() {
    // Handle format shared cell logic here
    const dialogRef = this.dialog.open(DialogFormatComponent, {
      data: {
        clicked: 'Shared Cell',
        data: {
          data: { title: 'Format: Shared Cell (ID: 1)' },
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  viewCell() {
    const rowId = this.currentCell._cell['column']['definition']['col'];
    const colId = this.currentCell._cell['row']['data']['row'];
    const dialogRef = this.dialog.open(DialogViewComponent, {
      data: {
        clicked: 'Cell',
        data: { rowId, colId },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
      }
    });
  }

  viewItem() {
    const rowId = this.currentCell._cell['column']['definition']['col'];
    const colId = this.currentCell._cell['row']['data']['row'];
    const dialogRef = this.dialog.open(DialogViewComponent, {
      data: {
        clicked: 'Item',
        data: { rowId, colId },
      },
      panelClass: 'view-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
      }
    });
  }

  viewAll() {
    const dialogRef = this.openDialog(DialogViewComponent, {
      clicked: 'All',
      data: `View Cell Values Here ${this.currentCell._cell['value']} ?`,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Handle result logic here if needed
      }
    });
  }

  formatLocalCol() {
    const columnId = this.getColumnIdByField(this.column);

    const dialogRef = this.dialog.open(FormatPageComponent, {
      data: {
        title: `Format: Local Column (ID: ${columnId})`,
        objectId: columnId,
        context: ContextMenu.FormatLocalColumn,
      },
      panelClass: 'format-local-column',
    });

    this.showDropdown = false;

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.applyLocalColFormat(result);
      }
    });
  }

  formatSharedCol() {
    const dialogRef = this.openDialog(FormatLocalSharedColComponent, {
      ...this.columnData,
      shared: true,
      data: { title: 'Format Shared-Column' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.applySharedColFormat(result);
      }
    });
  }

  // Helper Methods

  private openDialog(component: any, data: any) {
    return this.dialog.open(component, {
      data: { ...data },
      panelClass: component.name.toLowerCase(),
    });
  }

  private getColumnIdByField(field: string) {
    let columnId: any;
    this.tabulatorTable.getColumns().map((col: any) => {
      if (col.getField() === field) {
        columnId = col.getDefinition()['col'];
      }
    });
    return columnId;
  }

  private applyLocalColFormat(result: any) {


    this.colMinWith = result.formatLocalColForm.minWidth;
    this.getFormatLocalCol();

    this.tabulatorTable.on('dataTreeRowCollapsed', () => {
      this.getFormatLocalCol();
    });
    this.tabulatorTable.on('dataTreeRowExpanded', () => {
      this.getFormatLocalCol();
    });

    this.updateColumnDefinition(result);
  }

  private applySharedColFormat(result: any) {
    this.updateColumnDefinition(result);
    this.tabulatorTable.redraw(true);
  }

  private updateColumnDefinition(result: any) {
    const columnDef = {
      ...this.currentColumn?.getDefinition(),
      ...result, // Apply formatted properties
    };
    this.currentColumn?.updateDefinition(columnDef);
    const columnIndex = this.columnData.findIndex(
      (col) => col.field === this.currentColumn?.getField()
    );
    if (columnIndex > -1) {
      this.columnData[columnIndex] = columnDef;
    }
    this.showDropdown = false;
    document.body.removeEventListener('click', this.hideDropdown);
  }

  viewColumn() {
    const dialogRef = this.dialog.open(DialogViewComponent, {
      data: {
        clicked: 'Column',
        data: this.viewcolumnData,
      }, // Pass the current column data to the dialog
    });
    this.showDropdown = false;
    dialogRef.afterClosed().subscribe(() => {
      document.body.removeEventListener('click', this.hideDropdown);
    });
  }

  headerClickFunc = (e: any, column: any) => {
    var tabulator = column.getTable();
    this.sortStatus ? '' : tabulator.clearSort();
  };

  updateTableWithFilters(enable: any) {
    if (enable) {
      this.tabulatorTable?.replaceData(this.tabFlatData);
    } else if (!enable && this.allData.length > 0) {
      this.tabulatorTable?.clearHeaderFilter();
      this.tabulatorTable?.replaceData(this.allData);
    }

    var headers = document.querySelectorAll('.tabulator .tabulator-header');
    let columns = this.tabulatorTable
      ?.getColumnDefinitions()
      .map((colDef: any) => {
        colDef.headerFilter = enable ? this.customFilterEditor : false;
        return colDef;
      });

    this.tabulatorTable?.setColumns(columns);
  }

  // Debounce function
  debounce(func: any, wait: any) {
    let timeout: any;
    return (...args: any) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  splitString(input: string) {
    // Regular expression to match the delimiters [Like] and [And]
    var regex = /\[Like\]|\[And\]/g;

    // Split the string based on the regex
    var parts = input.split(regex);

    // Remove any empty strings resulting from the split
    parts = parts.filter((part) => part.trim() !== '');

    // Extract the delimiters for clarity (optional)
    var delimiters = input.match(regex);

    return { parts, delimiters };
  }

  customFilterEditor = (
    cell: any,
    onRendered: any,
    success: any,
    cancel: any,
    editorParams: any
  ) => {
    var container = document.createElement('span');
    const currentDefinition = cell.getColumn().getDefinition();

    //create and style inputs
    var input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.style.padding = '2px';
    input.style.width = '100%';
    input.value = cell.getValue();

    // Create and style the error message container
    const errorMessage = document.createElement('div');
    errorMessage.style.color = 'red';
    errorMessage.style.display = 'none';
    errorMessage.style.marginTop = '2px';
    errorMessage.style.fontWeight = '200';
    errorMessage.textContent = 'Invalid syntax';

    function buildValues() {
      success({
        inputColumnStatus: cell.getColumn().getDefinition().status,
        inputColumn: cell.getColumn().getField(),
        inputValue: input.value,
      });
    }

    function keypress(e: any) {
      if (e.keyCode == 13) {
        buildValues();
      }

      if (e.keyCode == 27) {
        cancel();
      }
    }

    const validateInputValue = () => {
      const validator = this.filterService.validateInput(input.value);
      const tableHeader = document.querySelector(
        '.tabulator-header[role="rowgroup"]'
      ) as HTMLDivElement;
      const tabulator = document.querySelector(
        '.tabulator[role="grid"]'
      ) as HTMLDivElement;
      const tabulatorTable = document.querySelector(
        '.tabulator[tabulator-layout=fitDataFill] .tabulator-tableholder .tabulator-table'
      ) as HTMLDivElement;

      if (!validator) {
        // Column current width
        const columnCurrentWidth = cell.getColumn().getWidth();
        // Update custom validatioin flag
        currentDefinition.validationFlag = false;
        // Update column width
        columnCurrentWidth < SystemInitials.MinWidth
          ? cell.getColumn().setWidth(SystemInitials.MinWidth)
          : '';
        // Update input color and error message display
        input.style.color = 'red';
        errorMessage.style.display = 'block';
      } else {
        // Update custom validatioin flag
        currentDefinition.validationFlag = true;
        // Update input color and error message display
        input.style.color = 'black';
        errorMessage.style.display = 'none';
      }
    };

    const debounceBuildValues = this.debounceInput(buildValues, 300);
    const debounceValidateInput = this.debounceInput(validateInputValue, 300);
    function inputEvent(e: any) {
      debounceBuildValues();
      debounceValidateInput();
    }

    input.addEventListener('change', buildValues);
    input.addEventListener('blur', buildValues);
    input.addEventListener('keydown', keypress);
    input.addEventListener('input', inputEvent);

    container.appendChild(input);
    container.appendChild(errorMessage);

    return container;
  };

  debounceInput(func: any, wait: any) {
    let timeout: any;
    return (...args: any) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  onAddSheet(data: any) {
    this.sheetService.requestAddSheet(data);
  }

  getFormatLocalCol() {
    this.res$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (!data) return;

      const res = data?.data['Local Col']?.FontStyle;
      const appliedStyle = JSON.parse(res)?.FontStyle;

      this.tabulatorTable.redraw(true);

      this.tabulatorTable.getColumns().forEach((col) => {
        if (col.getField() === this.column) {
          col.getCells().forEach((cell) => {
            const element = cell.getElement();
            if (element.classList.contains('tabulator-cell')) {
              this.applyStylesToCell(element, appliedStyle);
            }
          });
        }
      });
    });
  }

  private applyStylesToCell(element: HTMLElement, style: any) {
    // General styles
    element.style.minWidth = `${this.colMinWith}px`;
    element.style.backgroundColor = style['Background Color'] || '';
    element.style.color = style['Font Color'] || '';
    element.style.fontSize = style['Size'] || '';
    element.style.textAlign = style['Text Alignment'] || '';

    // Font style
    switch (style['Font Style']) {
      case 'Regular':
        element.style.fontStyle = 'normal';
        break;
      case 'Bold':
        element.style.fontWeight = 'bold';
        break;
      case 'Italic':
        element.style.fontStyle = 'italic';
        break;
    }

    // Effects
    this.applyEffects(element, style['Effects']);

    // Font family
    element.style.fontFamily =
      style['Font'] ||
      style['Asian text font'] ||
      `${style['Asian text font']}, ${style['Font']}`;
  }

  private applyEffects(element: HTMLElement, effect: string) {
    switch (effect) {
      case 'italicText':
        element.style.fontStyle = 'italic';
        break;
      case 'boldText':
        element.style.fontWeight = 'bold';
        break;
      case 'strikeThrough':
        element.classList.add('text-linethrough-class');
        break;
      case 'underline':
        element.classList.add('text-underline-class');
        break;
      case 'textWrap':
        element.classList.add('text-wrap-class');
        break;
      case 'superScript':
        element.innerHTML = `<sup>${element.innerHTML}</sup>`;
        break;
      case 'subScript':
        element.innerHTML = `<sub>${element.innerHTML}</sub>`;
        break;
      case 'textBorder':
        element.style.border = '1px solid';
        break;
    }
  }

  handelClick(e: MouseEvent, cell: CellComponent, item : any){
    if (this.currentPageId === Page.ALL_COLS.toString() && item === PageItem.PAGE_ID) {
      const pageTypeExists = cell.getData()[PageItem.PAGE_TYPE]?.length > 0;
      const pageIdExists = cell.getData()[PageItem.PAGE_ID]?.length > 0;

      this.showAddEdit.add = !pageTypeExists && !pageIdExists; // Add only if both are missing
      this.showAddEdit.edit = pageIdExists; // Edit if page_id exists
    } else if (this.currentPageId === Page.ALL_COLS.toString() && item === PageItem.PAGE_TYPE) {
      const pageTypeExists = cell.getData()[PageItem.PAGE_TYPE]?.length > 0;
      const pageIdExists = cell.getData()[PageItem.PAGE_ID]?.length > 0;

      this.showAddEdit.add = !pageIdExists && !pageTypeExists; // Add only if both are missing
      this.showAddEdit.edit = pageTypeExists; // Edit if page_type exists
    }else {
      this.showAddEdit = {
        add : true,
        edit : true
      }
    }
  }
}
