import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  // GET request
  get<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  // POST request
  post<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(url, data);
  }

  // PUT request
  put<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(url, data);
  }

  // PATCH request
  patch<T>(url: string, data: any, headers?: object): Observable<T> {
    return this.http.patch<T>(url, data, headers);
  }

  // DELETE request
  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url);
  }
}
