import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PgTabStateModel, PgTab } from './pg-tab.model';
import { tPgService } from '../../core/services/tPg.service';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {
  AddPgTab,
  IsLoadingMenuBar,
  LoadMenuBar,
  LoadPgTabs,
  SelectPgTab,
  SetFontStyle,
  FetchLanguages,
  DeletePgCol,
  UpdatePgTabs,
} from './pg-tab.actions';
import {
  All_Pages_ID,
} from '../../core/constants/app.contants';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { Sheet } from '../page/page.actions';
import { PageTabState } from './pg-tab.state';
import { MainService } from '../../core/services/main-service/main.service';
import { SheetState } from '../page/page.store';
import { RowPageData } from '../../shared/models/edit-dd-dailogs/dd-dailog.models';

@State<PgTabStateModel>({
  name: 'pgTabs',
  defaults: PageTabState,
})
@Injectable()
export class PgTabStore {
  constructor(
    private _tPgService: tPgService,
    private store: Store,
    private sessionStorageService: SessionStorageService,
    private mainService: MainService
  ) {}

  @Selector()
  static getPgTabs(state: PgTabStateModel): PgTab[] {
    return state.pgTabs;
  }

  @Selector()
  static getDefaultPgTab(state: PgTabStateModel): PgTab {
    return state.defaultPgTab;
  }

  @Selector()
  static getSelectedPgTab(state: PgTabStateModel): PgTab {
    return state.selectedPgTab;
  }

  @Selector()
  static getCurrentPgId(state: PgTabStateModel): number {
    return state.defaultPageId;
  }

  @Selector()
  static isLoading(state: PgTabStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static getError(state: PgTabStateModel): string | null {
    return state.error;
  }

  @Selector()
  static getSheetData(state: PgTabStateModel): any {
    return state.sheetData;
  }

  @Selector()
  static getSheetColumns(state: PgTabStateModel): any {
    return state.sheetColumns;
  }

  @Selector()
  static getMenuBar(state: PgTabStateModel): any {
    return state.menuBars;
  }

  @Selector()
  static getFontStyle(state: PgTabStateModel) {
    return state.fontStyle;
  }

  @Selector()
  static getLanguages(state: PgTabStateModel) {
    return state.languages;
  }

  @Action(LoadPgTabs)
  async loadPgTabs(ctx: StateContext<PgTabStateModel>, action: LoadPgTabs) {
    const { page_id } = action;
    const dataExist = ctx.getState()?.allTabsData.length > 0;
    const refresh = action?.refresh ?? false;
    ctx.patchState({ loading: true, error: null });
    if (dataExist && !refresh) {
      const flatData = this.mainService.mapAllPagesFlatData(
        ctx.getState().allTabsData
      );
      const mappedValue = this.mainService.mapAllPagesData(
        ctx.getState().allTabsData
      );
      this.store.dispatch(
        new Sheet.UpdatePageData({
          allTabsData: mappedValue.allTabsData,
          allTabsFlatData: flatData.allTabsData,
          defaultPageId: mappedValue?.defaultPageId,
        })
      );

      ctx.patchState({
        loading: false,
      });
      return;
    }
    return this._tPgService.getAllPages().pipe(
      tap((data: any) => {
        const flatData = this.mainService.mapAllPagesFlatData(data);
        const mappedValue = this.mainService.mapAllPagesData(data);
        this.store.dispatch(
          new Sheet.UpdatePageData({
            allTabsData: mappedValue.allTabsData,
            allTabsFlatData: flatData.allTabsData,
            defaultPageId: mappedValue?.defaultPageId,
          })
        );
        if (page_id === All_Pages_ID) {
          const tabs = flatData.allTabsData.filter(
            (obj) => All_Pages_ID && obj[All_Pages_ID] !== undefined
          )[0];
          const pgTabs = tabs[Number(All_Pages_ID)].pageData.filter(
            (res: RowPageData) => {
              const id = Number(res.page_id);
              const tab = flatData.allTabsData.filter(
                (obj) => id && obj[id] !== undefined
              )[0];
              return tab[id]?.pageData && tab[id].pageData.length > 0;
            }
          );
          const defaultPgTab = pgTabs[0];
          ctx.patchState({
            pgTabs,
            loading: false,
            defaultPageId: defaultPgTab?.page_id || 0,
            selectedPgTab: defaultPgTab,
          });
        }
      }),
      catchError((error) => {
        ctx.patchState({ error: 'Failed to load pgTabs', loading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(LoadMenuBar)
  loadMenuBar(ctx: StateContext<PgTabStateModel>, action: LoadMenuBar) {
    const { DDL } = action;
    return this._tPgService.getDDL(DDL).pipe(
      tap((data: any) => {
        ctx.patchState({
          menuBars: data?.data?.DDLContent?.map((item: any) => ({
            rowId: item.row,
            item: item.token,
          })),
        });
      })
    );
  }

  @Action(IsLoadingMenuBar)
  isLoading(ctx: StateContext<PgTabStateModel>, { loading }: IsLoadingMenuBar) {
    ctx.patchState({
      loading,
    });
  }

  @Action(SelectPgTab)
  selectPgTab(ctx: StateContext<PgTabStateModel>, { pgTab }: SelectPgTab) {
    const allTabsFlatData = this.store.selectSnapshot(
      SheetState.getSheetFlatData
    );
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);

    ctx.patchState({
      selectedPgTab: pgTab,
      defaultPageId: Number(pgTab.page_id),
    });
    ctx.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: allTabsData,
        allTabsFlatData: allTabsFlatData,
        defaultPageId: pgTab.page_id,
      })
    );
  }

  @Action(AddPgTab)
  addPgTab(ctx: StateContext<PgTabStateModel>, { pgTab }: SelectPgTab) {
    const state = ctx.getState();
    ctx.patchState({
      pgTabs: [...state.pgTabs, pgTab],
      defaultPgTab: pgTab,
      selectedPgTab: pgTab,
    });
    ctx.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: this.sessionStorageService.getAllPageData(),
        defaultPageId: pgTab.page_id,
      })
    );
  }

  @Action(UpdatePgTabs)
  updatePgTabs(
    ctx: StateContext<PgTabStateModel>,
    { pgTabs, defaultPgTabId }: UpdatePgTabs
  ) {
    const state = ctx.getState();
    ctx.patchState({
      pgTabs: pgTabs,
      defaultPageId: defaultPgTabId,
    });
  }

  @Action(DeletePgCol)
  deletePgCol(ctx: StateContext<PgTabStateModel>, { colId }: DeletePgCol) {
    // select the snapshot state from PgTabStore
    const pgTab = this.store.selectSnapshot(
      PgTabStore.getSelectedPgTab
    ) as PgTab;
    const pgId = Number(pgTab.page_id);
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);

    // Delete page column from store
    this.mainService.deletePageColumnById(pgId, colId, allTabsData);
  }

  @Action(SetFontStyle)
  setFontStyle(ctx: StateContext<PgTabStateModel>, action: SetFontStyle) {
    // Call the service method which performs a side effect (e.g., updating font styles) but does not return data
    this.mainService.setFontStyles(action.payload);

    // Retrieve the current state
    const state = ctx.getState();

    // Prepare the new state model
    const stateModel = {
      fontStyle: action.payload, // Directly use action.payload
    };

    // Update the state with the new font style
    ctx.setState({
      ...state,
      ...stateModel,
    });
  }

  @Action(FetchLanguages)
  FetchLanguages(ctx: StateContext<PgTabStateModel>) {
    return this._tPgService.getLanguages().pipe(
      tap((data: any) => {
        const state = ctx.getState();
        const stateModel = {
          languages: data,
        };
        // Update the existing state
        ctx.setState({
          ...state,
          ...stateModel,
        });
      })
    );
  }
}
