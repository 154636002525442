import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { FormatResponse } from '../interfaces/response.interface';
import { AddItemPayload } from '../../shared/models/item/item.model';

@Injectable({
  providedIn: 'root',
})
export class tItemService {
  private apiUrl = 'tItem';

  constructor(private apiService: ApiService) {}

  // Fetch all items
  getItems(): Observable<any[]> {
    return this.apiService.get<any[]>(this.apiUrl);
  }

  addItem(payLoad : AddItemPayload){
    return this.apiService.post<FormatResponse<any>>(this.apiUrl+'/itemcell', payLoad);
  }

  editItem(payLoad : AddItemPayload){
    return this.apiService.post<FormatResponse<any>>(this.apiUrl+'/cellitem', payLoad);
  }
}
