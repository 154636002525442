import { RowPageData } from "../shared/models/edit-dd-dailogs/dd-dailog.models";

export function findMaxRowLevel(obj : RowPageData) {
  let maxRowLevel = obj.RowLevel || 0;

  if (obj._children && Array.isArray(obj._children)) {
    for (const child of obj._children) {
      maxRowLevel = Math.max(maxRowLevel, findMaxRowLevel(child));
    }
  }

  return maxRowLevel;
}
