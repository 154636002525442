import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ADD_ROW_MODEL } from '../../shared/models/row/row.models';
import { FormatResponse } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class RowService {
  private apiUrl = 'tRow';
  constructor(private apiService: ApiService, private http: HttpClient) {}
  addPgRow(row: ADD_ROW_MODEL): Observable<FormatResponse<any>> {
    return this.apiService.post<FormatResponse<any>>(`${this.apiUrl}/pgRow`,row);
  }
  addSystemPgRow(row: ADD_ROW_MODEL): Observable<FormatResponse<any>> {
    return this.apiService.post<FormatResponse<any>>(`${this.apiUrl}`,row);
  }
}
