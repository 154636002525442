import { Pipe, PipeTransform } from '@angular/core';
import { PgTab } from '../../store/pg-tab/pg-tab.model';

@Pipe({
  name: 'pageName',
})
export class PageNamePipe implements PipeTransform {

  transform(sheet: PgTab): string {
    if (!sheet?.page_name) return '';

    const withoutTags = sheet.page_name.replace(/<\/?[^>]+(>|$)/g, '');

    const withoutEntities = withoutTags.replace(/&nbsp;/g, ' ').replace(/&[a-z]+;/g, '');

    return withoutEntities
      .trim()
      .split(' ')
      .map(word => word.charAt(0) + word.slice(1))
      .join(' ');
  }
}
