import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  HostListener,
  inject,
  DestroyRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ColumnComponent, TabulatorFull as Tabulator } from 'tabulator-tables';
import { Select, Store } from '@ngxs/store';
import { SheetState } from '../../../../store/page/page.store';
import { Observable, takeUntil, Subject, Subscription, map} from 'rxjs';
import { Sheet } from '../../../../store/page/page.actions';
import { COL_STATUSES, FILTER_TYPES, SystemInitials } from '../../../../constant';
import { AutoAdjustWidthDirective } from '../../../directives/auto-adjust-width.directive';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MainService } from '../../../../core/services/main-service/main.service';
import { ASCII_DASH, ASCII_PLUS, ASCII_SPACE, DDL_All_Pages, DDL_Page_Edition, DDL_Regions, UNICODE_DOWN_ARROW, UNICODE_LEFT_RIGHT_ARROW, UNICODE_UP_ARROW } from '../../../../core/constants/app.contants';
import { TabulatorEvents } from '../../../../core/enums/tabulator-events/events';
import { DDResponse, RowPageData } from '../../../models/edit-dd-dailogs/dd-dailog.models';
import { DDLMenuBarType } from '../../../../core/enums/edit-dd-dailog/edit-dd';
import { PageModelData } from '../../../../core/interfaces/page.iterface';
import { FormMode } from '../../../../core/enums/forms/form';
import { FilterService } from '../../../../core/services/filter-service/filter.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// Extends the ColumnDefinition interface
declare module "tabulator-tables" {
  interface ColumnDefinition {
    validationFlag?: boolean; // custom property
  }
}

@Component({
  selector: 'edit-dd-dialog-content',
  templateUrl: './edit-dd-dialog-content.component.html',
  styleUrls: ['./edit-dd-dialog-content.component.scss'],
})
export class editDdContentComponent implements OnInit {
  @Output() isGetData: EventEmitter<boolean> = new EventEmitter<boolean>();
  destroyRef = inject(DestroyRef);
  private tabulatorTable!: Tabulator;
  @ViewChild('tabulatorDiv2', { static: true }) tabulatorDiv2!:
    | ElementRef;
  private unsubscribe$ = new Subject<void>();
  allData: any = ([] = []);
  clickedRowData: any;
  parentRows: any[] = [];
  allDataWithoutNested: any = ([] = []);
  allColumns: any = ([] = []);
  pageFreezeColumn: any = 0;
  frozen: any[] = [];
  public sortStatus = false;
  private isFilter: boolean = false;
  private sub: Subscription | null = null;
  @Select(SheetState.PickDdiData) data$: Observable<any> | undefined;
  @Select(SheetState.PickDdiColumns) columns$: Observable<any> | undefined;
  expandLevels: boolean[] = [];
  selectedColumns: any[] = [];
  columnOrder: any[] = [];
  triggered: boolean = false;
  private originalData: any[] = [];
  expand = UNICODE_LEFT_RIGHT_ARROW;
  @ViewChild(AutoAdjustWidthDirective)
  isDragging: boolean = false;
  isResizing: boolean = false;
  initialMouseX: number = 0;
  initialMouseY: number = 0;
  initialWidth: number = 0;
  initialHeight: number = 0;
  offsetX = 0;
  offsetY = 0;
  scrollbarWidth = this.getScrollbarWidth();
  levels: any;
  isVisible: any;
  inputValue: string = '';
  newWidth: number = 0;
  newHeight: number = 0;
  level: any;
  columnsWithHeaderContext: any = [];
  
  constructor(
    private store: Store,
    private mainService: MainService,
    public dialog: MatDialog,
    public filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public data: PageModelData,
    public dialogRef: MatDialogRef<editDdContentComponent>
  ) {}

  ngOnInit() {
    this.mainService.sortStatusDD.subscribe((value)=>{
      if(value==='Sort is On'){
        this.enableSort();
      }
    })
    this.getData();
    this.renderTabulator();
    this.freezeLevel();
    this.expandLevel();
    this.store.dispatch(new Sheet.SetFrozen(this.data?.data?.frozen));
    this.store.dispatch(new Sheet.SetWidthExpand(this.data?.data?.expandWidth));
    this.toggleFilter();
    this.toggleSort();
  }

  ngAfterViewInit() {
    this.freezeLevel();
    this.expandLevel();
  }
  enableSort():void {
    const sorterElements = this.tabulatorDiv2.nativeElement.querySelectorAll('.sorter');
    if (sorterElements.length > 0) {
      sorterElements.forEach((element: HTMLElement) => {
        element.classList.add('hover-enabled');
      });
    }
  }
  

  // Function to calculate the side scrollbar width
  getScrollbarWidth(): number {
    return window.innerWidth - document.documentElement.clientWidth;
  }
  
  getData() {
    if(this.data.token === DDL_Page_Edition){
      this.store.dispatch(new Sheet.PickDdiData(this.data?.token, true));
    }else{
      this.store.dispatch(new Sheet.PickDdiData(this.data?.token));
    }
    this.data$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.originalData = data;
        this.allDataWithoutNested = data;
        this.allData = this.buildNestedDataIterative(data);
      }
    });
  }
  
  hasChildrenInAnyRow = false;
  buildNestedDataIterative(inputData: any) {
    this.hasChildrenInAnyRow = false;
    const map: { [key: string]: any } = {};
    const structuredData: any[] = [];

    inputData.forEach((item: any) => {
      const newItem = { ...item };

      // If no parent (ParentRow is null or its Row is null), it's a root-level item
      if (!item?.ParentRow || item?.ParentRow?.Row == null || !map[item?.ParentRow?.Row]) {
        structuredData.push(newItem); // Root item
      } else {
        if (item?.ParentRow?.Row != null) {
          this.hasChildrenInAnyRow = true;
        }

        // Check if the parent row exists in the map
        const parentRow = map[item?.ParentRow?.Row];
        if (parentRow) {
          // If parent exists, ensure _children is initialized
          if (!parentRow._children) {
            parentRow._children = [];
          }
          parentRow._children.push(newItem); // Add the current item as a child of the parent
        }
      }
      // Store the current item in the map by its row
      map[item?.row] = newItem;
    });
    // Log the final structured data
    return structuredData;
  }

  dataSelection() {
    let selectedColumns;
    selectedColumns = this.allData;
    return selectedColumns;
  }

  underlineFormatter = (cell: { getValue: () => any }) => {
    const value = cell.getValue();
    if (value) {
      return `<span style="text-decoration: underline;">${value}</span>`;
    } else {
      return value;
    }
  };
  onClose(): void {
    this.dialogRef.close();
  }
  boldSectionHead = (cell: { getValue: () => any; getData: () => any }) => {
    const value = cell.getValue();
    const data = cell.getData();
    if (data.RowLevel == 0) {
      return `<span style="font-weight:bold;">${value}</span>`;
    }
    return value;
  };

  // Check if the column contains any semicolon
  checkColumnForSemicolons(column: any): boolean {
    return this.checkColStatus(column.status);
  }

  generateColumnCode(columnSelection: any = []) {
    if (columnSelection.length != 0) {
      // First, sort columns so that visible ones come at the start
      const sortedColumns = columnSelection.sort((a: any, b: any) => {
        // If the column is visible, it should come before the ones that are not visible
        if (a.visible && !b.visible) return -1; // a comes before b
        if (!a.visible && b.visible) return 1;  // b comes before a
        return 0; // If both are either visible or not, keep their relative order
      });
      const columns = sortedColumns.map((column: any, index: number) => {
        const hasSemicolon = this.checkColumnForSemicolons(column); // Check if the column has semicolons
        const formatter = (
          cell: any,
          formatterParams: any,
          onRendered: any
        ) => {
          const value = cell.getValue()
          // If the column has a semicolon, format all items in that column with chips
          if (hasSemicolon) {
            return this.chipFormater()(cell, formatterParams, onRendered);
          } else {
            return value; // Return the original value if no semicolon
          }
        };
        // Apply freezing logic based on column index and visibility
        if (index < +this.pageFreezeColumn && this.pageFreezeColumn != 0) {
          if (column.visible === true) {
            column.frozen = true;
          }
        } else {
          column.frozen = false;
        }
        // Apply formatter logic for specific conditions
        if (column.status.includes('Nested')) {
          return { ...column, formatter: this.boldSectionHead };
        }
        // Use underline formatter for "Page URL" column
        if (column.field === 'page_url' && column.field !== 'undefined') {
          return { ...column, formatter: this.underlineFormatter };
        } else {
          return { ...column, formatter }; // Apply the dynamic formatter to other columns
        }
      });
      return columns;
    }
  }

  renderTabulator(): void {
    if (this.tabulatorTable) {
      this.tabulatorTable.destroy(); // Destroy previous table instance
    }

    this.columns$?.subscribe((columns) => {
      if (columns != undefined) {
        this.selectedColumns =
          this.columnOrder.length != 0 ? this.columnOrder : columns;
        this.allColumns = columns.map((col: any) => ({ ...col }));
        this.columnsWithHeaderContext = this.selectedColumns?.map(
          (column: any) => {
            this.isVisible = column?.status?.includes(COL_STATUSES.DDL_COL);
            return {
              ...column,
              validationFlag: true,
              headerFilterLiveFilter: false,
              visible: this.isVisible,
              headerSortTristate: true,
              headerFilter: this.isFilter ? this.customFilterEditor : false,
              headerFilterFunc: this.filterService.customFilterFunction,
              headerClick: (e: any, column: any) => {
                this.headerClickFunc(e, column); // Attach header click function
              },
            };
          }
        );
        const nestedColumn = this.columnsWithHeaderContext.find(
          (column: { status?: string | string[] }) =>
            column.status?.includes('Nested')
        )?.field;

        // Initialize the Tabulator table
        this.tabulatorTable = new Tabulator(this.tabulatorDiv2?.nativeElement, {
          data: this.dataSelection(),
          columns: this.generateColumnCode(this.columnsWithHeaderContext),
          dataTree: true,
          height: window.innerHeight + 'px',
          dataTreeFilter: true,
          dataTreeStartExpanded: true,
          dataTreeElementColumn: nestedColumn,
          addRowPos: 'bottom',
          validationMode: 'highlight',
          dataTreeCollapseElement: '<div></div>',
          dataTreeExpandElement: '<div></div>',
          headerSortElement: function (column, dir) {
            switch (dir) {
              case 'asc':
                return (
                  '<div class="sorter">' + `${UNICODE_UP_ARROW}` + '</div>'
                );
              case 'desc':
                return (
                  '<div class="sorter">' + `${UNICODE_DOWN_ARROW}` + '</div>'
                );
              default:
                return (
                  '<div class="sorter">' + `${UNICODE_UP_ARROW}` + '</div>'
                );
            }
          },
          columnDefaults: {
            resizable: true, // Ensure columns are resizable,
            headerSortTristate: true,
          },
          progressiveLoad: 'scroll',
          layout: 'fitData',
          layoutColumnsOnNewData: true,
          movableColumns: true,
          spreadsheet: true,
          spreadsheetRows: 250,
          movableRows: false,
          columnHeaderSortMulti: true, // Use sortStatus
          headerSortClickElement: 'icon', // Enable or disable sorting on icon
          rowFormatter: (row) => {
            this.formatRow(row, nestedColumn);

            let maxLength = 0; // To track the maximum length of text in the last visible column
            let longestValue = ''; // To store the actual longest value

            // Iterate over each cell in the row
            row.getCells().forEach((cell, index, cells) => {
              const value = cell.getValue();

              // Handle tooltip for cells with chips or values
              if (value && typeof value === 'string' && value.includes(';')) {
                cell.getElement().removeAttribute('title');
              } else {
                cell.getElement().setAttribute('title', value);
              }

              // Check if the current cell belongs to the last visible column
              const visibleColumns = this.columnsWithHeaderContext.filter(
                (column: any) => column.visible === true
              );
              const lastVisibleColumn = visibleColumns.pop(); // Get the last visible column

              if (lastVisibleColumn) {
                // Check if this cell belongs to the last visible column
                if (
                  cell.getColumn().getDefinition().field ===
                  lastVisibleColumn.field
                ) {
                  // Compare the length of the current cell's value with the longest value
                  if (value && value.length > maxLength) {
                    maxLength = value.length;
                    longestValue = value;
                  }

                  // If we are at the row with the longest value, stop further processing
                  // Adjust the column width only once based on the max length found
                  const minWidth = maxLength * 8; // Adjust the multiplier (8) for padding/font size
                  const cellElement = cell.getColumn().getElement();
                  const currentWidth = parseInt(
                    cellElement.style.width || '0',
                    10
                  );
                  // Only apply the minWidth if it's greater than the current width
                  if (minWidth > currentWidth) {
                    cellElement.style.minWidth = `${minWidth}px`;
                    cell.getColumn().setWidth(minWidth);
                    cellElement.style.maxWidth = '100%'; // Optional maxWidth to constrain column size
                  }
                  // After we've found the longest value, we stop further row iterations
                  return; // Stop further iteration over cells in this row
                }
              }
            });
          },
        });

        this.tabulatorTable.on(TabulatorEvents.RowClick, (e, row) => {
          const element = e.target as HTMLElement;
          if (element.id === 'RowHeaderID') {
            // Toggle expansion state
            const isExpanded = row.isTreeExpanded();
            isExpanded ? row.treeCollapse() : row.treeExpand();
          } else {
            this.handleRowClick(row); // Handle row clicks
          }
        });

        this.tabulatorTable.on(TabulatorEvents.ColumnMoved, (column: any, columns: any) => {
          const allColumns = this.tabulatorTable.getColumns();
          allColumns.map((column, index) => {
            this.columnOrder[index] = column.getDefinition();
          });
          this.triggered = true;
        });

        // Attch the event for column resizing
        this.tabulatorTable.on('columnResized', (column:ColumnComponent) => {
          const columnDef = column.getDefinition(); // Get column definition
          const validationFlag = columnDef.validationFlag; // Access the custom flag

          // Check minWidth logic
          const currentWidth = column.getWidth();
          if (!validationFlag && currentWidth < SystemInitials.MinWidth) {
            column.setWidth(100);
          }
        });
      }

      // Apply filters after rendering
      setTimeout(() => {
        if (this.isFilter) {
          this.isGetData.emit(true);
          this.updateTableWithFilters(true);
        } else {
          this.isGetData.emit(true);
          this.updateTableWithFilters(false);
        }
      }, 300);
    });
  }

  headerClickFunc = (e: any, column: any) => {
    var tabulator = column.getTable();
    this.sortStatus ? '' : tabulator.clearSort();
  };
  toggleSort(){
    this.mainService.sortStatusDD?.subscribe((sortStatus) => {
      if (sortStatus == 'Sort is Off') {
        this.sortStatus = false;
        this.tabulatorTable?.clearSort();
      } else if (sortStatus == 'Sort is On') {
        this.sortStatus = true;
      }
    });
  }

  chipFormater() {
    return (cell: any, formatterParams: any, onRendered: any) => {
      const value = cell.getValue();

      // Create a container for chips
      const chipContainer = document.createElement('div');
      chipContainer.classList.add('chip-container');

      // Split by semicolon or treat as single item
      const items =
        value && typeof value === 'string'
          ? value.includes(';')
            ? value.split(';').map((item) => item.trim())
            : [value.trim()]
          : [];

      // Create chips for each item
      items.forEach((item) => {
        // Create the outer div for each item
        const outerDiv = document.createElement('div');
        outerDiv.className = 'menu-item-container'; // Set the class name

        // Create the Button div
        const chipDiv = document.createElement('div');
        chipDiv.className = 'Chip';
        chipDiv.title = item; // Set the chip tooltip
        chipDiv.textContent = item;

        // Append the Button div to the outer div
        outerDiv.appendChild(chipDiv);

        // Append the outer div to the chip container
        chipContainer.appendChild(outerDiv);
      });

      // Remove the cell tooltip by removing the title attribute
      cell.getElement().removeAttribute('title');

      return chipContainer.outerHTML;
    };
  }

  expandLevel(): boolean[] {
      if (!this.sub) {
      this.mainService.pageFormateReg
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((level)=> DDL_All_Pages === this.data.token || DDL_Regions === this.data.token ? level - 1  :  level)
      ).subscribe((res) => {
        if (res != null) {
          this.tabulatorTable.blockRedraw();
          this.expandLevels = Array(res).fill(true);
          const rows = this.tabulatorTable.getRows();
            if (rows) {
              if (res === 0) {
                rows.forEach((row) => row.treeCollapse());
              } else {
                rows.forEach((row) => this.expandToDepth(row, res));
              }
            }
          this.tabulatorTable.restoreRedraw();
        }
      });
    }
    return this.expandLevels;
  }

  
  expandToDepth(row: any, depth: number) {
    if (depth > 0) {
      row.treeExpand();
      const children = row.getTreeChildren();
      children?.forEach((child: RowPageData) =>
        this.expandToDepth(child, depth - 1)
      );
    } else {
      row.treeCollapse();
    }
  }

  freezeLevel() {
    this.mainService.pageFormateFreeze.subscribe((res) => {
      if (res.pageFreezeColumn != undefined) {
        this.pageFreezeColumn = res.pageFreezeColumn;
        this.tabulatorTable?.setColumns(
          this.generateColumnCode(this.columnsWithHeaderContext)
        );
      }
    });
    if (this.pageFreezeColumn == 0) {
      this.tabulatorTable?.getColumns()?.map((column) => {
        if (column.getDefinition().frozen == true) {
          column.updateDefinition({
            frozen: false,
            title: column.getDefinition().title,
          });
          this.selectedColumns = this.columnOrder;
        }
      });
    }
  }

  handleRowClick(row : any) {
    this.clickedRowData = row.getData();

    if (this.isFilter) {
      // Use the original data to find the parent rows
      this.parentRows = this.findParentRowsInOriginalData(this.clickedRowData);
    } else {
      // Collect parent rows normally
      this.parentRows = this.collectParentRows(row);
    }
    this.parentRows.reverse();
    this.parentRows.push(this.clickedRowData);
    let data : DDResponse = {
      mode : this.data.mode ?? FormMode.ADD
    }
    switch (true) {
      case (DDLMenuBarType.REGION in this.clickedRowData):
        data = {
          ...data,
          regionRowSelected: this.clickedRowData
        };
        break;
      case (DDLMenuBarType.LANGUAGE in this.clickedRowData):
        data = {
          ...data,
          languageRowSelected: this.clickedRowData
        };
        break;
      case (DDLMenuBarType.CURRENCY in this.clickedRowData):
        data = {
          ...data,
          currencyRowSelected: this.clickedRowData
        };
        break;
      case (DDLMenuBarType.PAGE in this.clickedRowData):
        data = {
          ...data,
          pageRowSelected: this.clickedRowData
        };
        break;
      case (DDLMenuBarType.MODE in this.clickedRowData):
        data = {
          ...data,
          modeRowSelected: this.clickedRowData,
        };
        break;
    }
    this.closeDialog(data);
  }

  findParentRowsInOriginalData(childRowData: any): any[] {
    const parentRows: any[] = [];
    const findParent = (row: any) => {
      const parentId = row.ParentRow?.Row; // Assuming you have a field that points to the parent
      if (parentId) {
        const parentRow = this.originalData.find((r) => r.row === parentId);
        if (parentRow) {
          parentRows.push(parentRow);
          findParent(parentRow); // Recursively find parent
        }
      }
    };
    findParent(childRowData);
    return parentRows;
  }

  collectParentRows(row: any): any[] {
    let parentRows: any[] = [];
    let currentRow = row.getTreeParent(); // Get the parent row

    while (currentRow) {
      parentRows.push(currentRow.getData());
      currentRow = currentRow.getTreeParent(); // Move to the next parent
    }

    return parentRows; // Return the collected parent rows
  }

  closeDialog(data: DDResponse) {
    this.dialogRef.close(data);
  }

  formatRow(row: any, nestedColumn: string): void {
    const depth = row.getData().RowLevel; // Get the RowLevel
    const hasChildren = row.getTreeChildren().length > 0;
    if (!this.hasChildrenInAnyRow) {
      row
        .getCells()
        .forEach((cell: { getElement: () => any; getField: () => string }) => {
          const cellElement = cell.getElement();
        });
    }
    row
      .getCells()
      .forEach((cell: { getElement: () => any; getField: () => string }) => {
        const field = cell.getField();

        if (field === nestedColumn) {
          const cellElement = cell.getElement();
          Array.from(cellElement.querySelectorAll('.line')).forEach((line) => {
            (line as HTMLElement).remove();
          });
          const parentDiv = document.createElement('div');
          parentDiv.classList.add('RowHeaderDiv');

          if (depth > 0) {
            for (let i = 0; i < depth; i++) {
              const lineDiv = document.createElement('div');
              if (hasChildren && i == depth - 1) {
                lineDiv.classList.add('RowHeader');
                lineDiv.innerHTML = row.isTreeExpanded()
                  ? ASCII_DASH
                  : ASCII_PLUS;
                lineDiv.id = 'RowHeaderID';
              } else {
                lineDiv.classList.add('RowHeader');
                lineDiv.innerHTML = ASCII_SPACE;
              }
              parentDiv.append(lineDiv);
              cellElement.appendChild(parentDiv);
            }
          }
          if (depth == 0) {
            const lineDiv = document.createElement('div');
            lineDiv.classList.add('SectionRowHeader');
            lineDiv.innerHTML = row.isTreeExpanded() ? ASCII_DASH : ASCII_PLUS;
            lineDiv.id = 'RowHeaderID';
            parentDiv.append(lineDiv);
            cellElement.appendChild(parentDiv);
          }
        } else {
          const cellElement = cell.getElement();
        }
      });
  }

  toggleFilter() {
    this.mainService.filterStatusDD?.subscribe((res)=>{
      if (res=='Filter is On') {
        this.updateTableWithFilters(true);
        this.isFilter = true;
      } else if (res == 'Filter is Off') {
        this.isFilter = false;
        this.tabulatorTable?.clearHeaderFilter();
        this.updateTableWithFilters(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateTableWithFilters(enable: any) {
    // Replace tabulator data without nested data
    enable
      ? this.tabulatorTable?.replaceData(this.allDataWithoutNested)
      : this.tabulatorTable?.replaceData(this.allData);

    var headers = document.querySelectorAll(
      '.tabulator2 .tabulator .tabulator-header'
    );
    let columns = this.tabulatorTable
      ?.getColumnDefinitions()
      .map((colDef: any) => {
        colDef.headerFilter = enable ? this.customFilterEditor : false;
        return colDef;
      });

    this.tabulatorTable?.setColumns(columns);
  }

  /**
   * Custom filter editor for Tabulator's column headers.
   * This function creates an input field inside the header for custom filtering,
   * with real-time validation, error handling, and event-based triggers for filter application.
   *
   * @param {Object} cell - The Tabulator cell object where the editor is being applied.
   * @param {Function} onRendered - Callback that is called once the editor is rendered.
   * @param {Function} success - Callback to pass the input data back to Tabulator once the filter is applied.
   * @param {Function} cancel - Callback to cancel the editor input (triggered on 'Escape').
   * @param {Object} editorParams - Additional editor parameters passed by Tabulator's configuration.
   * @returns {HTMLElement} - A container element with the input field and an optional error message display.
   */
  customFilterEditor = (
    cell: any,
    onRendered: any,
    success: any,
    cancel: any,
    editorParams: any
  ): HTMLElement => {
    // Create a container to hold the input and error message
    const container = document.createElement('span') as HTMLElement;
    const currentDefinition = cell.getColumn().getDefinition();

    // Create and style the input element for user input
    var input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.style.padding = '2px';
    input.style.width = '100%';
    input.value = cell.getValue();

    // Create and style the error message container (initially hidden)
    const errorMessage = document.createElement('div');
    errorMessage.style.color = 'red';
    errorMessage.style.fontSize = '12px';
    errorMessage.style.display = 'none';
    errorMessage.style.marginTop = '2px';
    errorMessage.textContent = 'Invalid syntax';
    errorMessage.style.fontWeight = '200';

    // Validate the input value and adjust the UI accordingly
    const validateInputValue = () => {
      const isValid = this.filterService.validateInput(input.value);
      const tableHeader = document.querySelector(
        '.tabulator2.tabulator .tabulator-header[role="rowgroup"]'
      ) as HTMLDivElement;
      const tabulator = document.querySelector(
        '.tabulator2.tabulator[role="grid"]'
      ) as HTMLDivElement;
      const tabulatorTable = document.querySelector(
        '.tabulator2.tabulator[tabulator-layout=fitDataFill] .tabulator-tableholder .tabulator-table'
      ) as HTMLDivElement;
      const tabulatorPlaceholder = document.querySelector(
        '.tabulator2.tabulator .tabulator-tableholder'
      ) as HTMLDivElement;

      if (!isValid) {
        // Column current width
        const columnCurrentWidth = cell.getColumn().getWidth();
        // Update custom validatioin flag
        currentDefinition.validationFlag = false;
        // Update column width
        columnCurrentWidth < SystemInitials.MinWidth ? cell.getColumn().setWidth(SystemInitials.MinWidth) : '';
        // If input is invalid, style the input in red and display the error message
        input.style.color = 'red';
        errorMessage.style.display = 'block';
      } else {
        // Update custom validatioin flag
        currentDefinition.validationFlag = true;
        // If input is valid, revert the styles back to normal
        input.style.color = 'black';
        errorMessage.style.display = 'none';
      }
    };

    // Function to build input values and pass them back
    const buildValues = () => {
      success({
        inputColumnStatus: cell.getColumn().getDefinition().status,
        inputColumn: cell.getColumn().getField(),
        inputValue: input.value,
      });
    };

    // Debounce buildValues and validateInput functions
    const debounceBuildValues = this.debounceInput(buildValues, 300);
    const debounceValidateInput = this.debounceInput(validateInputValue, 300);

    // Input event listener (debounced)
    input.addEventListener('input', () => {
      debounceBuildValues();
      debounceValidateInput();
    });

    // Keypress listener for Enter and Escape
    input.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'Enter') buildValues();
      if (e.key === 'Escape') cancel();
    });

    // Finalize filter on blur
    input.addEventListener('blur', buildValues);

    // Append the input and error message to the container
    container.appendChild(input);
    container.appendChild(errorMessage);

    // Return the container element that will be used as the filter editor
    return container;
  };

  /**
   * Debounces a function, ensuring that the provided function `func` is invoked only after
   * the specified `wait` time has passed since the last time the debounced function was called.
   *
   * @param {Function} func - The function to be debounced.
   * @param {number} wait - The number of milliseconds to wait before calling the function after the last invocation.
   * @returns {Function} - A new debounced function that delays the execution of `func` until after `wait` milliseconds
   *                       have passed since the last invocation.
   */
  debounceInput(func: any, wait: any): Function {
    let timeout: any;
    return (...args: any) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  /**
   * Checks if any of the provided statuses meet specific filtering rules related to items.
   *
   * @param {string[]} statuses - An array of status strings to check.
   * @returns {boolean} - Returns `true` if any status includes 'Item' and matches predefined rules; otherwise, returns `false`.
   */
  checkColStatus(statuses: []): boolean {
    // Define the rules for valid item statuses
    const rules = ['Item#≥0', '≥1', '≤2', '=2', '≥2'];

    // Find the first status that includes the substring 'Item'
    const status = statuses.find(
      (status: string) => typeof status === 'string' && status.includes('Item')
    );
    if (status && rules.includes(status)) return true;
    return false;
  }

  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.isResizing = true;
    this.initialMouseX = event.clientX;
    this.initialMouseY = event.clientY;

    const popup = document.querySelector('.edit-item-container') as HTMLElement;
    this.initialWidth = popup.offsetWidth; // Get the current width
    this.initialHeight = popup.offsetHeight; // Get the current height

    document.body.classList.add('no-select');
    event.preventDefault();

    document.addEventListener('mousemove', this.onResize);
    document.addEventListener('mouseup', this.onResizeEnd);
  }

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.isDragging = true;
    this.offsetX =
      event.clientX -
      this.popupContainer.nativeElement.getBoundingClientRect().left;
    this.offsetY =
      event.clientY -
      this.popupContainer.nativeElement.getBoundingClientRect().top;
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      this.moveDialog(event);
    }
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.isDragging = false;
  }
  @ViewChild('popupContainer') popupContainer!: ElementRef; // to get the reference of the popup container for further manipulaiton

  // Move the dialog within the viewport
  moveDialog(event: MouseEvent) {
    const dialogElement = this.popupContainer.nativeElement;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculating the new position
    let newLeft = event.clientX - this.offsetX;
    let newTop = event.clientY - this.offsetY;

    // Limitizing the window for dialog to be draggable within the viewport
    newLeft =
      Math.max(
        0,
        Math.min(
          newLeft,
          viewportWidth - dialogElement.offsetWidth - (this.scrollbarWidth + 15)
        )
      ) +
      dialogElement.offsetWidth / 1.85;
    newTop =
      Math.max(
        0,
        Math.min(newTop, viewportHeight - dialogElement.offsetHeight)
      ) +
      dialogElement.offsetHeight / 2;
    // Defining new position for the dialog
    dialogElement.style.left = `${newLeft}px`;
    dialogElement.style.top = `${newTop}px`;
  }
  onResize = (event: MouseEvent) => {
    if (this.isResizing) {
      event.preventDefault();
      // Check if the mouse is within the viewport bounds
      if (
        event.clientX < 0 ||
        event.clientY < 0 ||
        event.clientX > window.innerWidth - this.scrollbarWidth ||
        event.clientY > window.innerHeight
      ) {
        return; // Stop resizing if the mouse is outside the viewport
      }

      const dx = event.clientX - this.initialMouseX;
      const dy = event.clientY - this.initialMouseY;
      const popup = this.popupContainer.nativeElement;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Get the current position and dimensions of the popup
      const popupRect = popup.getBoundingClientRect();

      // Calculate the new width and height based on mouse movement
      this.newWidth = Math.max(100, this.initialWidth + dx);
      this.newHeight = Math.max(100, this.initialHeight + dy);

      // Check if the dialog is near the left boundary and allow shrinking
      if (popupRect.left <= 0) {
        if (dx < 0) {
          // Allow shrinking when resizing to the left (dx < 0)
          this.newWidth = Math.max(100, popupRect.width - Math.abs(dx));
        } else {
          // Prevent increasing size if moving to the right
          this.newWidth = popupRect.width + popupRect.left;
        }
      }

      // Check if the dialog is near the top boundary and allow shrinking
      if (popupRect.top <= 0) {
        if (dy < 0) {
          // Allow shrinking when resizing upwards (dy < 0)
          this.newHeight = Math.max(100, popupRect.height - Math.abs(dy));
        } else {
          // Prevent increasing size if moving downwards
          this.newHeight = popupRect.height + popupRect.top;
        }
      }

      // Stop resizing if the popup is touching or crossing the right boundary
      if (
        popupRect.left + this.newWidth >=
        viewportWidth - this.scrollbarWidth
      ) {
        this.newWidth = viewportWidth - popupRect.left - this.scrollbarWidth;
      }

      // Stop resizing if the popup is touching or crossing the bottom boundary
      if (popupRect.top + this.newHeight >= viewportHeight) {
        this.newHeight = viewportHeight - popupRect.top;
      }

      // Apply the constrained width and height to the popup element
      popup.style.width = `${this.newWidth}px`;
      popup.style.height = `${this.newHeight}px`;
    }
  };

  onResizeEnd = () => {
    this.isResizing = false;
    document.body.classList.remove('no-select');
    document.removeEventListener('mousemove', this.onResize);
    document.removeEventListener('mouseup', this.onResizeEnd);
  };
}
