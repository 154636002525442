<div class="edit-col-container" cdkDropList (cdkDropListDropped)="drop($event)" #popupContainer>
  <div class="modal-header" (mousedown)="onDragStart($event)">
    <div class="modal-title text-nowrap">PickCol-Dialog</div>
    <div class="close-icon" (click)="onClose()">
      {{ closeTab }}
    </div>
  </div>
  <div class="checkbox-item">
    <div cdkDropList *ngIf="!data.dialog" (cdkDropListDropped)="drop($event)">
      <div *ngFor="let col of columns; let i = index" cdkDrag>
        <div class="col-container" (click)="idSelector(col.col)">
          <div class="col-item">
            <span class="text-nowrap">{{ col?.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div cdkDropList *ngIf="data.dialog" (cdkDropListDropped)="drop($event)">
      <div *ngFor="let coldd of columnsDDI; let i = index" cdkDrag>
        <div class="col-container">
          <div class="col-item">
            <span class="text-nowrap">{{ coldd?.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <span [innerHTML]="expandIcon"></span>
  </div>
</div>