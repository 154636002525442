import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private readonly storageKey = 'pageData'; // Key used in sessionStorage

  constructor() {}

  getTabsDataById(pageId : number, tabs? : any){
    const pageData : any = this.getAllPageData();
    const key : number = Number(pageId);
    if(tabs && tabs.allTabsData && tabs.allTabsData.length > 0){
      const obj = tabs.allTabsData.filter((obj : any ) => key && obj[key] !== undefined)[0];
      return obj[key] ?? null;
    }else if(pageData && pageData.length > 0 ){
      const obj = pageData.filter((obj : any ) => key && obj[key] !== undefined)[0];
      return obj[key] ?? null;
    }else {
      return null
    };
  }

  // Save data for a specific page
  savePageData(tabId: string, data: any): void {
    const pageData = data ?? this.getAllPageData();
    pageData[tabId] = data; // Add or update page data
    sessionStorage.setItem(this.storageKey, JSON.stringify(pageData));
  }

  // Get data for a specific page by pageId
  getPageData(tabId: string): any | null {
    const pageData = this.getAllPageData();
    return pageData[tabId] || null; // Return data or null if not found
  }

  // Clear data for a specific page by pageId
  clearPageData(tabId: string): void {
    const pageData = this.getAllPageData();
    delete pageData[tabId]; // Remove page data
    sessionStorage.setItem(this.storageKey, JSON.stringify(pageData));
  }

  // Clear all tab data
  clearAllPages(): void {
    sessionStorage.removeItem(this.storageKey);
  }

  // Get all page data
  public getAllPageData(): Record<string, any> {
    const data = sessionStorage.getItem(this.storageKey);
    return data ? JSON.parse(data) : [];
  }
}
