import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgxsModule, provideStore } from '@ngxs/store';
import { SheetState } from './store/page/page.store';
import { environment } from '../envirnoments/environment';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { ItemState } from './store/item/item.store';
import { FormatStore } from './store/format/format.store';
import { PgTabStore } from './store/pg-tab/pg-tab.store';
import { PgRowStore } from './store/row/row.store';
import { ColRowStore } from './store/cols/cols.store';
import { AppStore } from './store/root/root.store';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideStore([
      AppStore,
      SheetState,
      ItemState,
      FormatStore,
      PgTabStore,
      PgRowStore,
      ColRowStore], withNgxsReduxDevtoolsPlugin()),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  ],
};
