import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { ADD_ROW_MODEL } from '../../shared/models/row/row.models';
import { last, Observable } from 'rxjs';
import { FormatResponse } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class ColsService {
  private apiUrl = 'tCol';
  constructor(private apiService: ApiService, private http: HttpClient) {}
  addPgCols(row: ADD_ROW_MODEL): Observable<FormatResponse<any>> {
    return this.apiService.post<FormatResponse<any>>(`${this.apiUrl}/createColAndRow`,row)
  }
}
