<div class="d-flex align-center justify-center">
  <div
    appControlShift
    class="add-underline">
    <input
      class="expand-input before-input"
      [min]="minValue"
      appDynamicInputWidth
      [max]="maxValue"
      [(ngModel)]="beforeValue"
      [disabled]="halt"
      [inputValue]="beforeValue"
      #value
      tabindex="-1"
      (keydown)="onKeyDown($event)"
      type="number">
    <span class="dot expand-input">.</span>
    <input
      [(ngModel)]="afterValue"
      appDynamicInputWidth
      [inputValue]="afterValue"
      [disabled]="halt"
      [min]="0"
      [max]="9"
      tabindex="-1"
      class="expand-input after-input"
      type="number">
  </div>
  <div 
    tabindex="-1"
    class="expand-icon flex-row">
    <span
      class="counter-btn inc"
      (click)="incrementValue()">
    </span>
      {{ arrow_up_down }}
    <span
      class="counter-btn dec"
      (click)="decrementValue()">
    </span>
  </div>
</div>
