export interface PageStateModel {
  visitedPages: any[];
  addRow: any[];
  sheetData: any[];
  sheetColumns: any[];
  allTabsData: Record<number, TabData>[];
  allTabsFlatData: Record<number, TabData>[];
  defaultPageId: number | null;
  tabData: any[];
  loading: boolean;
  PickDdiData: any[];
  PickDdiColumns: any[];
  filteredSheetData: any[];
  regions: any[];
  formatLocalCol: any | null;
  deleteItemData: any | null;
  frozen: any | null;
  expandWidth: number | null;
  rowLevel : {
    ddl : number,
    page : number,
  }
}

export interface TabData {
  pageData: any[];
  pageColumns: any[];
}

export const PageTabState : PageStateModel = {
  visitedPages:  [],
  addRow:[],
  sheetData: [],
  sheetColumns:[],
  allTabsData : [],
  allTabsFlatData: [],
  defaultPageId : null,
  tabData:[],
  loading:false,
  PickDdiData:[],
  PickDdiColumns:[],
  filteredSheetData:[],
  regions : [],
  formatLocalCol : null,
  deleteItemData : null,
  frozen : null,
  expandWidth : null,
  rowLevel :  {
    page : 1.0,
    ddl : 1.0
  },
}
