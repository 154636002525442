<div class="add-row-container" #addrowcontainer >

    <div class="dialog-header" (mousedown)="onDragStart($event)">
      <span class="heading">{{data.title}}-dialog</span>
    </div>
    <div class="edit-item-container" style="height: 100%;" >

      <div class="form-group2" style="overflow: auto; flex-direction: column;">
        <div class="button-group">
          <div class="delete-button-container">
            <div type="button" class="Chip" (click)="onSave()">Save</div>
          </div>
          <div type="button" class="Chip" (click)="closeDialog()">Cancel</div>
        </div>

      </div>
    </div>

    <div class="resizer" (mousedown)="onResizeStart($event)">
      <span [innerHTML]="expandIcon"></span>
    </div>
  </div>
