<div class="menu-item-container" *ngFor="let menu of menuOptions">
  <ng-container [ngSwitch]="menu">
    <!-- Default for 'col' -->
    <div *ngSwitchCase="'Cols'" class="Chip" (click)="openColDialog()">
      <div class="dropdown-button-container">
        <span>{{ menu }}</span>
      </div>
    </div>

    <!-- Content for 'freeze' -->
    <div *ngSwitchCase="'Freeze'" class="Chip">
      <div class="dropdown-button-container">
        <div class="tooltip-container">{{ menu }}:</div>
          <app-freeze-field
            [columns]="columns$ | async"
            context="edit-dd-dialog">
          </app-freeze-field>
      </div>
    </div>

    <!-- Content for 'expand' -->
    <div *ngSwitchCase="'Expand'" class="Chip">
      <div class="dropdown-button-container">
        <div class="tooltip-container">
          {{ menu }}:
          <div class="dropdown-button-container">
            <app-decimal-field
              (expandLevel)="onExpandChange($event)"
              [halt]="ddlLanuages !== data.token && DDL_Regions !== data.token"
              [minValue]="1"
              [byEvent]="true">
            </app-decimal-field>
          </div>
        </div>
      </div>
    </div>

    <!-- Content for 'sort' -->
    <div *ngSwitchCase="'Sort'" class="Chip">
      <div class="dropdown-button-container">
        <div class="tooltip-container">
          <span>{{ menu }}</span>
        </div>
        <input
          type="checkbox"
          id="sortCheckbox"
          name="sortCheckbox"
          [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          class="checkbox"
          (change)="toggleSort()"
        />
      </div>
    </div>

    <!-- Content for 'filter' -->
    <div *ngSwitchCase="'Filter'" class="Chip">
      <div class="dropdown-button-container">
        <div class="tooltip-container">
          <span>{{ menu }}</span>
        </div>
        <input
          (change)="toggleFilter()"
          [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          type="checkbox"
          id="filterCheckbox"
          name="filterCheckbox"
          class="checkbox"
        />
      </div>
    </div>

    <!-- Content for 'clear' -->
    <div *ngSwitchCase="'Clear'" class="Chip" (click)="clearFilters()">
      <div class="dropdown-button-container">
        <span style="color: red">Clear</span>
      </div>
    </div>
    <!-- Default case (if no match) -->
    <div *ngSwitchDefault class="Chip">
      <div>{{ menu }}</div>
    </div>
  </ng-container>
</div>
