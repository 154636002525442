import { Injectable } from '@angular/core';
import {  Store } from '@ngxs/store';
import { AddItemThroughDropdown, GetItemDropdown, UpdateItemThroughDropdown } from '../../../store/item/item.action';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  private apiUrl = 'tPg/';
  constructor(
    private store: Store,
    private apiService : ApiService
  ) {}

  updateCellItem(formData: any) {
    // API call through store dispatch
    const payload = {
      pageId: formData.pageId,
      colId: formData.columnId,
      rowId: formData.rowId,
      Object : formData.tokenId
    }
    this.store.dispatch(new UpdateItemThroughDropdown(payload));
  }

  addCellItem(formData: any) {
    const payload = {
      pageId: formData.pageId,
      colId: formData.columnId,
      rowId: formData.rowId,
      DataType: formData.columnDatatype,
      Object : formData.tokenId
    }
    this.store.dispatch(new AddItemThroughDropdown(payload));
  }

  getDropdownData(colId?: number) {
    this.store.dispatch(new GetItemDropdown(colId));
    const data = [
      {tokenId: 3000000251, pageType: 'Each Page'},
      {tokenId: 3000000252, pageType: 'Pages List'},
      {tokenId: 3000000253, pageType: 'Category'},
      {tokenId: 3000000254, pageType: 'Product'},
      {tokenId: 3000000255, pageType: 'LevelSet'},
      {tokenId: 3000000256, pageType: 'SearchSet'},
    ];

    return data;
  }

  getDDLForAllColumns(){
    return this.apiService.get<any[]>(this.apiUrl+'DDLS/DataTypes');
  }
}
