import { Injectable } from '@angular/core';
import { MainService } from '../main-service/main.service';
import { FILTER_TYPES } from '../../../constant';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  constructor(
    private mainService : MainService
  ) {}

  parseFilterInput(input: string): string[] {
    let rawParts = input.match(
      /\[.*?\]|"[^"]*"|'[^']*'|[^ ]+/g
    );
  
    // Remove backslashes from matches
    const parts: string[] | undefined = rawParts?.map((part: any) =>
      part.startsWith('"') || part.startsWith("'")
        ? part.replace(/\\(["'])/g, '$1')?.slice(1, -1) // Unescape quotes within the string
        : part
    );
  
    return parts 
      ? parts
        .filter(
          part => 
            part.toString().toUpperCase() !== `[${FILTER_TYPES.AND}]` && 
            part.toString().toUpperCase() !== `[${FILTER_TYPES.SYMBOL_AND}]`
        )
        .map(part => {
          if (
            part.toString().toUpperCase() === `[${FILTER_TYPES.OR}]` ||
            part.toString().toUpperCase() === `[${FILTER_TYPES.SYMBOL_OR}]`
          ) {
            part = `[${FILTER_TYPES.OR}]`;
          }
          return part;
        })    
    : [];
  }

  customFilterFunction = (
    headerValue: any,
    rowValue: any,
    rowData: any,
    filterParams: any
  ) => {
    const filterConditions = this.parseFilterInput(headerValue.inputValue)
    const orConditions = filterConditions.join(' ').split(`[${FILTER_TYPES.OR}]`);

    const validator: boolean = this.validateInput(headerValue.inputValue);
    if (!validator) return true;

    if (this.mainService.checkColStatus(headerValue.inputColumnStatus)) {
      const search = ';';
      const replacement = ' ';
      rowValue = rowValue?.replace(new RegExp(search, 'g'), replacement);
      rowValue = rowValue
        ?.split(' ')
        .map((name: string) => name.trim())
        .filter((name: string) => name);

      rowValue = rowValue?.join(' ');
    }

    if (rowValue && headerValue.inputValue) {
      const filteration = orConditions.some(orCondition => {
        const conditions = this.parseFilterInput(orCondition);
        const filters = this.createFilters(conditions);
        if (filters.length == 0) return true;

        return filters.every((filter: any, index: number) => {
          return filter.types.every((filterType: string) => {
            return filter.strings.every((str: string) => {
              const filterString = str?.replace(/^['"](.*)['"]$/, '$1');
  
              switch (filterType) {
                case FILTER_TYPES.LIKE:
                case FILTER_TYPES.L:
                  return rowValue
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(filterString?.toLowerCase());
                 
                case FILTER_TYPES.NOT_LIKE:
                case FILTER_TYPES.NOT_L:
                  return !rowValue
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(filterString?.toLowerCase());
  
                case FILTER_TYPES.MATCH:
                case FILTER_TYPES.M:
                  return rowValue?.includes(filterString);
  
                case FILTER_TYPES.NOT_MATCH:
                case FILTER_TYPES.NOT_M:
                  return !rowValue?.includes(filterString);
  
                case FILTER_TYPES.START:
                case FILTER_TYPES.S:
                  return rowValue
                    ?.toString()
                    ?.toLowerCase()
                    ?.startsWith(filterString?.toLowerCase());
  
                case FILTER_TYPES.NOT_START:
                case FILTER_TYPES.NOT_S:
                  return !rowValue
                    ?.toString()
                    ?.toLowerCase()
                    ?.startsWith(filterString?.toLowerCase());
  
                case FILTER_TYPES.END:
                case FILTER_TYPES.E:
                  return rowValue
                    ?.toString()
                    ?.toLowerCase()
                    ?.endsWith(filterString?.toLowerCase());
  
                case FILTER_TYPES.NOT_END:
                case FILTER_TYPES.NOT_E:
                  return !rowValue
                    ?.toString()
                    ?.toLowerCase()
                    ?.endsWith(filterString?.toLowerCase());
  
                case FILTER_TYPES.REGEX:
                case FILTER_TYPES.R:
                  try {
                    // Extract the pattern and flags
                    var regexMatch = filterString.match(/^\/(.+)\/([igmuy]?)$/);
                    if (regexMatch) {
                      var regex = new RegExp(regexMatch[1], regexMatch[2]);
                      return regex.test(rowValue?.toString());
                    } else {
                      return false;
                    }
                  } catch (e) {
                    return false;
                  }
  
                default: // Default case uses 'Like'
                  return filterString
                    .toString()
                    .split(' ')
                    .every((str: string) =>
                      rowValue
                        ?.toString()
                        ?.toLowerCase()
                        ?.includes(str.toLowerCase())
                    );
              }
            });
          });
        });
      })
      
      return filteration;
    }

    return true;
  };

  validateInput(inputValue: string) {
    const filterTypes: string[] = Object.values(FILTER_TYPES);
    const validationPassed = true;
    const validationFailed = false;

    // Split the input string into filter types and filter strings
    let rawParts = inputValue.match(/\[.*?\]|"[^"]*"|'[^']*'|[^ ]+/g);
    // Remove backslashes from matches
    let conditions = rawParts?.map((part: any) =>
      part.startsWith('"') || part.startsWith("'")
        ? part.replace(/\\(["'])/g, '$1')?.slice(1, -1) // Unescape quotes within the string
        : part
    );

    const filters = this.createFilters(conditions);
    const validator = filters.every((filter: any) => {
      return filter.types.every((type: any) => {
        if (
          filterTypes.includes(type) &&
          filter.strings.length > 0 &&
          filter.types.length === 1
        ) {
          if (type == FILTER_TYPES.REGEX || type == FILTER_TYPES.R) {
            return filter.strings.every((string: string) => {
              if (!this.mainService.isValidRegex(string)) return false;
              if (string.trim() === '') return false;
              
              return true;
            });
          }
          return true;
        } else if (
          filterTypes.includes(type) &&
          (filter.types.includes(FILTER_TYPES.AND) ||
            filter.types.includes(FILTER_TYPES.SYMBOL_AND)) &&
          filter.strings.length > 0 &&
          (filter.types.length == 1 || filter.types.length == 2)
        ) {
          return true;
        } else if (
          filterTypes.includes(type) &&
          (filter.types.includes(FILTER_TYPES.OR) ||
            filter.types.includes(FILTER_TYPES.SYMBOL_OR)) &&
          filter.strings.length > 0 &&
          (filter.types.length == 1 || filter.types.length == 2)
        ) {
          return true;
        } else {
          return false;
        }
      });
    });

    if (!validator || (inputValue && !conditions)) return validationFailed;
    if (conditions && conditions.length > 0 && conditions[0] === '[]') return validationFailed;

    if (conditions) {
      for (let i = 0; i < conditions?.length; i++) {
        // Check for consecutive or mismatched brackets
        if (
          conditions[i]?.startsWith('[') &&
          conditions[i]?.endsWith(']') &&
          conditions.length > 1 &&
          conditions[i + 1]?.startsWith('[') &&
          conditions[i + 1]?.endsWith(']')
        ) {
          conditions[i].includes(FILTER_TYPES.AND) ||
          conditions[i].includes(FILTER_TYPES.SYMBOL_OR) ||
          conditions[i].includes(FILTER_TYPES.OR) ||
          conditions[i].includes(FILTER_TYPES.SYMBOL_OR)
            ? validationPassed
            : validationFailed;
        } else if (
          (conditions[i]?.startsWith('[') && !conditions[i]?.endsWith(']')) ||
          conditions[i]?.startsWith(']')
        ) {
          return validationFailed;
        }
      }
    } else {
      return validationPassed;
    }

    return validationPassed;
  }

  createFilters(conditions: any) {
    const filters: any = [];
    let currentFilterTypes: any = [];
    let currentFilterStrings: any = [];

    if (!conditions) return [];
    if (conditions.length == 0) return [];

    // Creaet filters based on provided conditions of filter-type and filter-string
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].startsWith('[') && conditions[i].endsWith(']')) {
        // If there is an existing filter type and strings, push them to the filters array
        if (currentFilterStrings.length > 0) {
          filters.push({
            types:
              currentFilterTypes.length == 0
                ? [FILTER_TYPES.LIKE]
                : currentFilterTypes,
            strings: currentFilterStrings,
          });

          currentFilterTypes = [];
          currentFilterStrings = [];
        }

        // Push parsed filter type
        const filterType = this.parseFilterType(conditions[i]);
        currentFilterTypes.push(...filterType);
      } else {
        // Accumulate filter strings
        currentFilterStrings.push(conditions[i]);

        // TODO: Uncomment the below line of code, as this will be used to filter hyphrn (-) separated words
        // currentFilterStrings.push(parts[i].replace(/-+/g, ' '));
      }
    }

    // Push the last filter type and strings
    if (currentFilterTypes.length > 0 && currentFilterStrings.length > 0) {
      filters.push({
        types: currentFilterTypes.map((type: any) => type),
        strings: currentFilterStrings,
      });
    }

    // Apply the "LIKE" if filter-type is not present
    if (currentFilterTypes.length == 0 && currentFilterStrings.length > 0) {
      filters.push({
        types: [FILTER_TYPES.LIKE],
        strings: currentFilterStrings,
      });
    }

    // Push empty filter-type for empty filter-type
    if (currentFilterTypes.length > 0 && currentFilterStrings.length == 0) {
      filters.push({
        types: currentFilterTypes,
        strings: currentFilterStrings,
      });
    }

    return filters;
  }

  parseFilterType(filterType: string) {
    if (!filterType?.slice(1, -1)) {
      return ['[]'];
    }
    return filterType
      ?.slice(1, -1)
      .split(' ')
      .map((type: string) => type?.toUpperCase());
  }

  // TODO: Recursive function to filter data. This can be removed. 
  // This was used before, right now it is not using in application
  filterData(rowData: any, filterString: any) {
    const filterLower = filterString.toLowerCase();

    // Base case: Check if there are no children
    if (!rowData?._children || rowData._children.length === 0) {
      return rowData?.page_name?.toLowerCase().includes(filterLower)
        ? { page_name: rowData.page_name }
        : null;
    }

    // Check if the parent matches the filter string
    const parentMatches = rowData?.page_name
      ?.toLowerCase()
      .includes(filterLower);

    // Recursively check children for matches
    const matchedChildren = rowData._children62
      .map((child: any) => this.filterData(child, filterString)) // Call recursively
      .filter((child: any) => child !== null); // Remove null results

    // If there are matched children, return them without the parent
    if (matchedChildren.length > 0) {
      return matchedChildren; // Return matched children
    }

    // If the parent matches and no children matched, return the parent
    if (parentMatches && matchedChildren.length === 0) {
      return { page_name: rowData.page_name }; // Return only the parent if no children matched
    }

    // If neither matches, return null or handle accordingly
    return null; // or whatever you need in case of no matches
  }
}