import { AfterViewInit, DestroyRef, Directive, ElementRef, HostListener, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { TooltipService } from '../../core/services/tooltip.service';
import { combineLatest, distinctUntilChanged, forkJoin, last, of, switchMap, takeLast, withLatestFrom } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { clickOutsideHandler } from 'ckeditor5';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnInit {
  destroyRef = inject(DestroyRef);
  clicked = false;
  private _id: string = '';
  @Input('appTooltip') tooltipContent: string = '';
  @Input()
  get name(): string {
    return this._id;
  }
  set name(value: string) {
    this._id = value;
  }
  private tooltipElement: HTMLElement | null = null;
  private showTimeout: any;
  private hideTimeout: any;
  constructor(private elementRef: ElementRef, private renderer: Renderer2, private tooltipService: TooltipService) {}
  ngOnInit(): void {
    this.tooltipService.getTriggerTooltip$.pipe(
      takeUntilDestroyed(this.destroyRef),
      distinctUntilChanged(),
      withLatestFrom(this.tooltipService.getIdFOrToolTip$),
      switchMap(([toolTip, id]) => {
        if (id === this._id) {
          this.clicked=true;
          this.createTooltip(toolTip,this.clicked);
          this.handleTimeout(1000);
          this.clicked=false;
        } else {
          this.destroyTooltip();
        }
        return of([toolTip, id]);
      })
    ).subscribe();

    this.tooltipService.destroyTooltip$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(()=>this.destroyTooltip())
  }

  @HostListener('mouseenter') onMouseEnter() {
    clearTimeout(this.hideTimeout);
    this.showTimeout = this.createTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    clearTimeout(this.showTimeout);
    if (!this.isMouseOverTooltip()) {
        this.destroyTooltip();
    }
  }

  private createTooltip(message?: any, clicked?: boolean) {
    if (this.tooltipElement || !this.tooltipContent) return;

    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'custom-tooltip');
    if (clicked) {
      this.renderer.addClass(this.tooltipElement, 'stay-tooltip');
      setTimeout(() => {
        if (this.tooltipElement) {
          setTimeout(() => {
            this.renderer.addClass(this.tooltipElement, 'stay-tooltip');

          }, 1000);
        }
        this.renderer.addClass(this.tooltipElement, 'stay-tooltip');
      }, 5000);

    } else {
      this.renderer.addClass(this.tooltipElement, 'show-tooltip');
    }

    const preElement = this.renderer.createElement('p');
    this.renderer.appendChild(preElement, this.renderer.createText(`${message ?? this.tooltipContent}`));
    this.renderer.appendChild(this.tooltipElement, preElement);

    const hostPosition = this.elementRef.nativeElement.getBoundingClientRect();
    this.renderer.setStyle(this.tooltipElement, 'top', `${hostPosition.bottom + window.scrollY}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${hostPosition.left + window.scrollX}px`);

    if (this.tooltipElement) {
      document.body.appendChild(this.tooltipElement);

      this.renderer.listen(this.tooltipElement, 'mouseenter', () => {
        clearTimeout(this.hideTimeout);
      });
      if(!this.clicked){
        this.renderer.listen(this.tooltipElement, 'mouseleave', () => {
          this.hideTimeout = this.destroyTooltip();
        });
      }
    }
  }

  private isMouseOverTooltip() {
    return this.tooltipElement && document.querySelector('.custom-tooltip:hover') !== null;
  }

  private destroyTooltip() {
    if (this.tooltipElement) {
    this.renderer.removeClass(this.tooltipElement, 'show-tooltip');
    this.renderer.addClass(this.tooltipElement, 'hide-tooltip');
   
        if (this.tooltipElement) {
          document.body.removeChild(this.tooltipElement);
          this.tooltipElement = null;
        }
    }
  }

  private async handleTimeout(delay: number) {
    await this.waitForTimeout(delay);
    this.destroyTooltip();
  }

  private waitForTimeout(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

}
