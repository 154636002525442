import { Component, HostListener, ElementRef, ViewChild, Inject, OnInit, inject, DestroyRef } from '@angular/core';
import { ASCII_EXPAND_ICON } from '../../../core/constants/app.contants';
import { DragService } from '../../../core/services/drag-service/drag.service';
import { ResizeService } from '../../../core/services/resize-service/resize.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RowComponent } from 'tabulator-tables';
import { ADD_ROW_MODEL } from '../../models/row/row.models';
import { ContextActions, Page } from '../../../core/constants/menu-bar/page/page';
import { map, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RowPageData } from '../../models/edit-dd-dailogs/dd-dailog.models';
import { AddChildRow, AddChildSystemRow, AddNextRow, AddNextSystemRow, AddPrevRow, AddPrevSystemRow } from '../../../store/row/row.action';
import { AddChildCol, AddNextCol, AddPrevCol } from '../../../store/cols/cols.action';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';

interface dialogData {
  title: string,
  currentRow : RowComponent,
}

@Component({
  selector: 'app-add-row-dialog',
  templateUrl: './add-row-dialog.component.html',
  styleUrl: './add-row-dialog.component.scss'
})
export class AddRowDialogComponent implements OnInit{
  destroyRef = inject(DestroyRef);
  getCurrentPgId$ : Observable<PgTab> = inject(Store).select(PgTabStore.getSelectedPgTab)
  pgId : number = 0;
  pages = {
    isAllPages : false,
    isAllCols : false,
    isOthers : false
  }
  constructor(private dragService: DragService,
    public dialogRef: MatDialogRef<AddRowDialogComponent>,
    private resizeService: ResizeService,
    private store : Store,
    @Inject(MAT_DIALOG_DATA) public data: dialogData,
  ) {}

  ngOnInit(): void {
    this.getCurrentPgId$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map((res : PgTab)=> Number(res.page_id)),
    ).subscribe((pgId : number) => {
      this.pgId =  pgId;
      if(this.pgId === Page.ALL_PAGES){
        this.pages.isAllPages = true;
      }else if(this.pgId === Page.ALL_COLS){
        this.pages.isAllCols = true;
      }else{
        this.pages.isOthers = true;
      }
    });
  }


  expandIcon = ASCII_EXPAND_ICON;
  @ViewChild('addrowcontainer') popupContent!: ElementRef;

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContent);
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContent);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }

  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContent);
  }

  onSave(): void {
    if (!this.data.currentRow) {
      return;
    }

    const currentRowId = this.getCurrentRowData()?.row ?? null;
    const nextRowId = this.getNextRowData()?.row ?? null;
    const prevRowId = this.getPrevRowData()?.row ?? null;

    const row: ADD_ROW_MODEL = {
      Pg: this.pgId,
      Share: null,
      ParentRow: null,
      SiblingRow: null,
      RowType: null,
      RowLevel: 1,
      Inherit: null,
    };

    switch (this.data.title) {
      case ContextActions.ADD_CHILD_ROW: {
        const rowData = this.getCurrentRowData() as RowPageData;
        row.ParentRow = currentRowId ? Number(currentRowId) : null;
        row.RowLevel = rowData.RowLevel + 1;
        if (this.pages.isAllPages) {
          this.dispatchAction(AddChildRow, row, prevRowId, nextRowId, currentRowId);
        } else if (this.pages.isAllCols) {
          this.dispatchAction(AddChildCol, row, prevRowId, nextRowId, currentRowId);
        }else{
          this.dispatchAction(AddChildSystemRow, row, prevRowId, nextRowId, currentRowId);
        }
        break;
      }
      case ContextActions.ADD_NEXT_ROW: {
        row.SiblingRow = currentRowId ? Number(currentRowId) : null;
        if (this.pages.isAllPages) {
          this.dispatchAction(AddNextRow, row, prevRowId, nextRowId, currentRowId);
        }else if (this.pages.isAllCols) {
          this.dispatchAction(AddNextCol, row, prevRowId, nextRowId, currentRowId);
        }else{
          this.dispatchAction(AddNextSystemRow, row, prevRowId, nextRowId, currentRowId);
        }
        break;
      }
      case ContextActions.ADD_PREV_ROW: {
        row.SiblingRow = prevRowId ? Number(prevRowId) : null;
        if (this.pages.isAllPages) {
          this.dispatchAction(AddPrevRow, row, prevRowId, nextRowId, currentRowId);
        } else if (this.pages.isAllCols) {
          this.dispatchAction(AddPrevCol, row, prevRowId, nextRowId, currentRowId);
        }else{
          this.dispatchAction(AddPrevSystemRow, row, prevRowId, nextRowId, currentRowId);
        }
        break;
      }
      default:
        break;
    }

    this.closeDialog();
  }

  dispatchAction(
    action: any,
    row: ADD_ROW_MODEL,
    prevRowId: string | null,
    nextRowId: string | null,
    currentRowId: string | null
  ){
    this.store.dispatch(
      new action({
        row,
        prevRowId: prevRowId ? Number(prevRowId) : null,
        nextRowId: nextRowId ? Number(nextRowId) : null,
        currentRowId: currentRowId ? Number(currentRowId) : null,
      })
    );
  };


  private getRowData(rowComp: RowComponent | null): any | null {
    return rowComp ? rowComp?.getData() : null;
  }

  getCurrentRowData(): RowPageData | null {
    return this.data.currentRow?.getData() as RowPageData|| null;
  }

  getPrevRowData(): RowPageData | null {
    const prevRowComp = this.data.currentRow?.getPrevRow() as RowComponent;
    return this.getRowData(prevRowComp);
  }

  getNextRowData(): RowPageData | null {
    const nextRowComp = this.data.currentRow?.getNextRow() as RowComponent;
    return this.getRowData(nextRowComp);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
