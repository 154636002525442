import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Input,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef,
  inject,
  DestroyRef,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../../modals/page.modal';
import { MainService } from '../../../core/services/main-service/main.service';
import { FormatPageComponent } from '../format-page-dialog/format-page/format-page-main.component';
import { DialogDeleteComponent } from '../dialog-delete/dialog-delete.component';
import { PGColDialogComponent } from '../../../modals/pgcol-dialog/pgcol-dialog.component';
import { PGUserDialogComponent } from '../../../modals/pguser-dialog/pguser-dialog.component';
import { Select, Store } from '@ngxs/store';
import { SheetState } from '../../../store/page/page.store';
import { take } from 'rxjs/operators';
import { Observable, Subject, takeUntil, map, of } from 'rxjs';
import { Sheet } from '../../../store/page/page.actions';
import { AutoAdjustWidthDirective } from '../../../shared/directives/auto-adjust-width.directive';
import { editDdDialogMainComponent } from '../../../shared/components/edit-dd-dialog/edit-dd-dialog-main/edit-dd-dialog-main.component';

import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import {
  All_Pages_ID,
  Countries,
  DDL_All_Pages,
  DDL_Currencies,
  DDL_Languages,
  DDL_Page_Edition,
  DDL_Page_Modes,
  DDL_Regions,
  ObjectType,
  UNICODE_GLOBE,
  UNICODE_LANGUAGE,
  UNICODE_LEFT_RIGHT_ARROW,
  UNICODE_RELOAD,
} from '../../../core/constants/app.contants';
import { DialogViewComponent } from '../dialog-view/dialog-view.component';
import {
  DDResponse,
  RowCurrencyData,
  RowLanguageData,
  RowPageData,
  RowRegionData,
} from '../../models/edit-dd-dailogs/dd-dailog.models';
import { TooltipService } from '../../../core/services/tooltip.service';
import {
  MenuBar,
  SelectedMenuBar,
} from '../../../core/constants/menu-bar/menu-bar.contants';
import {
  AddPgTab,
  LoadMenuBar,
  SetFontStyle,
} from '../../../store/pg-tab/pg-tab.actions';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';
import { FormBuilder } from '@angular/forms';
import { PAGE_IDS } from '../../../constant';
import { ContextMenu, SECTION } from '../../../core/constants/menu-bar/page/page';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
})
export class MenuBarComponent implements OnInit, AfterViewInit {
  destroyRef = inject(DestroyRef);
  @Input() sheetSelected: any;
  @Output() dataExported: EventEmitter<void> = new EventEmitter<void>();
  @Output() columnFreezeToggled: EventEmitter<void> = new EventEmitter<void>();
  @Output() dataSorted: EventEmitter<void> = new EventEmitter<void>();
  @Input() currentSheetIndex!: number;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | undefined; // Access matMenuTrigger via ViewChild
  firstIncrement: boolean = true;
  @Select(SheetState.regions) regions$!: Observable<[]>;
  selectPgTab$: Observable<PgTab> = inject(Store).select(
    PgTabStore.getSelectedPgTab
  );
  currentPgId$: Observable<number> = inject(Store).select(
    PgTabStore.getCurrentPgId
  );
  currentPgId: number = 0;
  sortStatus: string = 'Sort is Off';
  filterStatus: string = 'Filter is Off';
  sessionId!: string;
  uiCurrency: string = 'US$';
  isAllPages: boolean = true;
  columns: any[] = [];
  sheetData: any[] = [];
  pageFreezeColumn: any = 0;
  filteredLanguages: string[] = [];
  selectedLanguage: {} = { language: 'English', type: 'language' };
  tooltip: any = '';
  pageMenu: boolean = false;
  isChecked: boolean = false;
  EditionCheckbox: boolean = false; // Tracks the state of the checkbox
  edition: string = '';
  refresh = UNICODE_RELOAD;
  language = UNICODE_LANGUAGE;
  globe = UNICODE_GLOBE;
  expand = UNICODE_LEFT_RIGHT_ARROW;
  frozen: any = 0;
  expandWidth: any = null;
  @Select(SheetState.getFrozen) frozen$!: Observable<any>;
  @Select(SheetState.getExpandWidth) expandWidth$!: Observable<any>;
  @Select(SheetState.getPageId) pageId$!: Observable<any>;
  @Select(SheetState.getSheetColumnsById) columns$: Observable<any> | undefined;
  private unsubscribe$ = new Subject<void>();
  @ViewChild(AutoAdjustWidthDirective)
  widthDirective!: AutoAdjustWidthDirective;
    tooltipContent: string = '';
  height: any = 0;
  width: any = 0;
  previousFontSize: number = 0;
  currentFontSize: number = 0;
  newFontSize: number = 0;
  @Input() set sheet(data: any) {
    this.sheetData = data;
  }
  menuBar = MenuBar.data;
  isEditionPg: boolean = false;
  selectedMenuBar = SelectedMenuBar;
  private destroy$ = new Subject<void>();
  isSectionExist = false;
  pageChanged$: Observable<any> = inject(Store).select(
    SheetState.getSheetDataById
  );
  constructor(
    private mainService: MainService,
    public dialog: MatDialog,
    private store: Store,
    private cdr: ChangeDetectorRef,
    public tooltipService: TooltipService,
    public fb: FormBuilder
  ) {
    this.initializeFontSize();
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.setupInitialConditions();
    this.adjustCheckboxSize();
    this.setupResizeListener();
    this.store.dispatch(new LoadMenuBar('Menu Bar'));
    this.pageChanged$.pipe(takeUntil(this.destroy$),map((res) => {
      this.isSectionExist = res?.filter((x: any) => x?.row_type === SECTION)?.length > 0;
    })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeFontSize(): void {
    this.previousFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
  }

  private initializeSubscriptions(): void {
    this.currentPgId$.subscribe((res: any) => {
      this.isAllPages =
        Number(res.page_id) || Number(res) === PAGE_IDS.ALL_PAGES
          ? true
          : false;
      this.currentPgId = typeof res === 'object' ? Number(res.page_id) : res;
    });

    this.mainService.sortStatus
      .pipe(takeUntil(this.destroy$))
      .subscribe((sortStatus) => (this.sortStatus = sortStatus));

    this.mainService.filterStatus
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterStatus) => (this.filterStatus = filterStatus));

    if (!this.isChecked) {
      const bodyElement = document.body;
      bodyElement.classList.add('notranslate');
    }
    this.tooltip = Object.values(this.selectedLanguage)[0];
    let obj1 = { pageExpand: 1 };
    this.mainService.pageFormate.next(obj1);
    this.mainService.pageFormateReg.next(obj1);
  }

  private setupInitialConditions(): void {
    if (!this.isChecked) {
      document.body.classList.add('notranslate');
    }
    this.tooltip = Object.values(this.selectedLanguage)[0] || 'Default Tooltip'; // Provide a default tooltip
  }

  private setupResizeListener(): void {
    window.addEventListener('resize', this.adjustCheckboxSize.bind(this));
  }

  private adjustCheckboxSize() {
    const bodyFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    if (bodyFontSize && this.previousFontSize) {
      this.height = bodyFontSize / this.previousFontSize - 0.2;
      this.width = bodyFontSize / this.previousFontSize - 0.2;
    } else {
      // Fallback to default sizes
      this.height = 16;
      this.width = 16;
    }
  }

  setLanguage(language: string | null) {
    const bodyElement = document.body;
    bodyElement.classList.remove('notranslate');
    this.selectedLanguage = {
      language: language || 'English',
      type: 'language',
    };
    this.tooltip = Object.values(this.selectedLanguage)[0];
    if (language) {
      this.translatePage(language);
    } else {
      this.translatePage('en');
    }
  }

  ngAfterViewInit() {
    const selectElement = document.querySelector(
      '.goog-te-combo'
    ) as HTMLSelectElement;
    if (selectElement) {
      this.filteredLanguages.forEach((lang) => {
        const optionElement = Array.from(selectElement.options).find(
          (option) => option.text === lang
        );
        if (optionElement) {
          optionElement.style.display = 'block';
        }
      });
    }
    this.edition = this.mainService?.getEdition();
    this.pageId$?.subscribe((pageId) => {
      this.isEditionPg = pageId === All_Pages_ID ? true : false;
    });
    this.setupResizeListener();
    this.cdr.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    this.pageMenu = false;
  }

  translatePage(language: string) {
    const selectElement = document.querySelector(
      '.goog-te-combo'
    ) as HTMLSelectElement;
    if (selectElement) {
      const langValue = this.getLanguageValue(language);
      selectElement.value = langValue;
      selectElement.dispatchEvent(new Event('change'));
    }
  }

  getLanguageValue(language: string): string {
    const languageMap: { [key: string]: string } =
      this.mainService.languageListKeys;
    return languageMap[language] || 'en';
  }

  getAllGoogleLanguages(): string[] {
    return this.mainService.languageList;
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault(); // Prevent the default right-click menu

    if (this.menuTrigger) {
      this.menuTrigger.openMenu(); // Open the context menu
    }
  }

  handleCheckboxChange(event: any) {
    this.EditionCheckbox = event.target.checked;
    this.mainService.handleCheckboxChange(event);
  }

  setCurrency(currency: string) {
    this.uiCurrency = currency;
  }

  toggleAutoTranslate(event: Event) {
    if (this.isChecked) {
      const bodyElement = document.body;
      bodyElement.classList.remove('notranslate');
      const selectElement = document.querySelector(
        '.goog-te-combo'
      ) as HTMLSelectElement;
      // Check if the element is found and is an HTMLSelectElement
      if (selectElement) {
        // Get the selected value
        const selectedLanguage = selectElement.value;
        // Output the selected value to the console (or use it as needed)
        Object.values(this.mainService.languageListKeys).map((code, index) => {
          if (selectedLanguage == code) {
            this.tooltip = Object.keys(this.mainService.languageListKeys)[
              index
            ];
          }
        });
      } else {
        console.error('Select element not found.');
      }
    }
  }

  async handleDialog<T>(
    dialogData: any,
    selectedId: string,
    updateMethod: (rowSelected: T) => void
  ): Promise<void> {
    await this.subscribeToData();

    const dialogRef = this.dialog.open(editDdDialogMainComponent, {
      width: 'auto',
      panelClass: 'pick-ddl',
      data: {
        data: { frozen: this.frozen, expandWidth: this.expandWidth },
        token: selectedId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const rowSelected =
          result[
            Object.keys(result).find((key) =>
              key.endsWith('RowSelected')
            ) as keyof DDResponse
          ];

        if (rowSelected) {
          updateMethod(rowSelected as T);
        } else {
          this.tooltipContent = `Current ${selectedId} : Null`;
          this.tooltipService.triggerTooltip(this.tooltipContent);
          this.selectedMenuBar[
            `current${selectedId}` as keyof typeof SelectedMenuBar
          ] = this.tooltipContent;
        }

        this.resetFreezeSettings();
      }
    });
  }

  async subscribeToData(): Promise<void> {
    this.frozen$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => (this.frozen = data));
    this.expandWidth$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => (this.expandWidth = data));
  }

  private resetFreezeSettings(): void {
    let obj3 = { pageFreezeColumn: 0 };
    this.mainService.pageFormateFreeze.next(obj3);
    this.store.dispatch(new Sheet.SetFrozen(99));
    this.mainService.pageFormateFreeze.next({ pageFreezeColumn: 0 });
  }

  async showCountryRegion(): Promise<void> {
    await this.handleDialog<RowRegionData>(
      'Region',
      DDL_Regions,
      this.updateSelectRegion.bind(this)
    );
  }

  async showLanguageDialog(): Promise<void> {
    await this.handleDialog<RowLanguageData>(
      'Language',
      DDL_Languages,
      this.updateSelectLanguage.bind(this)
    );
  }

  async showCurrencyDialog(): Promise<void> {
    await this.handleDialog<RowCurrencyData>(
      'Currency',
      DDL_Currencies,
      this.updateSelectCurrency.bind(this)
    );
  }

  async showEditionDialog(): Promise<void> {
    await this.handleDialog<RowPageData>(
      'Edition',
      DDL_Page_Edition,
      (rowSelected) => {
        this.store.dispatch(
          new AddPgTab({
            page_id: rowSelected.page_id,
            page_name: rowSelected.page_name,
            page_edition: rowSelected.page_edition,
            page_comment: rowSelected.page_comment,
          })
        );
      }
    );
  }

  async showPageDialog(): Promise<void> {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu(); // Open the context menu
    }
    await this.handleDialog<RowPageData>(
      'Page',
      DDL_All_Pages,
      (rowSelected) => {
        this.store.dispatch(
          new AddPgTab({
            page_id: rowSelected.page_id,
            page_name: rowSelected.page_name,
            page_edition: rowSelected.page_edition,
            page_comment: rowSelected.page_comment,
          })
        );
      }
    );
  }

  async showViewDialog(): Promise<void> {
    await this.handleDialog<any[]>(
      'View',
      DDL_Page_Modes,
      this.updateSelectView.bind(this)
    );
  }

  updateSelectRegion(rowSelected: RowRegionData): void {
    this.tooltipContent = this.formatTooltipContent(
      'Region',
      rowSelected.region
    );
    this.updateMenuBar('Region', this.tooltipContent);
  }

  updateSelectLanguage(rowSelected: RowLanguageData): void {
    this.tooltipContent = this.formatTooltipContent(
      'Language',
      rowSelected.language
    );
    this.updateMenuBar('Language', this.tooltipContent);
  }

  updateSelectCurrency(rowSelected: RowCurrencyData): void {
    this.tooltipContent = this.formatTooltipContent(
      'Currency',
      rowSelected.unit
    );
    this.updateMenuBar('Currency', this.tooltipContent);
    this.setCurrency(rowSelected.unit);
  }

  updateSelectView(parentRows: any[]): void {
    if (parentRows?.length) {
      const lastPage = parentRows.pop().page;
      const parentPage = parentRows
        .map((row) => `<u>${row.page}</u>`)
        .join(' <span class="unicode-separator">\u220B</span> ');
      this.tooltipContent = `Current Page Mode: ${lastPage} (${parentPage})`;
      this.updateMenuBar('Page', this.tooltipContent);
    }
  }

  private formatTooltipContent(type: string, data: string | string[]): string {
    if (Array.isArray(data)) {
      return `Current ${type}: ${data
        .map((item) => `<u>${item}</u>`)
        .join(' <span class="unicode-separator">\u220B</span> ')}`;
    }
    return `Current ${type}: ${data}`;
  }

  private updateMenuBar(type: string, content: string): void {
    this.selectedMenuBar[
      `current${type}` as keyof typeof this.selectedMenuBar
    ] = content;
    this.tooltipService.triggerTooltip(content);
  }

  toggleSort() {
    this.mainService.toggleSort();
  }

  toggleFilter() {
    this.mainService.toggleFilter();
  }

  openColDialog() {
    const dialogRef = this.dialog.open(PGColDialogComponent, {
      width: '750px',
      data: { selectedId: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  selectedUserOption: User | null = null;
  openUserDialog(): void {
    const dialogRef = this.dialog.open(PGUserDialogComponent, {
      width: '400px',
      data: { selectedUserOption: this.selectedUserOption },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedUserOption = result;
      }
    });
  }

  /**
   * Opens the FormatePageComponent dialog to format the page.
   * Once the dialog is closed, if a result is returned:
   */
  formatPage(): void {
    // Open the dialog and pass the title as data
    const dialogRef = this.dialog.open(FormatPageComponent, {
      data: {
        title: `Format: Page (ID: ${this.currentPgId})`,
        objectId: this.currentPgId,
        context: ContextMenu.FormatPage,
      },
    });

    // Handle the dialog close event
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return; // Exit if no result is returned

      // Dispatch the result to the store to update font style
      this.store.dispatch(new SetFontStyle(result));

      // Update local property: pageFreezeColumn
      this.pageFreezeColumn = result.pageFreezeColumn;

      // Notify other components via a service
      this.mainService.pageFormate.next(result);
    });
  }

  deletePage() {
    if (!this.isAllPages) {
      this.menuTrigger?.closeMenu();

      const dialogRef = this.dialog.open(DialogDeleteComponent, {
        data: {
          title: `Delete: Page (ID: ${this.currentPgId})`,
          pgId: this.currentPgId,
          data: "shall be Deleted? Click 'Delete' to confirm ",
          name: ObjectType.DataObject.Page,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  viewPage() {
    const dialogRef = this.dialog.open(DialogViewComponent, {
      data: {
        clicked: 'Page',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
      }
    });
  }
}
