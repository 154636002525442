@if(pgTabs$ | async; as pgTabs){
  <div class="d-flex flex-nowrap tabs-container">
    @for (tab of pgTabs; track tab; let i = $index) {
      @let text = 'Page Name: ' + tab.page_name + '\n' + 'Page Edition: ' + (tab?.page_edition || 'N/A') + '\n' + 'Page Comment: ' + (tab?.page_comment || 'N/A' );
      <div
        [ngClass]="{ 'ecllipses' : i >= 14, 'active flex-shrink-0' : i === currentSheetIndex}"
        class="chip flex-shrink-0-1-auto"
        [appTooltip]="text"
        [name]="tab.page_name"
        (mouseenter)="tooltipService.triggerTooltipFor(tab.page_name)"
        tabindex="{{i}}"
        (keydown.Shift.Tab)="onShiftTabKeyPress($event,tab, i)"
        (keydown.Tab)="onKeyDown($event,tab, i)"
        (click)="onSelectPgTab(tab, i)">
        <span>{{ tab | pageName }}<sup class="cross" [innerHTML]="cross_icon" (click)="deleteSheet(i)"></sup></span>
      </div>
    }
    @if(pgTabs.length <= 50 && !(isLoading$ | async)){
      <div
        class="flex-shrink-0"
        (click)="addSheet(pgTabs)"
        [innerHTML]="add_icon">
      </div>
    }
  </div>
}
