import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appControlShift]',
})
export class ControlShiftDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    // Query input elements within the directive's host element
    const beforeInput = this.el.nativeElement.querySelector('.before-input') as HTMLInputElement;
    const afterInput = this.el.nativeElement.querySelector('.after-input') as HTMLInputElement;

    // Navigation logic
    if (event.key === 'ArrowRight' && document.activeElement === beforeInput) {
      afterInput?.focus(); // Focus next input if it exists
      event.preventDefault();
    } else if (event.key === 'ArrowLeft' && document.activeElement === afterInput) {
      beforeInput?.focus(); // Focus previous input if it exists
      event.preventDefault();
    }
  }
}
