import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MainService } from '../../../core/services/main-service/main.service';
import { SECTION } from '../../../core/constants/menu-bar/page/page';
import { ARROW_UP_DOWN } from '../../../core/constants/app.contants';
import { COL_STATUSES } from '../../../constant';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { SheetState } from '../../../store/page/page.store';

interface Column {
  status: string[]; // to maintain the status of the column
}

@Component({
  selector: 'app-freeze-field',
  templateUrl: './freeze-field.component.html',
  styleUrls: ['./freeze-field.component.scss'],
})
export class FreezeFieldComponent implements OnDestroy, OnInit {
  destroyRef = inject(DestroyRef);

  @Input() columns: any[] = [];
  @Input() context: 'menu-bar' | 'edit-dd-dialog' = 'menu-bar'; // Context to differentiate between menu-bar and edit-dd-dialog
  ddiChanged$: Observable<any> = inject(Store).select(SheetState.PickDdiData);
  pageChanged$: Observable<any> = inject(Store).select(
    SheetState.getSheetDataById
  );
  arrow_up_down = ARROW_UP_DOWN;
  pageFreezeColumn: any = 0;
  private destroy$ = new Subject<void>();
  isSectionExist = false;

  constructor(private mainService: MainService) {}

  ngOnInit(): void {
    if (this.context === 'menu-bar') {
      // Subscribe to page change event
      this.pageChanged$.pipe(takeUntil(this.destroy$),map((res) => {
            this.isSectionExist = res?.filter((x: any) => x?.row_type === SECTION)?.length > 0;
            return res;
          })
        ).subscribe(() => {
          this.resetFreezeColumn();
        });
    } else if (this.context === 'edit-dd-dialog') {
      // Subscribe to page change event
      this.ddiChanged$.pipe(takeUntil(this.destroy$),map((res) => {
            return res;
          })
        ).subscribe(() => {
          this.resetFreezeColumn();
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private resetFreezeColumn(): void {
    this.pageFreezeColumn = 0;
    this.updateFreezeColumn(this.pageFreezeColumn);
  }

  incrementValue(): void {
    this.updateFreezeColumn(this.pageFreezeColumn + 1);
  }

  decrementValue(): void {
    this.updateFreezeColumn(this.pageFreezeColumn - 1);
  }

  toggleFreeze(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;

    // Filter out hidden columns based on context
    const visibleColumns = this.columns.filter((column: Column) => {
      if (this.context === 'menu-bar') {
        return !column.status.includes('Hidden');
      } else if (this.context === 'edit-dd-dialog') {
        return column.status.includes(COL_STATUSES.DDL_COL);
      }
      return false;
    });

    const columnCount = visibleColumns.length; // Access the visible columns length

    if (target.classList.contains('inc') && this.pageFreezeColumn + 1 < columnCount) {
      this.incrementValue();
    } else if (target.classList.contains('dec') && this.pageFreezeColumn > 0) {
      this.decrementValue();
    }
  }

  private updateFreezeColumn(value: number): void {
    this.pageFreezeColumn = Math.max(0, value);
    const update = { pageFreezeColumn: this.pageFreezeColumn };
    if (this.context === 'menu-bar') {
      this.mainService.pageFormate.next(update);
    } else if (this.context === 'edit-dd-dialog') {
      this.mainService.pageFormateFreeze.next(update);
    }
  }
}
