import { AddItemPayload } from "../../shared/models/item/item.model";

export enum ActionTypes {
  ADD_ITEM_IN_ROW = '[ITEM] Add Item In Row',
  ADD_ITEM_IN_ROW_SUCCESS = '[ITEM] Add Item In Row Success',
  ADD_ITEM_IN_ROW_FAILURE = '[ITEM] Add Item In Row Failure',

  EDIT_ITEM_IN_ROW = '[ITEM] Edit Item In Row',
  EDIT_ITEM_IN_ROW_SUCCESS = '[ITEM] Edit Item In Row Success',
  EDIT_ITEM_IN_ROW_FAILURE = '[ITEM] Edit Item In Row Failure',
}
export class GetItemDropdown {
  static readonly type = '[Item] GetItemDropdown';
  constructor(public payload: any) {}
}

export class UpdateItemThroughDropdown {
  static readonly type = '[Item] UpdateItemThroughDropdown';
  constructor(public payload: any) {}
}

export class AddItemThroughDropdown {
  static readonly type = '[Item] AddItemThroughDropdown';
  constructor(public payload: any) {}
}

export class EditItemData {
  static readonly type = '[Item] EditItemData';
  constructor(public payload: any) {}
}


export class AddItemDataInRow {
  static readonly type = ActionTypes.ADD_ITEM_IN_ROW;
  constructor(public payload: AddItemPayload, public text? : string) {}
}

export class AddItemDataInRowSuccess {
  static readonly type = ActionTypes.ADD_ITEM_IN_ROW_SUCCESS;
  constructor(public payload: any) {}
}

export class AddItemDataInRowFailure {
  static readonly type = ActionTypes.ADD_ITEM_IN_ROW_FAILURE;
  constructor(public error: any) {}
}

export class EditItemDataInRow {
  static readonly type = ActionTypes.EDIT_ITEM_IN_ROW;
  constructor(public payload: AddItemPayload, public text? : string) {}
}

export class EditItemDataInRowSuccess {
  static readonly type = ActionTypes.EDIT_ITEM_IN_ROW_SUCCESS;
  constructor(public payload: any) {}
}


export class EditItemDataInRowFailure {
  static readonly type = ActionTypes.EDIT_ITEM_IN_ROW_FAILURE;
  constructor(public error: any) {}
}
