import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appDynamicInputWidth]',
})
export class DynamicInputWidthDirective implements OnChanges, OnInit {
  @Input() inputValue: number | null = null; // Bind to the [value] property as a number

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    if (!/^\d$/.test(input.value)) {
      this.inputValue = 1
      this.updateWidth(this.inputValue);
      input.value = input.value.slice(0, 1);
    }
  }

  ngOnInit(): void {
    this.updateWidth(this.inputValue);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputValue']) {
      this.updateWidth(this.inputValue);
    }
  }

  private updateWidth(input: number | null): void {
    let width = '0.2em'; // Default width for 1 digit

    if (input !== null && !isNaN(input)) {
      // Convert number to string to calculate its length (account for decimals)
      const inputAsString = input.toString();
      const numLength = inputAsString.replace('.', '').length; // Exclude the decimal point
      width = `${0.6 + 0.6 * (1 - 1)}em`;
    }

    // Apply the calculated width directly as inline CSS
    this.renderer.setStyle(this.el.nativeElement, 'width', width);
  }
}
