import { DestroyRef, inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { RowStateModel } from "./row.model";
import { RowState } from "./row.state";
import { AddChildRow, AddChildRowFailure, AddChildRowSuccess, AddChildSystemRow, AddChildSystemRowFailure, AddChildSystemRowSuccess, AddNextRow, AddNextRowFailure, AddNextRowSuccess, AddNextSystemRow, AddNextSystemRowFailure, AddNextSystemRowSuccess, AddPrevRow, AddPrevRowFailure, AddPrevRowSuccess, AddPrevSystemRow, AddPrevSystemRowFailure, AddPrevSystemRowSuccess } from "./row.action";
import {  last, tap } from "rxjs";
import { FormatResponse } from "../../core/interfaces/response.interface";
import { PgTabStore } from "../pg-tab/pg-tab.store";
import { MainService } from "../../core/services/main-service/main.service";
import { ContextActions } from "../../core/constants/menu-bar/page/page";
import { SheetState } from "../page/page.store";
import { Sheet } from "../page/page.actions";
import { RowService } from "../../core/services/row.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RowPageData } from "../../shared/models/edit-dd-dailogs/dd-dailog.models";

@State<RowStateModel>({
  name: 'pgRow',
  defaults: RowState,
})

@Injectable()
export class PgRowStore {
  destroyRef = inject(DestroyRef);
  constructor(
    private tRowService : RowService,
    private store : Store,
    private mainService : MainService
  ) {}

  @Action(AddNextRow)
  AddNextRow(ctx: StateContext<RowStateModel>, action: AddNextRow) {
    ctx.patchState({
      isLoading : true,
    })
   return this.tRowService.addPgRow(action.data.row).pipe(
      takeUntilDestroyed(this.destroyRef),
      last(),
      tap((row : FormatResponse<any>) => {
        if(row.success){
        let data : any = {
          ...row.data.createdRow,
          Pg : row.data.createdPage.Pg
        };
         return ctx.dispatch(new AddNextRowSuccess({
          row : data,
          prevRowId : action.data.prevRowId ? Number(action.data.prevRowId) : null,
          nextRowId : action.data.nextRowId ? Number(action.data.nextRowId) : null,
          currentRowId : action.data.currentRowId ? Number(action.data.currentRowId) : null,
          }));
        }else{
          return ctx.dispatch(new AddNextRowFailure(row.error));
        }
      }),
    )
  }

  @Action(AddNextRowSuccess)
  AddNextRowSuccess(ctx: StateContext<RowStateModel>, action: AddNextRowSuccess) {
    const getCurrentPgId = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as RowPageData;
    const pgId = typeof getCurrentPgId === 'object' ? Number(getCurrentPgId.page_id) : getCurrentPgId;
    const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const updatedTabsData = this.mainService.addNewRow(
      pgId,
      action.data.row,
      allTabsData,
      action.data.currentRowId,
      ContextActions.ADD_NEXT_ROW
    )
    ctx.patchState({
      isLoading : false,
    })
    this.store.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: updatedTabsData,
        allTabsFlatData: allTabsFlatData,
        defaultPageId : pgId
      })
    );
  }

  @Action(AddNextRowFailure)
  AddNextRowFailure(ctx: StateContext<RowStateModel>, { error }: AddNextRowFailure) {
    ctx.patchState({
      error : error,
      isLoading : false,
    })
  }

  @Action(AddPrevRow)
    AddPrevRow(ctx: StateContext<RowStateModel>, action: AddPrevRow) {
    ctx.patchState({
      isLoading : true,
    })
    return this.tRowService.addPgRow(action.data.row).pipe(
      takeUntilDestroyed(this.destroyRef),
      last(),
      tap((row : FormatResponse<any>) => {
        if(row.success){
        let data : any = {
          ...row.data.createdRow,
          Pg : row.data.createdPage.Pg
        };

        return ctx.dispatch(new AddPrevRowSuccess({
          row : data,
          prevRowId : action.data.prevRowId ? Number(action.data.prevRowId) : null,
          nextRowId : action.data.nextRowId ? Number(action.data.nextRowId) : null,
          currentRowId : action.data.currentRowId ? Number(action.data.currentRowId) : null,
          }));
        }else{
          return ctx.dispatch(new AddPrevRowFailure(row.error));
        }
      }),
    )
  }

  @Action(AddPrevRowSuccess)
  AddPrevRowSuccess(ctx: StateContext<RowStateModel>, action: AddPrevRowSuccess) {
     const getCurrentPgId = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as RowPageData;
      const pgId = typeof getCurrentPgId === 'object' ? Number(getCurrentPgId.page_id) : getCurrentPgId;
      const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
      const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
      const updatedTabsData = this.mainService.addNewRow(
        pgId,
        action.data.row,
        allTabsData,
        action.data.currentRowId,
        ContextActions.ADD_PREV_ROW
      )
      ctx.patchState({
        isLoading : false,
      })
      this.store.dispatch(
        new Sheet.UpdatePageData({
          allTabsData: updatedTabsData,
          allTabsFlatData: allTabsFlatData,
          defaultPageId : pgId
        })
      );
  }


  @Action(AddPrevRowFailure)
  AddPrevRowFailure(ctx: StateContext<RowStateModel>, { error }: AddPrevRowFailure) {
    ctx.patchState({
      error : error,
      isLoading : false,
    })
  }

  @Action(AddChildRow)
  AddChildRow(ctx: StateContext<RowStateModel>, action: AddChildRow) {
    ctx.patchState({
      isLoading : true,
    })
    return this.tRowService.addPgRow(action.data.row).pipe(
      takeUntilDestroyed(this.destroyRef),
      last(),
      tap((row : FormatResponse<any>) => {
        if(row.success){
        let data : any = {
          ...row.data.createdRow,
          Pg : row.data.createdPage.Pg
        };
        return ctx.dispatch(new AddChildRowSuccess({
          row : data,
          prevRowId : action.data.prevRowId ? Number(action.data.prevRowId) : null,
          nextRowId : action.data.nextRowId ? Number(action.data.nextRowId) : null,
          currentRowId : action.data.currentRowId ? Number(action.data.currentRowId) : null,
          }));
        }else{
          return ctx.dispatch(new AddChildRowFailure(row.error));
        }
      }),
    )

  }

  @Action(AddChildRowSuccess)
  AddChildRowSuccess(ctx: StateContext<RowStateModel>, action: AddChildRowSuccess) {
    const getCurrentPgId = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as RowPageData;
    const pgId = typeof getCurrentPgId === 'object' ? Number(getCurrentPgId.page_id) : getCurrentPgId;
    const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const updatedTabsData = this.mainService.addNewRow(
      pgId,
      action.data.row,
      allTabsData,
      action.data.currentRowId,
      ContextActions.ADD_CHILD_ROW
    )
    ctx.patchState({
      isLoading : false,
    })
    this.store.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: updatedTabsData,
        allTabsFlatData: allTabsFlatData,
        defaultPageId : pgId
      })
    );
  }

  @Action(AddChildRowFailure)
  AddChildRowFailure(ctx: StateContext<RowStateModel>, { error }: AddChildRowFailure) {
  ctx.patchState({
    error : error,
    isLoading : false,
  })
  }

  @Action(AddNextSystemRow)
  AddNextSystemRow(ctx: StateContext<RowStateModel>, action: AddNextSystemRow) {
  ctx.patchState({
    isLoading : true,
  })
  return this.tRowService.addSystemPgRow(action.data.row).pipe(
    takeUntilDestroyed(this.destroyRef),
    last(),
    tap((row : FormatResponse<any>) => {
      if(row.success){
        let data = row.data.createdRow;
        return ctx.dispatch(new AddNextSystemRowSuccess({
        row : data,
        prevRowId : action.data.prevRowId ? Number(action.data.prevRowId) : null,
        nextRowId : action.data.nextRowId ? Number(action.data.nextRowId) : null,
        currentRowId : action.data.currentRowId ? Number(action.data.currentRowId) : null,
        }));
      }else{
        return ctx.dispatch(new AddNextSystemRowFailure(row.error));
      }
    }),
  )
  }

  @Action(AddNextSystemRowSuccess)
  AddNextSystemRowSuccess(ctx: StateContext<RowStateModel>, action: AddNextSystemRowSuccess) {
  const getCurrentPgId = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as RowPageData;
  const pgId = typeof getCurrentPgId === 'object' ? Number(getCurrentPgId.page_id) : getCurrentPgId;
  const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
  const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
  const updatedTabsData = this.mainService.addNewRow(
    pgId,
    action.data.row,
    allTabsData,
    action.data.currentRowId,
    ContextActions.ADD_NEXT_ROW
  )
  ctx.patchState({
    isLoading : false,
  })
  this.store.dispatch(
    new Sheet.UpdatePageData({
      allTabsData: updatedTabsData,
      allTabsFlatData: allTabsFlatData,
      defaultPageId : pgId
    })
  );
  }

  @Action(AddNextSystemRowFailure)
  AddNextSystemRowFailure(ctx: StateContext<RowStateModel>, { error }: AddNextSystemRowFailure) {
  ctx.patchState({
    error : error,
    isLoading : false,
  })
  }

  @Action(AddPrevSystemRow)
  AddPrevSystemRow(ctx: StateContext<RowStateModel>, action: AddPrevSystemRow) {
  ctx.patchState({
    isLoading : true,
  })
  return this.tRowService.addSystemPgRow(action.data.row).pipe(
  takeUntilDestroyed(this.destroyRef),
  last(),
  tap((row : FormatResponse<any>) => {
    if(row.success){
      let data = row.data.createdRow;
      return ctx.dispatch(new AddPrevSystemRowSuccess({
      row : data,
      prevRowId : action.data.prevRowId ? Number(action.data.prevRowId) : null,
      nextRowId : action.data.nextRowId ? Number(action.data.nextRowId) : null,
      currentRowId : action.data.currentRowId ? Number(action.data.currentRowId) : null,
      }));
    }else{
      return ctx.dispatch(new AddPrevSystemRowFailure(row.error));
    }
  }),
  )
  }

  @Action(AddPrevSystemRowSuccess)
  AddPrevSystemRowSuccess(ctx: StateContext<RowStateModel>, action: AddPrevSystemRowSuccess) {
  const getCurrentPgId = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as RowPageData;
  const pgId = typeof getCurrentPgId === 'object' ? Number(getCurrentPgId.page_id) : getCurrentPgId;
  const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
  const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
  const updatedTabsData = this.mainService.addNewRow(
    pgId,
    action.data.row,
    allTabsData,
    action.data.currentRowId,
    ContextActions.ADD_PREV_ROW
  )
  ctx.patchState({
    isLoading : false,
  })
  this.store.dispatch(
    new Sheet.UpdatePageData({
      allTabsData: updatedTabsData,
      allTabsFlatData: allTabsFlatData,
      defaultPageId : pgId
    })
  );
  }


  @Action(AddPrevSystemRowFailure)
  AddPrevSystemRowFailure(ctx: StateContext<RowStateModel>, { error }: AddPrevSystemRowFailure) {
  ctx.patchState({
  error : error,
  isLoading : false,
  })
  }

  @Action(AddChildSystemRow)
  AddChildSystemRow(ctx: StateContext<RowStateModel>, action: AddChildSystemRow) {
  ctx.patchState({
  isLoading : true,
  })
  return this.tRowService.addSystemPgRow(action.data.row).pipe(
  takeUntilDestroyed(this.destroyRef),
  last(),
  tap((row : FormatResponse<any>) => {
    if(row.success){
      let data = row.data.createdRow;
      return ctx.dispatch(new AddChildSystemRowSuccess({
      row : data,
      prevRowId : action.data.prevRowId ? Number(action.data.prevRowId) : null,
      nextRowId : action.data.nextRowId ? Number(action.data.nextRowId) : null,
      currentRowId : action.data.currentRowId ? Number(action.data.currentRowId) : null,
      }));
    }else{
      return ctx.dispatch(new AddChildSystemRowFailure(row.error));
    }
  }),
  )

  }

  @Action(AddChildSystemRowSuccess)
  AddChildSystemRowSuccess(ctx: StateContext<RowStateModel>, action: AddChildSystemRowSuccess) {
  const getCurrentPgId = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as RowPageData;
  const pgId = typeof getCurrentPgId === 'object' ? Number(getCurrentPgId.page_id) : getCurrentPgId;
  const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
  const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
  const updatedTabsData = this.mainService.addNewRow(
  pgId,
  action.data.row,
  allTabsData,
  action.data.currentRowId,
  ContextActions.ADD_CHILD_ROW
  )
  ctx.patchState({
  isLoading : false,
  })
  this.store.dispatch(
  new Sheet.UpdatePageData({
    allTabsData: updatedTabsData,
    allTabsFlatData: allTabsFlatData,
    defaultPageId : pgId
  })
  );
  }

  @Action(AddChildSystemRowFailure)
  AddChildSystemRowFailure(ctx: StateContext<RowStateModel>, { error }: AddChildSystemRowFailure) {
    ctx.patchState({
    error : error,
    isLoading : false,
    })
  }

  @Selector()
  static isRowLoading(state: RowStateModel): boolean {
    return state.isLoading;
  }
}

