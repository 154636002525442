export const UNICODE_RELOAD = '\u21BB';
export const UNICODE_LANGUAGE = '\u6587';
export const UNICODE_GLOBE = '\uD83C\uDF10';
export const UNICODE_LEFT_RIGHT_ARROW = '\u2B0D';
export const UNICODE_MULTIPLY = '\u00D7';
export const UNICODE_PLUS = '\uFF0B';
export const UNICODE_CIRCLE = '\u25EF';
export const UNICODE_DASH = '\u2212';
export const UNICODE_PLUS_CODE = '\u002B';
export const UNICODE_UP_ARROW = '\u2BC5';
export const UNICODE_DOWN_ARROW = '\u2BC6';
export const ASCII_SPACE = '&nbsp;';
export const ASCII_PLUS = '&#43;';
export const ASCII_DASH = '&#45;';
export const ARROW_UP_DOWN = '\u2191\u2193';

export const All_Pages_ID = '1000000010';
export const DDL_All_Pages = 'All Pages';
export const DDL_All_DDL_Pages = 'All DDL Pages';
export const DDL_Languages = 'All Languages';
export const DDL_Regions = 'All Regions';
export const Countries = 'Countries';
export const DDL_Currencies = 'Currencies';
export const DDL_Statuses = 'Statuses';
export const DDL_Page_Edition = 'Page Editions';
export const DDL_Page_Modes = 'Page Modes';

export const COL_IDS = {
  COL_COMMENT: 2000000610,
  PAGE_COMMENT: 2000000430,
  ROW_COMMENT: 2000000060,
  COL_STATUS: 2000000590,
  ROW_STATUS: 2000000050
}

export const FORMAT_FORM = {
  Format: 'Format',
  User: 'User',
  PgId: 'PgId',
  PgNestedCol: 'PgNestedCol',
  PgFreezCol: 'PgFreezeCol',
  PgExpand: 'PgExpand',
  PgSort: 'PgSort',
  PgFilter: 'PgFilter',
  PgLevelSet: 'PgLevelSet',
  PgSearchSet: 'PgSearchSet',
  ColId: 'ColId',
  RowId: 'RowId',
  CellId: 'CellId',
  Default: 'Default',
  CellDDL: 'CellDDL',
  ObjectType: 'ObjectType',
  ItemId: 'ItemId',
  Status: 'Status',
  Formula: 'Formula',
  FontStyle: 'FontStyle',
  ColMinWidth: 'ColMinWidth',
  Comment: 'Comment',
  CommentObject: 'CommentObject',
  TxList: 'TxList',
};

export const PAGE_FORMAT = [
  {
    label: 'Format ID',
    name: FORMAT_FORM.Format,
    readonly: true,
    pattern: /[0-9]+/,
  },
  {
    label: 'Format User',
    name: FORMAT_FORM.User,
    pattern: /[0-9]+/,
  },
  {
    label: 'Page ID',
    name: FORMAT_FORM.PgId,
    readonly: true,
    pattern: /[0-9]+/,
  },
  {
    label: 'Page Nested-Column',
    name: FORMAT_FORM.PgNestedCol,
    clickAction: true,
  },
  {
    label: 'Page Freeze-Column(s)',
    name: FORMAT_FORM.PgFreezCol,
    pattern: /^\d+$/,
  },
  {
    label: 'Page Expand-Level',
    name: FORMAT_FORM.PgExpand,
    pattern: /^[0-9]+$/,
  },
  { label: 'Page Sort-Formula', name: FORMAT_FORM.PgSort },
  { label: 'Page Filter-Formula', name: FORMAT_FORM.PgFilter },
  { label: 'Page LevelSet', name: FORMAT_FORM.PgLevelSet, pattern: /^\d+$/ },
  { label: 'Page SearchSet', name: FORMAT_FORM.PgSearchSet, pattern: /^\d+$/ },
  { label: 'Page FontStyle', name: FORMAT_FORM.FontStyle, clickAction: true },
  { label: 'Page Comment', name: FORMAT_FORM.Comment, clickAction: true },
  { label: 'Page Transactions', name: FORMAT_FORM.TxList, pattern: /\d+/ },
];

export const FORMAT_LOCAL_COL = [
  {
    label: 'Format ID',
    name: FORMAT_FORM.Format,
    readonly: true,
    pattern: /[0-9]+/,
  },
  {
    label: 'Format User',
    name: FORMAT_FORM.User,
    pattern: /[0-9]+/,
  },
  {
    label: 'Local-Column ID',
    name: FORMAT_FORM.ColId,
    readonly: true,
    pattern: /[0-9]+/,
  },
  { label: 'Column Status', name: FORMAT_FORM.Status },
  { label: 'Column Min-Width', name: FORMAT_FORM.ColMinWidth },
  { label: 'Column FontStyle', name: FORMAT_FORM.FontStyle, clickAction: true },
  { label: 'Column Comment', name: FORMAT_FORM.Comment, clickAction: true },
  { label: 'Column Transactions', name: FORMAT_FORM.TxList, readonly: true, pattern: /\d+/ },
];

export const FORMAT_LOCAL_ROW = [
  {label: 'Format ID', name: FORMAT_FORM.Format, readonly: true},
  { label: 'Format User', name: FORMAT_FORM.User, readonly: true },
  { label: 'Local-Row ID', name: FORMAT_FORM.RowId, readonly: true},
  { label: 'Row Status', name: FORMAT_FORM.Status },
  { label: 'Row FontStyle', name: FORMAT_FORM.FontStyle, clickAction: true },
  { label: 'Row Comment', name: FORMAT_FORM.Comment, clickAction: true },
  { label: 'Row Transactions', name: FORMAT_FORM.TxList, readonly: true },
];

export const FORMAT_LOCAL_CELL = [
  { label: 'Format ID', name: FORMAT_FORM.Format, readonly: true, pattern: /[0-9]+/ },
  { label: 'Format User', name: FORMAT_FORM.User, readonly: true, pattern: /[0-9]+/ },
  { label: 'Local-Cell ID', name: FORMAT_FORM.CellId, readonly: true, pattern: /[0-9]+/},
  { label: 'Cell DataType', name: FORMAT_FORM.ObjectType, pattern: /[0-9]+/},
  { label: 'Cell DDL', name: FORMAT_FORM.CellDDL},
  { label: 'Cell DefaultData', name: FORMAT_FORM.Default},
  { label: 'Cell Status', name: FORMAT_FORM.Status },
  { label: 'Cell Formula', name: FORMAT_FORM.Formula },
  { label: 'Cell FontStyle', name: FORMAT_FORM.FontStyle, clickAction: true },
  { label: 'Cell Comment', name: FORMAT_FORM.Comment, clickAction: true },
  { label: 'Cell Transactions', name: FORMAT_FORM.TxList, readonly: true },
];

export const FORMAT_LOCAL_ITEM = [
  {
    label: 'Format ID',
    name: FORMAT_FORM.Format,
    readonly: true,
    pattern: /[0-9]+/,
  },
  {
    label: 'Format User',
    name: FORMAT_FORM.User,
    pattern: /[0-9]+/,
  },
  {
    label: 'Local-Item ID',
    name: FORMAT_FORM.ItemId,
    readonly: true,
    required: true,
    pattern: /[0-9]+/,
  },
  { label: 'Item Status', name: FORMAT_FORM.Status },
  { label: 'Item Formula', name: FORMAT_FORM.Formula },
  { label: 'Item FontStyle', name: FORMAT_FORM.FontStyle, clickAction: true },
  { label: 'Item Comment', name: FORMAT_FORM.Comment, clickAction: true },
  { label: 'Item Transactions', name: FORMAT_FORM.TxList, readonly: true, pattern: /\d+/ },
];

export const ObjectType = {
  DataObject: {
    Page: 'Page',
    Column: 'Column',
    Row: 'Row',
    Cell: 'Cell',
    Item: 'Item',
  },
};

export const ASCII_CROSS_ICON = '&#120;';
export const UNICODE_CROSS_ICON = '\u{1F5D9}';
export const UNICODE_ADD_ICON = '&#8853';
export const ASCII_EXPAND_ICON = '&#x25E2;';
export const UNICODE_EXPAND_ICON = '&#9698;';
export const ENGLISH = '3000000100';

export const FontStyle = {
  Normal: 'normal',
  Italic: 'italic'
}
export const FontWeight = {
  Bold: 'bold'
}
export const TextDecoration = {
  LineThrough: 'line-through',
  Underline: 'underline'
}
export const TextAlign = {
  Left: 'left',
  Rigth: 'right',
  Justify: 'justify'
}
export const FONT_STYLE = {
  AsianFontFamily: 'asian-font-family',
  FontStyle: 'font-style',
  FontWeight: 'font-weight',
  FontSize: 'font-size',
  FontFamily: 'font-family',
  FontColor: 'color',
  BackgroundColor: 'background-color',
  TextAlign: 'text-align',
  TextDecoration: 'text-decoration',
  TextWrap: 'text-wrap',
  Border: 'border',
  Effects: {
    LineThrough: TextDecoration.LineThrough,
    Underline: TextDecoration.Underline,
    Superscript: 'sup',
    Subscript: 'sub',
    Italic: FontStyle.Italic,
    Bold: FontWeight.Bold,
    TextWrap: 'wrap',
    Border: '1px solid'
  }
}
