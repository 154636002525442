export enum ActionTypes {
  SET_APP_IS_LOADING = '[APP] Set Application Is Loading',
  SET_APP_HAS_ERROR = '[APP] Set Application Has Error',

  GET_ALL_COLS_DATATYPES = '[App] Get All Cols DataTypes',
  GET_ALL_COLS_DATATYPES_SUCCESS = '[App] Get All Cols DataTypes Success',
  GET_ALL_COLS_DATATYPES_FAILURE = '[App] Get All Cols DataTypes Success',

  SET_EXPAND_LEVEL = '[PAGE] Set Expand Level',
}

export class SetAppLoading {
  static readonly type = ActionTypes.SET_APP_IS_LOADING;
  constructor(public isLoading: boolean) {}
}

export class SetAppHasError {
  static readonly type = ActionTypes.SET_APP_HAS_ERROR;
  constructor(public error: any) {}
}

export class GetAllColsDataTypes {
  static readonly type = ActionTypes.GET_ALL_COLS_DATATYPES;
  constructor(){}
}

export class GetAllDataTypesSuccess {
  static readonly type = ActionTypes.GET_ALL_COLS_DATATYPES_SUCCESS;
  constructor(public data : Array<{ [key: number]: string }>){}
}

export class GetAllDataTypesFailure {
  static readonly type = ActionTypes.GET_ALL_COLS_DATATYPES_FAILURE;
  constructor(public error :  any){}
}

export class SetExpandLevel {
  static readonly type = ActionTypes.SET_EXPAND_LEVEL;
  constructor(public level :  number){}
}

