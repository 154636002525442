import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'isLanguageDisabled',
  pure : false
})
export class IsLanguageDisabledPipe implements PipeTransform {
  transform(value: string, selectedLanguages: {[key : string] : string}, currentIndex: number): boolean {
    return Object.values(selectedLanguages).includes(value) && selectedLanguages[currentIndex] !== value;
  }
}
