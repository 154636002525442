import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FontSelectServiceService } from '../../core/services/font-select-service/font-select-service.service';
import { DialogFontComponent } from '../../shared/components/dialog-font/dialog-font.component';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ASCII_EXPAND_ICON } from '../../core/constants/app.contants';
import { DragService } from '../../core/services/drag-service/drag.service';
import { ResizeService } from '../../core/services/resize-service/resize.service';
import { Format } from '../../store/format/format.actions';
import { Store } from '@ngxs/store';
import { FormatLocalColumn } from '../../store/format/format.model';
@Component({
  selector: 'app-format-local-shared-col',
  templateUrl: './format-local-shared-col.component.html',
  styleUrls: ['./format-local-shared-col.component.scss']
})
export class FormatLocalSharedColComponent  implements OnInit{
  private modalRef!: NgbModalRef;
  formatLocalColForm: FormGroup;
  closeResult: string = '';
  expandIcon = ASCII_EXPAND_ICON;
  @ViewChild('content', { static: true }) content: any;
  @ViewChild('content') popupContent!: ElementRef;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FormatLocalSharedColComponent>,
    private fb: FormBuilder,
    private service:FontSelectServiceService,
    private dialog:MatDialog,
    private modalService: NgbModal,
    private dragService: DragService,
    private resizeService: ResizeService,
    private store: Store,
  ) { 
      this.formatLocalColForm = this.fb.group({
      formatId: [{ value: 'Format ID', disabled: true }],
      formatUser: [{ value: 'Format User', disabled: true }],
      columnId: [{ value: 'Column ID', disabled: true }],
      status: [''],
      minWidth: [''],
      fontStyle: [''],
      comment: [''],
      transactions: [{ value: 'Transactions', disabled: true }]
    });
  }

  ngOnInit(): void {
    //this.open(this.content);

    this.getLocalColumn();
  }
  openFontDialog() {
    const dialogRef = this.dialog.open(DialogFontComponent, {
      width: '400px',
      data: this.data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      let alignment;
        switch (this.service.getTextAlignment()) {
          case '0':
            alignment = 'justify';
            break;
          case '1':
            alignment = 'left';
            break;
          case '2':
            alignment = 'right';
            break;
          default:
            alignment='';
            break;
        }
        let type;
        switch (this.service.getSelectedFontType()) {
          case '0':
            type = 'Regular';
            break;
          case '1':
            type = 'Italic';
            break;
          case '2':
            type = 'Bold';
            break;
          default:
            type='Regular';
            break;
        }
       const fontStyle= {
          "FontStyle": {"Asian text font": this.service.getAsianTextFont(),
          "Font Style": type,
          "Size": this.service.getFontSize(),
          "Font": this.service.getFontFamily(),
          "Font Color": this.service.getTextColor(),
          "Background Color": this.service.getBackgroundColor(),
          "Text Alignment": alignment,
          "Effects": this.service.getFontStyle()
        }
        }

        this.formatLocalColForm = this.fb.group({
          formatId: [{ value: 'Format ID', disabled: true }],
          formatUser: [{ value: 'Format User', disabled: true }],
          columnId: [{ value: 'Column ID', disabled: true }],
          status: this.formatLocalColForm.value['status'],
          minWidth:  this.formatLocalColForm.value['minWidth'],
          fontStyle: JSON.stringify(fontStyle),
          comment: this.formatLocalColForm.value['comment'],
          transactions: [{ value: 'Transactions', disabled: true }]
        });
    });
}
  onSave(): void {
    if (this.formatLocalColForm.valid) {
      this.dialogRef.close({
        fontStyleMinWidth: `${this.formatLocalColForm.value['fontStyle']}: ${this.formatLocalColForm.value['minWidth']}`,
        formatLocalColForm: this.formatLocalColForm.value
      });
      this.modalRef.dismiss();
      this.modalService.dismissAll();
    } else {}
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }
  open(content: any) {
    if (this.modalRef) {
      this.modalRef.close();
    }
    if(this.modalService){
      this.modalService.dismissAll();
    }
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      this.dialog.closeAll();
      return 'by pressing ESC';
      
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      this.dialog.closeAll();
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContent);
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContent);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }
  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContent);
  }

  getLocalColumn() {
    this.store.dispatch(new Format.GetLocalColumn(this.data.data.id)).subscribe({
      next: (state: any) => {
        if (!state || !state.format || !state.format.localColumnFormat || !state.format.localColumnFormat.FormatData) {
          console.error('Invalid data structure received:', state);
          return;
        }
    
        const localColumnformat: FormatLocalColumn = state.format.localColumnFormat.Local_Col;
      },
      error: (err: any) => {
        console.error('Error fetching page format data:', err);
      },
    });
  }
}
