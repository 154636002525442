<form [formGroup]="formatPageForm" class="form-width">
  <div class="form-group" *ngFor="let field of formFields">
    <label
      >{{ field.label
      }}<span class="required" *ngIf="field.required">*</span></label
    >
    <ng-container>
      <!-- Textarea for labels containing 'Comment' -->
      <textarea
        *ngIf="field.name === FORM.Comment"
        [formControlName]="field.name"
        [value]="formatPageForm.controls[field.name].value"
        (click)="field.clickAction && field.name === FORM.Comment ? openMlTextDialog() : null"
        class="custom-input fixed-width"
      >
      </textarea>

      <!-- Input with placeholder for 'Transactions' -->
      <div
        *ngIf="field.name === FORM.TxList"
        class="form-group"
      >
        <input
          type="text"
          id="transactionInput"
          #transactionInput
          [formControlName]="field.name"
          [value]="formatPageForm.controls[field.name].value" 
          [ngClass]="{ 'error': formatPageForm.controls[field.name].invalid && (formatPageForm.controls[field.name].touched) }"
          class="custom-input"
        />
      </div>

      <!-- Input with placeholder for 'Status' -->
      <div
        *ngIf="field.name === FORM.Status"
        class="form-group"
      >
        <div class="custom-input chip-container fixed-width" (click)="openEditDdDialog()">
          <div type="button" class="Chip margin-2px" *ngFor="let status of formatPageForm.controls[field.name].value" 
            (click)="openEditDdDialog(status.key); 
            $event.stopPropagation()"
          >
            {{ status.value }}
          </div>
        </div>
      </div>

      <!-- Select Box for labels containing 'Select' -->
      <select
        *ngIf="field.label.toLowerCase().includes('select')"
        [formControlName]="field.name"
        class="custom-input"
      >
        <option
          *ngFor="let option of field.options"
          [value]="option.value"
          [selected]="option.value === field.value"
        >
          {{ option.label }}
        </option>
      </select>

      <!-- Checkbox for labels containing 'Enable' -->
      <input
        *ngIf="field.label.toLowerCase().includes('enable')"
        type="checkbox"
        [formControlName]="field.name"
        [checked]="field.checked || false"
        class="custom-input"
      />

      <!-- Radio Button for labels containing 'Choose' -->
      <div
        *ngIf="field.label.toLowerCase().includes('choose')"
        class="form-group"
      >
        <label *ngFor="let option of field.options">
          <input
            type="radio"
            [formControlName]="field.name"
            [value]="option.value"
            [checked]="option.value === field.value"
            class="custom-input"
          />
          {{ option.label }}
        </label>
      </div>

      <!-- Default Input for all other labels -->
      <input
        *ngIf="
          !field.label.toLowerCase().includes('comment') &&
          !field.label.toLowerCase().includes('transactions') &&
          !field.label.toLowerCase().includes('select') &&
          !field.label.toLowerCase().includes('enable') &&
          !field.label.toLowerCase().includes('choose') &&
          field.name !== FORM.Status 
        "
        type="text"
        [formControlName]="field.name"
        [value]="formatPageForm.controls[field.name].value"
        class="custom-input"
        [ngClass]="{ 'error': formatPageForm.controls[field.name].invalid && (formatPageForm.controls[field.name].touched) }"
        (click)="field.clickAction && field.name === FORM.FontStyle ? openFontDialog() 
          : (field.name === FORM.PgNestedCol ? openColDialog()
              :(field.name === FORM.Status ? openEditDdDialog() 
                : null))
        "
       #textFieldReference/>
    </ng-container>
  </div>
</form>