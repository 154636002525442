import { Component, DestroyRef,EventEmitter,inject, Input, OnInit, Output,} from '@angular/core';
import { ARROW_UP_DOWN,} from '../../../core/constants/app.contants';
import { Store } from '@ngxs/store';
import { Observable,} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SheetState } from '../../../store/page/page.store';
import { SetExpandLevel } from '../../../store/root/root.action';

@Component({
  selector: 'app-decimal-field',
  templateUrl: './decimal-field.component.html',
  styleUrl: './decimal-field.component.scss',
})
export class DecimalFieldComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  @Input() maxBeforeDecimal: number = 1
  @Input() maxAfterDecimal: number = 1;
  @Input() minValue: number = 0;
  @Input() maxValue: number = 5;
  @Input() step: number = 1.0;
  @Input() initialValue: number = 1;
  @Input() byEvent = false;
  @Input() halt = false;
  @Output() expandLevel = new EventEmitter<number>();
  inputValue : number = 0; 
  arrow_up_down = ARROW_UP_DOWN;
  beforeValue = 1;
  afterValue : number | null = null;
  rowLevel$: Observable<{ddl : number , page : number}> = inject(Store).select(
    SheetState.getRowLevel
  );
  constructor(
    private store: Store){}
  ngOnInit(): void {
    this.rowLevel$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((rowLevel)=>{
      this.maxValue = this.byEvent ? rowLevel.ddl : rowLevel.page;
      this.beforeValue = this.byEvent ? rowLevel.ddl : rowLevel.page;
      this.afterValue = null;
    })
  }

 incrementValue(): void {
    if(!this.halt){
      let newVal = (this.beforeValue as number);
      if(newVal >= this.maxValue){
        return;
      }
      this.beforeValue = newVal + 1;
      this.emitEvent();
    }
  }

  decrementValue(): void {
    if(!this.halt){
      let newVal = (this.beforeValue as number);
      if(newVal <= this.minValue){
        return;
      }
      this.beforeValue = newVal - 1;
      this.emitEvent();

    }
  }

  emitEvent(){
    this.byEvent ? this.expandLevel.next(this.beforeValue) : this.store.dispatch(new SetExpandLevel(this.beforeValue));
  }

  onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'ArrowUp', 'ArrowDown'];
    const numericKey = !isNaN(Number(event.key)) && event.key >= '0' && event.key <= '9';
    const inputElement = event.target as HTMLInputElement;
    const currentValue = Number(inputElement.value) || 0;

    if (numericKey) {
      const newValue = Number(event.key);

      if (newValue > this.maxValue) {
        this.beforeValue = this.maxValue;
      } else if (newValue < this.minValue) {
        this.beforeValue = this.minValue;
      } else {
        this.beforeValue = newValue;
      }

      this.emitEvent();
      event.preventDefault();
      return;
    }

    switch (event.key) {
      case 'Backspace':
        this.beforeValue = this.minValue;
        this.afterValue = null;
        this.emitEvent();
        event.preventDefault();
        break;

      case 'ArrowUp':
        if (currentValue + 1 > this.maxValue) {
          this.beforeValue = this.maxValue;
        } else {
          this.beforeValue = currentValue + 1;
        }
        this.emitEvent();
        event.preventDefault();
        break;

      case 'ArrowDown':
        if (currentValue - 1 < this.minValue) {
          this.beforeValue = this.minValue;
        } else {
          this.beforeValue = currentValue - 1;
        }
        this.emitEvent();
        event.preventDefault();
        break;

      default:
        if (!allowedKeys.includes(event.key)) {
          event.preventDefault(); // Block unallowed keys
        }
    }
  }

}


