<div class="view-page-container" #viewPageContainer>
  <div class="modal-header" (mousedown)="onDragStart($event)">
    <span class="heading">View: {{clicked}} (ID:{{viewedID}})</span>
    <div class="close-icon" aria-label="Close" (click)="dialogRef.close()">
      <span aria-hidden="true">{{closeTab}}</span>
    </div>
  </div>
  <div class="edit-item-container enable-scroll" #scrollContainer>
    <div class="minWidth">
      <div *ngIf="((isLoading$ | async))" class="loader-overlay">
        <div class="loader"></div>
      </div>
      <!-- Dynamic Forms -->
      <form [formGroup]="dynamicForm">
        <div *ngIf="clicked">
          <div class="cell-value-container" *ngFor="let field of dialogConfig[clicked]?.formFields">
            <div>
              <label class="field-label" for="item-input font-style"> {{field}}</label>
            </div>
            <div>
              <input type="text" [formControlName]="field" [readonly]="true" class="View-field-item">
            </div>
          </div>
        </div>

        <!-- Input Sections -->
        <div *ngIf="dialogConfig[clicked]?.sections">
          <div *ngFor="let section of dialogConfig[clicked].sections">
            <div class="form-group">
              <label class="main-label-item" for="item-input font-style">{{section.title}}</label>
            </div>
            <div class="item-field-container" *ngFor="let field of section.fields">
              <div>
                <label class="field-label" for="item-input font-style">{{field | titlecase}}</label>
              </div>
              <div>
                <input type="text" [id]="field" [readonly]="true" [formControlName]="field" class="View-field-item">
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <span [innerHTML]="expandIcon"></span>
  </div>
</div>
