<ng-template #content let-modal>
  <angular-window class="add-item-modal" style="background-color:white"  [dragHolder]="header">
    <div class="modal-header" #header>
      <span class="heading">{{data.mode}} Item (ID:[{{data.id}}])</span>
    </div>
    <div class="edit-item-container">
      <form>
        <div class="form-group row-group first-group">
          <div class="first-half-width">
            <label class="font-style">DDS-Type(s)</label>
            <select class="language-select">
              <option value="leafs">Only DDS-Leafs</option>
              <option value="other">Other Type</option>
            </select>
          </div>
          <div class="second-half-width">
            <label class="font-style">DDS-Head(s)</label>
            <select class="language-select">
              <option value="userType">User Type</option>
              <option value="selected">Selected</option>
            </select>
          </div>
          <div class="cross-icon"  (click)="modal.dismiss('Cross click')">
            {{closeTab}}
          </div>
        </div>

        <!-- Second Row of Dropdowns -->
        <div class="form-group row-group">
          <div class="first-half-width">
            <label class="font-style">DDS-Type(s)</label>
            <select class="language-select">
              <option value="select">Select</option>
              <option value="other">Other Type</option>
            </select>
          </div>
          <div class="second-half-width">
            <label class="font-style">DDS-Head(s)</label>
            <select class="language-select" disabled>
              <option value="selected">Selected</option>
              <option value="other">Other Type</option>
            </select>
          </div>
        </div>

        <!-- Buttons -->
        <div class="button-group">
          <div class="button-container">
          <div class="Chip" type="button" (click)="onSave()">Save</div>
          </div>
          <div type="button" class="Chip" (click)="onCancel()">Cancel</div>
        </div>
      </form>
    </div>
  </angular-window>
</ng-template>
