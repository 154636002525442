import {
  Component,
  ViewChild,
  OnInit,
  Inject,
  ElementRef,
  HostListener,
  inject,
  DestroyRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ResizeService } from '../../../core/services/resize-service/resize.service';
import { DragService } from '../../../core/services/drag-service/drag.service';
import {
  All_Pages_ID,
  ENGLISH,
  UNICODE_CROSS_ICON,
  UNICODE_EXPAND_ICON,
} from '../../../core/constants/app.contants';
import { Store } from '@ngxs/store';
import { FormatStore } from '../../../store/format/format.store';
import { Format } from '../../../store/format/format.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormatPage } from '../../models/context-menus/page-menu/page-menu.models';
import { FormatColumn } from '../../models/context-menus/column-menu/column-menu.models';
import { FormatRow } from '../../models/context-menus/row-menu/row-menu.models';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import { Observable } from 'rxjs';
import { FormatCell } from '../../models/context-menus/cell-menu/cell-menu.models';
import { FormatItem } from '../../models/context-menus/item-menu/item-menu.models';

interface DialogConfig {
  [key: string]: {
    formFields?: string[];
    sections?: {
      title: string;
      fields: string[];
    }[];
  };
}

@Component({
  selector: 'app-dialog-view',
  templateUrl: './dialog-view.component.html',
  styleUrls: ['./dialog-view.component.scss'],
})
export class DialogViewComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  private modalRef!: NgbModalRef;
  data: any;
  clicked: any;
  expandIcon = UNICODE_EXPAND_ICON;
  closeTab = UNICODE_CROSS_ICON;
  dynamicForm: FormGroup = this.fb.group({});
  viewedID = '';

  @ViewChild('viewPageContainer', { static: true })
  popupContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLDivElement>;
  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollContainer.nativeElement.scrollTop = 0; // Scroll to top
      this.scrollContainer.nativeElement.scrollLeft = 0; // Scroll to top
    }, 200); // 200ms delay
  }
  viewFormatPage$ = inject(Store).select(FormatStore.viewFormatPageData);
  viewColumnData$ = inject(Store).select(FormatStore.viewColumnData);
  viewRowData$ = inject(Store).select(FormatStore.viewRowData);
  viewCellData$ = inject(Store).select(FormatStore.viewCellData);
  viewItemData$ = inject(Store).select(FormatStore.viewItemData);
  currentPgId$ = inject(Store).select(PgTabStore.getCurrentPgId);
  isLoading$: Observable<boolean> = inject(Store).select(FormatStore.isLoading);

  public dialogConfig: DialogConfig = {
    Item: {
      sections: [
        {
          title: 'Format Local-Item',
          fields: [
            'FormatID',
            'FormatUser',
            'ItemID',
            'ItemStatus',
            'ItemFormula',
            'ItemFontStyle',
            'ItemComment',
            'ItemTransactions',
          ],
        },
      ],
    },
    Cell: {
      sections: [
        {
          title: 'Format Local-Cell',
          fields: [
            'FormatID',
            'FormatUser',
            'CellID',
            'CellDataType',
            'CellDropDownList',
            'CellDefaultData',
            'CellStatus',
            'CellFormula',
            'CellFontStyle',
            'CellComment',
            'CellTransactions',
          ],
        },
      ],
    },
    Page: {
      formFields: [
        'Row',
        'PageId',
        'PageName',
        'PageType',
        'PageEdition',
        'PageOwner',
        'PageUrl',
        'PageSeo',
        'PageStatus',
        'PageComment',
        'RowType',
        'RowStatus',
        'RowComment',
        'Inherit',
        'Share',
      ],
      sections: [
        {
          title: 'Format Page',
          fields: [
            'Format',
            'User',
            'Object',
            'PgNestedCol',
            'PgFreezeCol',
            'PgExpand',
            'PgSort',
            'PgFilter',
            'FontStyle',
            'Comment',
            'TxList',
          ],
        },
      ],
    },
    Column: {
      formFields: [
        'Row',
        'ColId',
        'PageType',
        'ColName',
        'ColDatatype',
        'RowStatus',
        'RowComment',
        'RowType',
        'PageStatus',
        'PageOwner',
        'ColStatus',
        'ColFormula',
        'ColOwner',
      ],
      sections: [
        {
          title: 'Format Local Column',
          fields: [
            'Format',
            'User',
            'Object',
            'Status',
            'ColMinWidth',
            'FontStyle',
            'Comment',
            'TxList',
          ],
        },
      ],
    },
    Row: {
      formFields: [],
      sections: [
        {
          title: 'Format Local-Row',
          fields: [
            'Format',
            'User',
            'Object',
            'Status',
            'FontStyle',
            'Comment',
            'TxList',
          ],
        },
      ],
    },
  };

  constructor(
    public dialogRef: MatDialogRef<DialogViewComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: any,
    private fb: FormBuilder,
    private resizeService: ResizeService,
    private dragService: DragService,
    private store: Store
  ) {
    this.data = injectedData.data;
    this.clicked = injectedData.clicked;
    this.dynamicForm = this.createDynamicForm();
  }

  ngOnInit() {
    this.viewFormatData();
  }

  viewFormatData(): void {
    if (this.clicked === 'Page') {
      this.currentPgId$.subscribe((pg: any) => {
        this.viewedID = pg;
      });
      this.getViewFormatPageData();
    } else if (this.clicked === 'Column') {
      this.viewedID = this.data.col;
      this.getViewFormatColData();
    } else if (this.clicked === 'Row') {
      this.viewedID = this.data;
      this.getViewFormatRowData();
    } else if (this.clicked === 'Cell') {
      this.getViewFormatCellData();
    } else if (this.clicked === 'Item') {
      this.getViewFormatItemData();
    }
  }

  private createDynamicForm(): FormGroup {
    const config = this.dialogConfig[this.clicked]; // Get the config based on the clicked type
    if (!config) return this.fb.group({});

    const formGroup: { [key: string]: any } = {};

    // Add form fields from formFields array
    if (config.formFields) {
      config.formFields.forEach((field) => {
        formGroup[field] = [''];
      });
    }

    // Add form fields from sections
    if (config.sections) {
      config.sections.forEach((section) => {
        section.fields.forEach((field) => {
          formGroup[field] = [''];
        });
      });
    }

    return this.fb.group(formGroup);
  }

  getViewFormatPageData(): void {
    this.store.dispatch(new Format.ViewPage());
    this.viewFormatPage$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data?.pageData) {
          this.dynamicForm.patchValue(data?.pageData ?? {});
          const page = this.mapViewDataToFormatPage(data?.FormatData);
          this.patchSectionFields(page);
        }
      });
  }

  getViewFormatColData(): void {
    this.store.dispatch(new Format.ViewColumn(this.data.col));
    this.viewColumnData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data?.FormatData) {
          this.dynamicForm.patchValue(data?.ColData ?? {});
          const page = this.mapViewDataToFormatColumn(data?.FormatData);
          this.patchSectionFields(page);
        }
      });
  }

  getViewFormatRowData(): void {
    this.store.dispatch(new Format.ViewRow(this.data));
    this.viewRowData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        this.addDynamicControls(data.RowData);
        const row = this.mapViewDataToFormatColumn(data?.FormatData);
        this.patchSectionFields(row);
      });
  }

  /** Keys to exclude from the form controls */
  excludedKeys: string[] = [
    'RowLevel',
    'ParentRow',
    'ParentRow.Row',
    'ParentRow.RowLevel',
  ];

  addDynamicControls(data: any, parentKey: string = '') {
    Object.keys(data).forEach((key) => {
      const controlKey = parentKey ? `${parentKey}.${key}` : key;
      // Skip excluded keys
      if (this.excludedKeys.includes(controlKey)) {
        return;
      }
      // Add a control for non-object fields
      this.dynamicForm.addControl(
        controlKey,
        this.fb.control({ value: data[key] || '', disabled: true })
      );
    });
  }

  getFormKeys(): string[] {
    return Object.keys(this.dynamicForm.controls);
  }

  getViewFormatCellData(): void {
    this.store.dispatch(new Format.ViewCell(this.data.rowId, this.data.colId));
    this.viewCellData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data?.ViewCell) {
          const cell = this.mapViewDataToFormatCell(data?.ViewCell);
          this.viewedID = cell.CellID;
          this.patchSectionFields(cell);
        }
      });
  }

  getViewFormatItemData(): void {
    this.store.dispatch(new Format.ViewItem(this.data.rowId, this.data.colId));
    this.viewItemData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data?.ViewItem) {
          const item = this.mapViewDataToFormatItem(data?.ViewItem);
          this.viewedID = item.ItemID;
          this.patchSectionFields(item);
        }
      });
  }

  patchSectionFields(data: any) {
    this.dialogConfig[this.clicked]?.sections?.forEach((section) => {
      section.fields.forEach((field) => {
        if (data[field] !== undefined) {
          this.dynamicForm.get(field)?.patchValue(data[field]);
        }
      });
    });
  }

  mapViewDataToFormatPage(viewData: any): FormatPage {
    return {
      Format: viewData.Format?.toString() || '',
      User: viewData.User?.toString() || '',
      Object: viewData.Object?.toString() || '',
      PgNestedCol: viewData.PgNestedCol?.toString() || '',
      PgFreezeCol: viewData.PgFreezeCol || 0,
      PgExpand: viewData.PgExpand || 0,
      PgSort: viewData.PgSort || null,
      PgFilter: viewData.PgFilter || null,
      FontStyle: viewData.FontStyle || '',
      Comment: viewData.Comment?.[ENGLISH] || '',
      TxList: viewData.TxList || [],
    };
  }

  mapViewDataToFormatColumn(viewData: any): FormatColumn {
    const status = Object.values(viewData?.Status || {}).join('; ');
    return {
      Format: viewData.Format?.toString() || '',
      User: viewData.User?.toString() || '',
      Object: viewData.Object?.toString() || '',
      Status: status || '',
      ColMinWidth: viewData.ColMinWidth || '',
      FontStyle: viewData.FontStyle || '',
      Comment: viewData.Comment?.[ENGLISH] || '',
      TxList: viewData.TxList || [],
    };
  }

  mapViewDataToFormatRow(viewData: any): FormatRow {
    const status = Object.values(viewData?.Status || {}).join('; ');
    return {
      Format: viewData.Format?.toString() || '',
      User: viewData.User?.toString() || '',
      Object: viewData.Object?.toString() || '',
      Status: status || '',
      FontStyle: viewData.FontStyle || '',
      Comment: viewData.Comment?.[ENGLISH] || '',
      TxList: viewData.TxList || [],
    };
  }

  mapViewDataToFormatCell(viewData: any): FormatCell {
    const status = Object.values(viewData?.Status || {}).join('; ');
    return {
      FormatID: viewData.Format?.toString() || '',
      FormatUser: viewData.User?.toString() || '',
      CellID: viewData.Object?.toString() || '',
      CellDataType: viewData.DataType?.toString() || '',
      CellDropDownList: viewData.DDL?.toString() || '',
      CellDefaultData: viewData.Default?.toString() || '',
      CellStatus: status || '',
      CellFormula: viewData.Formula || '',
      CellFontStyle: viewData.FontStyle || '',
      CellComment: viewData.Comment?.[ENGLISH] || '',
      CellTransactions: viewData.TxList || [],
    };
  }

  mapViewDataToFormatItem(viewData: any): FormatItem {
    const status = Object.values(viewData?.Status || {}).join('; ');
    return {
      FormatID: viewData.Format?.toString() || '',
      FormatUser: viewData.User?.toString() || '',
      ItemID: viewData.Object?.toString() || '',
      ItemStatus: status || '',
      ItemFormula: viewData.Formula || '',
      ItemFontStyle: viewData.FontStyle || '',
      ItemComment: viewData.Comment?.[ENGLISH] || '',
      ItemTransactions: viewData.TxList || [],
    };
  }

  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContainer);
  }

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContainer);
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContainer);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }

  onDelete(): void {
    this.dialogRef.close(true);
    this.modalRef.dismiss('Save click');
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }
}
