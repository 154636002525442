import { Component,Inject, OnInit } from '@angular/core';
import { MainService } from '../../../../core/services/main-service/main.service';
import { DDL_All_Pages,DDL_Regions,UNICODE_LEFT_RIGHT_ARROW } from '../../../../core/constants/app.contants';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PGColDialogComponent } from '../../../../modals/pgcol-dialog/pgcol-dialog.component';
import { Select, Store } from '@ngxs/store';
import { SheetState } from '../../../../store/page/page.store';
import { Observable, Subject,} from 'rxjs';
import { Sheet } from '../../../../store/page/page.actions';
import { AutoAdjustWidthDirective } from '../../../directives/auto-adjust-width.directive';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'edit-dd-dialog-menu-bar',
  templateUrl: './edit-dd-dialog-menu-bar.component.html',
  styleUrls: ['./edit-dd-dialog-menu-bar.component.scss'],
})
export class editDdDialogMenuBarComponent implements OnInit {
  menuOptions = ['Cols', 'Freeze', 'Expand', 'Sort', 'Filter', 'Clear'];
  pageFreezeColumn: any = 0;
  expand = UNICODE_LEFT_RIGHT_ARROW;
  isFilter: boolean = false;
  previousFontSize: number;
  currentFontSize: number;
  parentRows: any[] = [];
  ddlLanuages = DDL_All_Pages;
  DDL_Regions = DDL_Regions;
  firstIncrement: boolean = true;
  @Select(SheetState.getFrozen) frozen$: Observable<any> | undefined;
  @Select(SheetState.PickDdiColumns) columns$: Observable<any> | undefined;
  height: any = 0;
  width: any = 0;
  clickedRowData: any;
  public sortStatus = false;
  public sorted = false;
  level: any;
  selectedId: any;
  widthDirective!: AutoAdjustWidthDirective;
  private destroy$ = new Subject<void>();
  constructor(
    private store: Store,
    private mainService: MainService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<editDdDialogMenuBarComponent>,
    public fb: FormBuilder
  ) {
    this.previousFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.currentFontSize = this.previousFontSize;
  }
  ngOnInit(): void {
    this.store.dispatch(new Sheet.SetFrozen(this.data?.data?.frozen));
    this.adjustCheckboxSize();
    window.addEventListener('resize', this.adjustCheckboxSize.bind(this));
    this.mainService.sortStatusDD$.next('Sort is Off');
    
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private adjustCheckboxSize() {
    const bodyFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.height = bodyFontSize / this.previousFontSize - 0.2;
    this.width = bodyFontSize / this.previousFontSize - 0.2;
  }
  openColDialog() {
    const dialogRef = this.dialog.open(PGColDialogComponent, {
      width: '750px',
      data: { selectedId: this.data?.selectedId, dialog: true },
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  clearFilters() {
    this.parentRows.pop();
    this.parentRows.push(null);
    this.clickedRowData = null;
    this.sortStatus = false;
    this.sorted = false;
    let obj3 = { pageFreezeColumn: 0 };
    this.mainService.pageFormateFreeze.next(obj3);
    this.onExpandChange('1');
    this.dialogRef.close({ parentRows: null, clicked: true });
  }

  onExpandChange(value: any) {
    this.mainService.pageFormateReg.next(value);
  }

  toggleSort() {
    this.mainService.toggleSortDialog();
  }

  toggleFilter() {
    this.mainService.toggleFilterDialog();
    this.dialogRef.afterClosed().subscribe((result) => {
      this.mainService.toggleFilterDialog();
    })
  }
}
