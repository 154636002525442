<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link
  href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Noto+Sans+KR:wght@100..900&family=Noto+Sans+SC:wght@100..900&family=Noto+Serif+JP:wght@200..900&family=Noto+Serif+KR:wght@200..900&family=Noto+Serif+SC:wght@200..900&display=swap"
  rel="stylesheet">
<div class="font-page-container" #fontPageContainer>
  <div class="modal-header" (mousedown)="onDragStart($event)">
    <span class="heading"></span>
  </div>
  <div class="font-dialog-container" style="overflow: auto;">
    <div class="font-dialog-inner-container">
      <div class="edit-item-container">
        <div class="form-group">
          <label for="item-input font-style">Latin Text</label>
          <div class="border"></div>
        </div>
      </div>
      <div class="font-style-container">
        <div class="font-container font-picker1">
          Asian text font:
          <select [(ngModel)]="selectAsianTextFont" (change)="onFontFamilyChangeAsian($event)" id="fontSelect">
            <option value="SimSun">SimSun</option>
            <option value="SimHei">SimHei</option>
            <option value="Microsoft YaHei">Microsoft YaHei</option>
            <option value="Noto Sans SC">Noto Sans SC</option>
            <option value="Noto Serif SC">Noto Serif SC</option>
            <option value="MS Gothic">MS Gothic</option>
            <option value="MSMINCHO">MS Mincho</option>
            <option value="Meiryo">Meiryo</option>
            <option value="Noto Sans JP">Noto Sans JP</option>
            <option value="Noto Serif JP">Noto Serif JP</option>
            <option value="Batang">Batang</option>
            <option value="Dotum">Dotum</option>
            <option value="Gulim">Gulim</option>
            <option value="Noto Sans KR">Noto Sans KR</option>
            <option value="Noto Serif KR">Noto Serif KR</option>
          </select>
          <div class="font-style-select">
            Font:
            <select [(ngModel)]="selectedFontFamily" (change)="onFontFamilyChange($event)">
              <option value="Arial, Helvetica, sans-serif">Arial, Helvetica, sans-serif</option>
              <option value="Verdana, Geneva, sans-serif">Verdana, Geneva, sans-serif</option>
              <option value="Tahoma, Geneva, sans-serif">Tahoma, Geneva, sans-serif</option>
              <option value="Times New Roman, Times, serif">Times New Roman, Times, serif</option>
              <option value="Georgia, serif">Georgia, serif</option>
              <option value="Palatino Linotype, Book Antiqua, Palatino, serif">Palatino Linotype, Book Antiqua,
                Palatino, serif</option>
              <option value="Arial Black, Gadget, sans-serif">Arial Black, Gadget, sans-serif</option>
              <option value="Impact, Charcoal, sans-serif">Impact, Charcoal, sans-serif</option>
              <option value="Lucida Console, Monaco, monospace">Lucida Console, Monaco, monospace</option>
              <option value="Courier New, Courier, monospace">Courier New, Courier, monospace</option>
              <option value="Lucida Sans Unicode, Lucida Grande, sans-serif">Lucida Sans Unicode, Lucida Grande,
                sans-serif</option>
              <option value="Tahoma, Geneva, sans-serif">Tahoma, Geneva, sans-serif</option>
            </select>
          </div>
        </div>
        <div class="font-container font-picker2">
          Font Style:
          <select [(ngModel)]="selectedFontStyle" (change)="onFontStyleChange()">
            <option value="normal">Regular</option>
            <option value="italic">Italic</option>
            <option value="bold">Bold</option>
          </select>
        </div>
        <div class="font-container font-picker3">
          Size:
          <select [(ngModel)]="selectFontSize" (change)="onFontSizeChange()">
            <option value="8px">8px</option>
            <option value="9px">9px</option>
            <option value="10px">10px</option>
            <option value="11px">11px</option>
            <option value="12px">12px</option>
            <option value="14px">14px</option>
            <option value="16px">16px</option>
            <option value="18px">18px</option>
            <option value="20px">20px</option>
            <option value="22px">22px</option>
            <option value="24px">24px</option>
            <option value="26px">26px</option>
            <option value="28px">28px</option>
            <option value="36px">36px</option>
            <option value="48px">48px</option>
            <option value="72px">72px</option>
          </select>
        </div>
      </div>
      <div class="edit-item-container">
        <div class="form-group">
          <label for="item-input font-style">All Text</label>
          <div class="border"></div>
        </div>
      </div>
      <div class="font-style-container">
        <div class="font-color-field">
          Font Color:
          <input [cpPosition]="position" (colorPickerSelect)="onColorPickerSelect($event)" [(colorPicker)]="color"
            value="{{ color }}" />
        </div>
        <div class="font-background-field">
          Background Color:
          <input [cpPosition]="position" (colorPickerSelect)="onColorPickerSelect($event)"
            [(colorPicker)]="colorBackground" value="{{ colorBackground }}" />
        </div>
        <div class="font-alignment-field">
          Text Alignment:
          <select [(ngModel)]="selectedAlignment" (change)="onSelectAlignment()">
            <option value="justify">Justify</option>
            <option value="left">Left</option>
            <option value="right">Right</option>
          </select>
        </div>
      </div>
      <div class="apply-effect-container">
        <div class="effects-item-container">
          <div class="form-group">
            <label for="item-input font-style">Effects</label>
            <div class="border"></div>
          </div>
        </div>
        <div class="effects-container">
          <table class="table-container-font-style">
            <tr>
              <td>
                <input type="checkbox" id="Strikethrough" name="Strikethrough" value="line-through"
                  [(ngModel)]="strikeThrough" (change)="onStrikeThrough()" />
                <label for="Strikethrough"> Strikethrough</label>
              </td>
              <td>
                <input type="checkbox" id="Underline" name="Underline" value="underline" [(ngModel)]="underLine"
                  (change)="onUnderline()" />
                <label for="Underline">Underline</label>
              </td>
              <td>
                <input type="checkbox" id="Textwrap" name="Textwrap" value="wrap" [(ngModel)]="textWrap"
                  (change)="onTextWrap()" />
                <label for="Textwrap">Text Wrap</label>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" id="Superscript" name="Superscript" value="sup" [(ngModel)]="superScript"
                  (change)="onSuperScript()" />
                <label for="Superscript">Superscript</label>
              </td>
              <td>
                <input type="checkbox" id="Italic" name="Italic" value="italic" [(ngModel)]="italicText"
                  (change)="onitalicText()" />
                <label for="Italic">Italic</label>
              </td>
              <td>
                <input type="checkbox" id="TextBorder" name="TextBorder" value="1px solid" [(ngModel)]="textBorder"
                  (change)="ontextBorder()" />
                <label for="vehicle1">Text Border</label>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" id="Subscript" name="Subscript" value="sub" [(ngModel)]="subScript"
                  (change)="onSubScript()" />
                <label for="vehicle1">Subscript</label>
              </td>
              <td>
                <input type="checkbox" id="Bold" name="Bold" value="bold" [(ngModel)]="boldText"
                  (change)="onboldText()" />
                <label for="Bold">Bold</label>
              </td>
              <td>

              </td>
            </tr>
          </table>
          <div class="preview-item-container">
            <div class="form-group">
              <label for="item-input font-style">Preview</label>
              <div class="border"></div>
            </div>
            <div class="preview">
              <div class="border first-border"></div>
              <p class="heading2" [style.border]="textBorder===true?'1px solid':'0'"
                [style.display]="superScript || subScript?'none':'flex'" [style.textWrap]="textWrap?'wrap':''"
                [style.textDecoration]="strikeThrough?'line-through':underLine?'underline':''"
                [style.textAlign]="selectedAlignment === 'justify' ? 'justify' : (selectedAlignment === 'left' ? 'left' : 'right')"
                [style.background]="colorBackground==='(none)'?'#fff':colorBackground"
                [style.color]="color==='Automatic'?'#000':color" [style.fontSize]="selectFontSize"
                [style.fontWeight]="selectedFontStyle === 'bold' || boldText===true?'bold':'normal'"
                [style.fontStyle]="selectedFontStyle === 'italic'|| italicText===true ? 'italic' :'normal'"
                [style.fontFamily]="fontFamily()">
                AaBbCcYyZz
              </p>
              <sup class="heading2" [style.display]="superScript?'flex':'none'" [style.textWrap]="textWrap?'wrap':''"
                [style.textDecoration]="strikeThrough?'line-through':underLine?'underline':''"
                [style.textAlign]="selectedAlignment === 'justify' ? 'justify' : (selectedAlignment === 1 ? 'left' : 'right')"
                [style.background]="colorBackground==='(none)'?'transparent':colorBackground"
                [style.color]="color==='Automatic'?'#000':color" [style.fontSize]="selectFontSize"
                [style.fontWeight]="selectedFontStyle === 'bold'?'bold':'normal'"
                [style.fontStyle]="selectedFontStyle === 'normal' ? 'normal' : 'italic'" [style.fontFamily]="fontFamily()">
                AaBbCcYyZz
              </sup>
              <sub class="heading2" [style.display]="subScript?'flex':'none'" [style.textWrap]="textWrap?'wrap':''"
                [style.textDecoration]="strikeThrough?'line-through':underLine?'underline':''"
                [style.textAlign]="selectedAlignment === 'justify' ? 'justify' : (selectedAlignment === 1 ? 'left' : 'right')"
                [style.background]="colorBackground==='(none)'?'transparent':colorBackground"
                [style.color]="color==='Automatic'?'#000':color" [style.fontSize]="selectFontSize"
                [style.fontWeight]="selectedFontStyle === 'bold'?'bold':'normal'"
                [style.fontStyle]="selectedFontStyle === 'normal' ? 'normal' : 'italic'" [style.fontFamily]="fontFamily()">
                AaBbCcYyZz
              </sub>
              <div class="border"></div>

            </div>
            <div class="font-container font-picker2">
              This is a TrueType font.
              <div class="color-button-container">
                <button class="new">New</button>
                <div [ngStyle]="{backgroundColor: color}" class="color1"></div>
                <div [ngStyle]="{backgroundColor: previousColor}" class="color1"></div>
                <button class="current">Current</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-group">
        <div class="button-group-container">
          <div class="Chip" (click)="onSave()" style="cursor: pointer;">Save</div>
        </div>
        <div class="Chip" (click)="onCancel()" style="cursor: pointer;">Cancel</div>
      </div>
    </div>
  </div>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <span [innerHTML]="expandIcon"></span>
  </div>
</div>
