import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { SystemInitials } from '../../constant';
import { FormGroup } from '@angular/forms';
import { KeyValue } from '../../shared/models/edit-dd-dailogs/dd-dailog.models';
import { FORMAT_FORM } from '../constants/app.contants';

@Injectable({
  providedIn: 'root',
})
export class FormatService {
  private apiUrl = 'tFormat';

  constructor(private apiService: ApiService) {}

  // View Page
  viewPage(pg: any): Observable<any[]> {
    return this.apiService.get<any[]>(`${this.apiUrl}/viewPg/${pg}`);
  }

  // Get Column
  viewColumn(pg: any): Observable<any[]> {
    return this.apiService.get<any[]>(`${this.apiUrl}/viewCol/${pg}`);
  }

  // Fetch all items
  getItems(): Observable<any[]> {
    return this.apiService.get<any[]>(this.apiUrl);
  }

  // Update page
  updatePage(pageId: number, form: any): Observable<any[]> {
    // API end point
    const url = `${this.apiUrl}/updatePg/${pageId}`;

    const formData = {
      ...form,
      [FORMAT_FORM.Comment]: form[FORMAT_FORM.CommentObject],
      [FORMAT_FORM.TxList]: form[FORMAT_FORM.TxList]
        ? [form[FORMAT_FORM.TxList]]
        : '',
    };

    // Filter out empty or falsy values
    const paylaod = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) =>
          value &&
          key != FORMAT_FORM.PgId && key != FORMAT_FORM.RowId && key != FORMAT_FORM.CommentObject &&
          (typeof value !== 'object' ||
            (Array.isArray(value)
              ? value.length > 0
              : Object.keys(value).length > 0))
      )
    );

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any[]>(url, paylaod, headers);
  }

  // Update column
  updateColumn(pgId: number, colId: number, form: any): Observable<any[]> {
    // API end point
    const url = `${this.apiUrl}/localcol/${pgId}/${colId}`;

    const formData = {
      ...form,
      [FORMAT_FORM.Status]: form[FORMAT_FORM.Status] ? form[FORMAT_FORM.Status].map((status: KeyValue) => status.key) : '',
      [FORMAT_FORM.Comment]: form[FORMAT_FORM.CommentObject],
      [FORMAT_FORM.TxList]: form[FORMAT_FORM.TxList] ? [form[FORMAT_FORM.TxList]] : '',
    };

    // Filter out empty or falsy values
    const paylaod = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) =>
          value &&
          key != FORMAT_FORM.ColId && key != FORMAT_FORM.RowId && key != FORMAT_FORM.CommentObject &&
          (typeof value !== 'object' ||
            (Array.isArray(value)
              ? value.length > 0
              : Object.keys(value).length > 0))
      )
    );

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any[]>(url, paylaod, headers);
  }

  // Update row
  updateRow(pgId: number, rowId: number, form: any): Observable<any[]> {
    // API end point
    const url = `${this.apiUrl}/localrow/${pgId}/${rowId}`;

    const formData = {
      ...form,
      [FORMAT_FORM.Status]: form[FORMAT_FORM.Status] ? form[FORMAT_FORM.Status].map((status: KeyValue) => status.key) : '',
      [FORMAT_FORM.Comment]: form[FORMAT_FORM.CommentObject],
      [FORMAT_FORM.TxList]: form[FORMAT_FORM.TxList] ? [form[FORMAT_FORM.TxList]] : '',
    };

    // Filter out empty or falsy values
    const paylaod = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) =>
          value &&
          key != FORMAT_FORM.ColId && key != FORMAT_FORM.RowId && key != FORMAT_FORM.CommentObject &&
          (typeof value !== 'object' ||
            (Array.isArray(value)
              ? value.length > 0
              : Object.keys(value).length > 0))
      )
    );

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any[]>(url, paylaod, headers);
  }

  // Update item
  updateItem(
    pgId: number,
    colId: number,
    rowId: number,
    form: any
  ): Observable<any[]> {
    // API end point
    const url = `${this.apiUrl}/localitem/${pgId}/${colId}/${rowId}`;

    const formData = {
      ...form,
      [FORMAT_FORM.Status]: form[FORMAT_FORM.Status] ? form[FORMAT_FORM.Status].map((status: KeyValue) => status.key) : '',
      [FORMAT_FORM.Comment]: form[FORMAT_FORM.CommentObject],
      [FORMAT_FORM.TxList]: form[FORMAT_FORM.TxList] ? [form[FORMAT_FORM.TxList]] : '',
    };

    // Filter out empty or falsy values
    const paylaod = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) =>
          value &&
          key != FORMAT_FORM.ItemId && key != FORMAT_FORM.RowId && key != FORMAT_FORM.CommentObject &&
          (typeof value !== 'object' ||
            (Array.isArray(value)
              ? value.length > 0
              : Object.keys(value).length > 0))
      )
    );

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any[]>(url, paylaod, headers);
  }

  // Update cell
  updateCell(
    pgId: number,
    colId: number,
    rowId: number,
    form: any
  ): Observable<any[]> {
    // API end point
    const url = `${this.apiUrl}/localcell/${pgId}/${colId}/${rowId}`;

    const formData = {
      ...form,
      [FORMAT_FORM.Status]: form[FORMAT_FORM.Status] ? form[FORMAT_FORM.Status].map((status: KeyValue) => status.key) : '',
      [FORMAT_FORM.Comment]: form[FORMAT_FORM.CommentObject],
      [FORMAT_FORM.TxList]: form[FORMAT_FORM.TxList] ? [form[FORMAT_FORM.TxList]] : '',
    };

    // Filter out empty or falsy values
    const paylaod = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) =>
          value &&
          key != FORMAT_FORM.CellId && key != FORMAT_FORM.RowId && key != FORMAT_FORM.CommentObject &&
          (typeof value !== 'object' ||
            (Array.isArray(value)
              ? value.length > 0
              : Object.keys(value).length > 0))
      )
    );

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any[]>(url, paylaod, headers);
  }

  // Get page format
  getPageFormat(pageId: number) {
    // API end point
    const url = `${this.apiUrl}/viewPg/${pageId}`;

    return this.apiService.get<any>(url);
  }

  // Get column format
  getLocalColumn(colId: number) {
    // API end point
    const url = `${this.apiUrl}/viewCol/${colId}`;
    return this.apiService.get<any>(url);
  }

  // Get cell format
  getLocalCell(colId: number, rowId: number) {
    // API end point
    const url = `${this.apiUrl}/viewCell/${colId}/${rowId}`;
    return this.apiService.get<any>(url);
  }

  // Get item format
  getLocalItem(colId: number, rowId: number) {
    // API end point
    const url = `${this.apiUrl}/viewItem/${colId}/${rowId}`;
    return this.apiService.get<any>(url);
  }

  // Get column format
  getLocalRow(rowId: number) {
    // API end point
    const url = `${this.apiUrl}/viewRow/${rowId}`;
    return this.apiService.get<any>(url);
  }

  // Delete column
  deleteColumn(pgId: number, colId: number, payload: any) {
    // API end point
    const url = `${this.apiUrl}/recycledcol/${pgId}/${colId}`;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any>(url, payload, headers);
  }

  // Delete a page
  deletePage(pgId: number, payload: any): Observable<any> {
    // API end point
    const url = `tFormat/recycledPg/${pgId}`;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any>(url, payload, headers);
  }

  // Delete a row
  deleteRow(pgId: number, rowId: number, payload: any): Observable<any> {
    // API end point
    const url = `tFormat/recycledrow/${pgId}/${rowId}`;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any>(url, payload, headers);
  }

  // Delete a row
  deleteItem(pgId: number, colId: number, rowId: number, payload: any): Observable<any> {
    // API end point
    const url = `tFormat/recycleditem/${pgId}/${colId}/${rowId}`;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any>(url, payload, headers);
  }

  // Get Row
  viewRow(rowId: any): Observable<any[]> {
    return this.apiService.get<any[]>(`${this.apiUrl}/viewRow/${rowId}`);
  }

  // Get Cell
  viewCell(rowId: any, colId: any): Observable<any[]> {
    return this.apiService.get<any[]>(
      `${this.apiUrl}/viewCell/${rowId}/${colId}`
    );
  }

  // Get Item
  viewItem(rowId: any, colId: any): Observable<any[]> {
    return this.apiService.get<any[]>(
      `${this.apiUrl}/viewItem/${rowId}/${colId}`
    );
  }
}
