import { Component, Inject, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogFontComponent } from '../../dialog-font/dialog-font.component';
import { MatDialog } from '@angular/material/dialog';
import { FontSelectServiceService } from '../../../../core/services/font-select-service/font-select-service.service';
import { DDL_Statuses, FONT_STYLE, FORMAT_FORM, FORMAT_LOCAL_COL, FORMAT_LOCAL_CELL, FORMAT_LOCAL_ITEM, FORMAT_LOCAL_ROW, PAGE_FORMAT } from '../../../../core/constants/app.contants';
import { PickColDialogComponent } from '../../../../modals/pickCol-Dialog/pickcol-dialog.component';
import { Select, Store } from '@ngxs/store';
import { Sheet } from '../../../../store/page/page.actions';
import { SystemInitials } from '../../../../constant';
import { Format } from '../../../../store/format/format.actions';
import { ContextMenu } from '../../../../core/constants/menu-bar/page/page';
import { editDdDialogMainComponent } from '../../edit-dd-dialog/edit-dd-dialog-main/edit-dd-dialog-main.component';
import { DDResponse, KeyValue, RowModeData } from '../../../models/edit-dd-dailogs/dd-dailog.models';
import { SheetState } from '../../../../store/page/page.store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FontStyle } from '../../../models/format/format.models';
import { DialogEditComponent } from '../../dialog-edit/dialog-edit.component';
import { PgTabStore } from '../../../../store/pg-tab/pg-tab.store';
import { FormatStateModel, SetFormatFormFields } from '../../../../store/format/format.model';
import { MainService } from '../../../../core/services/main-service/main.service';

@Component({
  selector: 'app-format-page-form',
  templateUrl: './format-page-form.component.html',
  styleUrls: ['./format-page-form.component.scss'],
})
export class FormatPageFormComponent implements OnChanges, AfterViewInit {
  formatPageForm!: FormGroup;
  formatLocalRowForm! : FormGroup;
  formatId!: string;
  pageId!: string;
  columnID: any = "";
  formFields: Array<any> = [];
  pageFormatData: any = [];
  editDdiData: any = [];
  languagesList : any = {};
  private languageArray: [{}] = [{}];
  readonly FORM = FORMAT_FORM;
  @ViewChild('textFieldReference',) fixedWidthContainer!: ElementRef<HTMLDivElement>;
  styledvalue = "<spna>styledvalue</span>";
  @Input() dialogData: any;
  @Output() formDataChange = new EventEmitter<any>(); // To emit form data to parent
  @Select(SheetState.PickDdiData) editDdiData$: Observable<any> | undefined;
  @Select(PgTabStore.getLanguages) languages$!: Observable<any>
  private unsubscribe$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dialogData'] && this.dialogData?.context) {
      if (this.dialogData?.context === ContextMenu.FormatPage) {
        this.formFields = PAGE_FORMAT;
      } else if (this.dialogData?.context === ContextMenu.FormatLocalRow) {
        this.formFields = FORMAT_LOCAL_ROW;
      } else if (this.dialogData?.context === ContextMenu.FormatLocalColumn) {
        this.formFields = FORMAT_LOCAL_COL;
      } else if (this.dialogData?.context === ContextMenu.FormatLocalCell) {
        this.formFields = FORMAT_LOCAL_CELL;
      } else if (this.dialogData?.context === ContextMenu.FormatLocalItem) {
        this.formFields = FORMAT_LOCAL_ITEM;
      }
    }
  }
  ngAfterViewInit(): void {
    const containerWidth = this.fixedWidthContainer.nativeElement.offsetWidth;

    // Select all elements with the 'fixed-width' class
    const fixedWidthElements = document.querySelectorAll('.fixed-width');

    // Apply the width to each of the 'fixed-width' elements
    fixedWidthElements.forEach((element) => {
      this.renderer.setStyle(element, 'width', `${containerWidth}px`);
    });
  }

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public service: FontSelectServiceService,
    private store: Store,
    private renderer: Renderer2,
    private mainService: MainService,
  ) {
    this.formatPageForm = this.fb.group({});
    this.formatLocalRowForm = this.fb.group({});
  }

  ngOnInit() {
    this.initSubscriptions();

    // Creating the form group for formatPageForm
    let formatPageFormGroup: Record<string, any> = {};

    this.formFields.forEach((field) => {
      formatPageFormGroup[field.name] = [
        { value: field.value ?? '', disabled: field.readonly },
        [
          field.required ? Validators.required : null,
          field.pattern ? Validators.pattern(field.pattern) : null
        ].filter(Boolean)  // Remove null validators
      ];
    });

    // Initialize both forms
    formatPageFormGroup[FORMAT_FORM.CommentObject] = [{ value: {} }];
    formatPageFormGroup[FORMAT_FORM.RowId] = [{ value: '' }];
    this.formatPageForm = this.fb.group(formatPageFormGroup);

    // Optionally set initial values
    this.formatPageForm.patchValue({
      formatId: this.formatId,
      pageId: this.pageId,
    });

    // Subscribe to form value changes
    this.formatPageForm.valueChanges.subscribe((updatedValues) => {
      this.formDataChange.emit({
        form: this.formatPageForm
      });
    });

    // Fetch data to display format form
    this.populateForm();
  }

  openFontDialog() {
    const dialogRef = this.dialog.open(DialogFontComponent, {
      width: '400px',
      data: {
       dialogData: this.dialogData,
       fontStyle: this.formatPageForm.controls[FORMAT_FORM.FontStyle].value
      },
    });

    dialogRef.afterClosed().subscribe((result: FontStyle) => {
      const fontStyle: string = this.mainService.parseFontStyle(result);
      
      this.dialogData.fontStyle = result;
      this.dialogData.fontStyleParsed = fontStyle;
      
      this.formatPageForm.patchValue({
        [FORMAT_FORM.FontStyle]: fontStyle.trim()
      });
    });
  }

  openColDialog() {
    const dialogRef = this.dialog.open(PickColDialogComponent, {
      width: '750px',
      data: { selectedId: '1000000001' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.columnID = result;
      this.formatPageForm.patchValue({
        [FORMAT_FORM.PgNestedCol]: this.columnID,
      });
    });
  }

  async openEditDdDialog(statusKey?: number | unknown) {
    const dialogRef = this.dialog.open(editDdDialogMainComponent, {
      width: 'auto',
      panelClass: 'pick-ddl',
      data: {
        token: DDL_Statuses
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Safely retrieve the row selected from the dialog result
        const rowSelectedKey = Object.keys(result).find((key) => key.endsWith('RowSelected')) as keyof DDResponse;
        const rowSelected: RowModeData = result[rowSelectedKey];
        const rowSelectedObj: KeyValue = { key: Number(rowSelected.row), value: rowSelected.token }

        if (rowSelected?.token) {
          // Get the current Status value from the form
          const currentStatuses: KeyValue[] = this.formatPageForm.controls[FORMAT_FORM.Status].value

          const isDuplicate = currentStatuses.some((status: KeyValue) => status.key === rowSelectedObj.key);

          const updatedStatuses: KeyValue[] = statusKey && !isDuplicate
            ? currentStatuses.map((status: KeyValue) =>
                status.key === statusKey ? rowSelectedObj : status
              )
            : !isDuplicate
            ? [...currentStatuses, rowSelectedObj]
            : currentStatuses;

          // Update the form control
          this.formatPageForm.patchValue({
            [FORMAT_FORM.Status]: updatedStatuses,
          });
        } else {
          console.warn('No valid row selected token found.');
        }
      }
    });
  }

  openMlTextDialog() {
    const dialogRef = this.dialog.open(DialogEditComponent, {
      data: {
        language: this.languagesList,
        value: this.formatPageForm.controls[FORMAT_FORM.Comment].value,
        context: ContextMenu.FormatLocalColumn
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      const mlText = result;
      if (typeof result === 'object') {
        this.formatPageForm.patchValue({
          [FORMAT_FORM.Comment]: mlText?.[SystemInitials.English],
          [FORMAT_FORM.CommentObject]: mlText,
        });
      }
    });
  }

  async populateForm() {
    switch (this.dialogData.context) {
      case ContextMenu.FormatPage:
        this.getPageFormat();
        return '';
      case ContextMenu.FormatLocalColumn:
        this.getLocalColumn();
        return '';
      case ContextMenu.FormatLocalRow:
        this.getLocalRow();
        return '';
      case ContextMenu.FormatLocalCell:
        this.getLocalCell();
        return '';
      case ContextMenu.FormatLocalItem:
        this.getLocalItem();
        return '';
      default:
        return '';
    }
  }

  getPageFormat() {
    this.store.dispatch(new Format.GetPage()).subscribe({
      next: (state: any) => {
        if (!state || !state.format || !state.format.pageFormat || !state.format.pageFormat.FormatData) {
          console.error('Invalid data structure received:', state);
          return;
        }

        const pgId = state.sheet.defaultPageId;
        const rowId = state.format.pageFormat.pageData.Row;
        const pgFormat = state.format.pageFormat.FormatData;
        this.setFormFields({
          FormFields: this.formFields,
          FormatData: pgFormat,
          ObjectId: pgId
        })

        this.formatPageForm.patchValue({
          [FORMAT_FORM.RowId]: rowId,
        });
      },

      error: (err: any) => {
        console.error('Error fetching page format data:', err);
      },
    });
  }

  getLocalColumn() {
    const colId: number = this.dialogData.objectId;
    this.store.dispatch(new Format.GetLocalColumn(colId)).subscribe({
      next: (state: any) => {
        if (!state || !state.format || !state.format.localColumnFormat || !state.format.localColumnFormat) {
          console.error('Invalid data structure received:', state);
          return;
        }
    
        const rowId = state.format.localColumnFormat.ColData.Row;
        const colformat = state.format.localColumnFormat.FormatData;
        this.setFormFields({
          FormFields: this.formFields,
          FormatData: colformat,
          ObjectId: colId
        })

        this.formatPageForm.patchValue({
          [FORMAT_FORM.RowId]: rowId,
        });
      },

      error: (err: any) => {
        console.error('Error fetching page format data:', err);
      },
    });
  }

  getLocalRow() {
    const rowId: number = this.dialogData.objectId;

    this.store.dispatch(new Format.GetLocalRow(rowId)).subscribe({
      next: (state: any) => {
        if (!state || !state.format || !state.format.localRowFormat || !state.format.localRowFormat.FormatData) {
          console.error('Invalid data structure received:', state);
          return;
        }

        const rowformat = state.format.localRowFormat.FormatData;
        this.setFormFields({
          FormFields: this.formFields,
          FormatData: rowformat,
          ObjectId: rowId
        })

        this.formatPageForm.patchValue({
          [FORMAT_FORM.RowId]: rowId,
        });
      },

      error: (err: any) => {
        console.error('Error fetching page format data:', err);
      },
    });
  }

  getLocalCell() {
    const colId: number = this.dialogData.colId;
    const rowId: number = this.dialogData.rowId;

    this.store.dispatch(new Format.GetLocalCell(colId, rowId)).subscribe({
      next: (state: any) => {
        if (!state || !state.format || !state.format.localCellFormat || !state.format.localCellFormat.ViewCell) {
          console.error('Invalid data structure received:', state);
          return;
        }

        const cellFormat = state.format.localCellFormat.ViewCell;
        const cellId = cellFormat.Object ?? '';
        this.dialogData.title = `Format: Local Cell (ID: ${cellId})`;
        this.setFormFields({
          FormFields: this.formFields,
          FormatData: cellFormat,
          ObjectId: cellFormat.Object
        })

        this.formatPageForm.patchValue({
          [FORMAT_FORM.RowId]: rowId,
        });
      },

      error: (err: any) => {
        console.error('Error fetching page format data:', err);
      },
    });
  }

  getLocalItem() {
    const colId: number = this.dialogData.colId;
    const rowId: number = this.dialogData.rowId;

    this.store.dispatch(new Format.GetLocalItem(colId, rowId)).subscribe({
      next: (state: any) => {
        if (!state || !state.format || !state.format.localItemFormat || !state.format.localItemFormat.ViewItem) {
          console.error('Invalid data structure received:', state);
          return;
        }

        const itemFormat = state.format.localItemFormat.ViewItem;
        const itemId = itemFormat.Object ?? '';
        this.dialogData.title = `Format: Local Item (ID: ${itemId})`;
        this.setFormFields({
          FormFields: this.formFields,
          FormatData: itemFormat,
          ObjectId: itemFormat.Object
        })

        this.formatPageForm.patchValue({
          [FORMAT_FORM.RowId]: rowId,
        });
      },

      error: (err: any) => {
        console.error('Error fetching page format data:', err);
      },
    });
  }

  setFormFields(formFields: SetFormatFormFields) {
    // Iterate through form fields and dynamically update the form values
    formFields.FormFields.forEach((field) => {
      let value: any = '';
      if (field.name === FORMAT_FORM.Comment) {
        value = formFields.FormatData[field.name]?.[SystemInitials.English] || '';
        this.formatPageForm.patchValue({ [FORMAT_FORM.CommentObject]: formFields.FormatData[field.name] });

      } else if (field.name == FORMAT_FORM.ColId || field.name == FORMAT_FORM.PgId 
          || field.name == FORMAT_FORM.ItemId
        ) {
        value = formFields.ObjectId || '';
      } else if (field.name === FORMAT_FORM.Status) {
        // Convert the status object into array of objects
        value = Object.entries(formFields.FormatData[field.name]).reduce((acc: any, [key, value]) => {
          acc.push({ 
            key: key,
            value: value,
          });
          return acc;
        }, []);

      } else if (field.name === FORMAT_FORM.TxList) {
        value = formFields.FormatData[field.name]?.[0] || '';
      } else {
        value = formFields.FormatData[field.name] || '';
      }

      this.formatPageForm.patchValue({
        [field.name]: value,
      });
    });
  }

  initSubscriptions() {
    this.editDdiData$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data != undefined) {
        this.editDdiData = data;
      }
    });

    this.languages$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.languagesList = data;
      data?.data['ALL Languages'].map((item: any, index: number) => {
        this.languageArray[index] = {
          language: Object.values(item),
          row: Object.keys(item),
        };
      });
    });
  }
}
